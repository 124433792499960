/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const WorkspaceChangeOwnerForm = (props) => {
  const {
    workspace,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={{ owner: workspace.owner }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="owner"
            rules={[{ required: true, message: t('validations.required') }]}
            help={t('workspaces.userTab.ownerDisclaimer')}
          >
            <Input disabled={!canEdit} style={{ width: '100%' }} />
          </Form.Item>
          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.save')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    owner: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

WorkspaceChangeOwnerForm.propTypes = propTypes;

export default WorkspaceChangeOwnerForm;
