import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useVibrationTimeMeasureDetail } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';
import { formatLocaleWithTimezone, getLocale } from '../utils/date';
import { VibrationMeasureChartContainer, VibrationValueFilterModal } from './components/times';

const VibrationTimeMeasureChartPage = (props) => {
  const {
    type,
  } = props;
  const { workspaceId, vibrationSessionId, vibrationMeasureId } = useParams();

  const { t, i18n } = useTranslation();

  const locale = getLocale(i18n.language);

  const {
    workspace,
    vibrationSession,
    vibrationMeasure,
    detailLoading,
    previewLoading,
    previewDecoding,
    error,
    preview,
    setFrom,
  } = useVibrationTimeMeasureDetail(workspaceId, vibrationSessionId, vibrationMeasureId);

  const [requestTimestamp, setRequestTimestamp] = React.useState(null);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [autoFilter, setAutoFilter] = React.useState(null);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/workspaces',
        breadcrumbName: 'Workspaces',
      },
    ];
    if (workspace) {
      result.push({
        path: `/workspaces/${workspaceId}`,
        breadcrumbName: workspace.name,
      });
      if (vibrationSession) {
        result.push({
          path: `/workspaces/${workspaceId}/vibrationSessions/${vibrationSessionId}`,
          breadcrumbName: vibrationSession.name,
        });
        if (vibrationMeasure) {
          result.push({
            path: '',
            breadcrumbName: vibrationMeasure.name,
          });
        }
      }
    }
    return result;
  }, [workspace, vibrationSession, vibrationMeasure]);

  const availableFrom = React.useMemo(() => {
    if (vibrationMeasure) {
      return vibrationMeasure.availableWindow[0];
    }
    return null;
  }, [vibrationMeasure]);

  const availableTo = React.useMemo(() => {
    if (vibrationMeasure) {
      return vibrationMeasure.availableWindow[1];
    }
    return null;
  }, [vibrationMeasure]);

  React.useEffect(() => {
    if (!requestTimestamp && availableFrom && availableTo) {
      setRequestTimestamp({
        from: availableFrom,
        to: availableTo,
      });
    }
  }, [requestTimestamp, setRequestTimestamp, availableFrom, availableTo]);

  const handleFilter = (data) => {
    setRequestTimestamp(data);
    setIsFilterOpen(false);
  };

  const handleFromChange = (data) => {
    setFrom(data);
  };

  const handleRequestFilter = (direction) => {
    if (direction === 'next') {
      setAutoFilter('next');
      setFrom(availableTo);
    }
    else if (direction === 'restart') {
      setAutoFilter('restart');
      setFrom(vibrationMeasure.minTimestamp);
    }
    else {
      setAutoFilter(null);
      setIsFilterOpen(true);
    }
  };

  React.useEffect(() => {
    if (autoFilter) {
      setRequestTimestamp({
        from: availableFrom,
        to: availableTo,
      });
    }
  }, [autoFilter, availableFrom, availableTo]);

  const subtitle = React.useMemo(() => {
    if (vibrationMeasure && vibrationSession) {
      const from = formatLocaleWithTimezone(vibrationMeasure.minTimestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, vibrationSession.timezone, false);
      const to = formatLocaleWithTimezone(vibrationMeasure.maxTimestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, vibrationSession.timezone, false);
      return t('vibrationTimeMeasures.chart.subtitle', { from, to, totalValues: vibrationMeasure.totalValues });
    }
    return '';
  }, [t, vibrationSession, vibrationMeasure, locale]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={detailLoading || previewLoading || previewDecoding}
    >
      <PageSection
        title={vibrationMeasure ? vibrationMeasure.name : ''}
        breadcrumb={breadcrumb}
        subtitle={subtitle}
      >
        {!error && vibrationSession && !previewLoading && !previewDecoding && preview && (
          <VibrationValueFilterModal
            timezone={vibrationSession.timezone}
            preview={preview}
            availableFrom={availableFrom}
            availableTo={availableTo}
            onFromChange={handleFromChange}
            onFormSubmit={handleFilter}
            isModalOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          />
        )}
        {vibrationMeasure && requestTimestamp && (
          <VibrationMeasureChartContainer
            from={requestTimestamp.from}
            to={requestTimestamp.to}
            workspaceId={workspaceId}
            vibrationSessionId={vibrationSessionId}
            vibrationMeasureId={vibrationMeasureId}
            onRequestFilter={handleRequestFilter}
            type={type}
          />
        )}
      </PageSection>
    </ComponentLoader>
  );
};

const propTypes = {
  type: PropTypes.oneOf(['single', 'octave', 'thirdOctave', 'statistics']).isRequired,
};

VibrationTimeMeasureChartPage.propTypes = propTypes;

export default VibrationTimeMeasureChartPage;
