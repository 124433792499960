/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

import {
  settingIcon,
  downloadImageIcon,
  showGridIcon,
  hideGridIcon,
  hidePointsIcon,
  showPointsIcon,
  infoIcon,
  searchIcon,
  excelIcon,
  tableIcon,
  restartIcon,
  nextIcon,

  exportExcel,
  PERCENT_DECIMAL,
} from '../utils';

import {
  formatLocale,
  formatLocaleWithTimezone,
  getLocale,
} from '../../../utils/date';

import StatisticsMeasureChartConfigModal from './OctaveMeasureChartConfigModal';
import { StatisticsMeasureTableModal } from '../../tables/times';
import { SoundMeasureConfigModal } from '../../components';

const calculateColors = (colorStep) => {
  const result = [];
  const byteStep = parseInt(255 / colorStep, 10);
  let currentRed = 0;
  let currentGreen = 0;
  let currentBlue = 255;
  for (let i = 0; i < colorStep; i += 1) {
    currentRed = byteStep * i;
    currentGreen = parseInt(255 - (255 * (Math.abs((byteStep * i) - 127) / 128)), 10);
    currentBlue = 255 - byteStep * i;
    result.push([
      currentRed,
      currentGreen,
      currentBlue,
    ]);
  }
  return result.map((r) => {
    const red = r[0].toString(16).padStart(2, '0');
    const green = r[1].toString(16).padStart(2, '0');
    const blue = r[2].toString(16).padStart(2, '0');
    return `#${red}${green}${blue}`;
  });
};

const StatisticsMeasureChart = (props) => {
  const {
    workspaceId,
    soundSession,
    measure,
    onRequestFilter,
  } = props;

  const { timezone } = soundSession;

  const { t, i18n } = useTranslation('translations');
  const locale = getLocale(i18n.language);

  const [currentTimeZoomIndexes, setCurrentTimeZoomIndexes] = React.useState([0, measure.values.length - 1]);
  const [currentClassZoomIndexes, setCurrentClassZoomIndexes] = React.useState([0, measure.config[0].classes.length - 1]);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [isTableModalOpen, setIsTableModalOpen] = React.useState(false);
  const [isStatisticsConfigModalOpen, setIsStatisticsConfigModalOpen] = React.useState(false);

  const statisticsChartRef = React.useRef(null);
  const [statisticsChartInstance, setStatisticsChartInstance] = React.useState(null);
  const detailChartRef = React.useRef(null);
  const [detailChartInstance, setDetailChartInstance] = React.useState(null);

  const [currentStatisticsConfig, setCurrentStatisticsConfig] = React.useState({
    measureId: measure.config[0].measureId,
    showGrid: true,
    showPoints: false,
    minColorRange: 20,
    maxColorRange: 80,
    colorSteps: 10,
    selectedPoint: null,
  });

  const currentConfig = React.useMemo(() => {
    const { config } = measure;
    return config[0];
  }, [measure]);

  const handleResetColors = React.useCallback(() => {
    setCurrentStatisticsConfig((old) => ({
      ...old,
      minColorRange: 20,
      maxColorRange: 80,
      colorSteps: 10,
    }));
  }, [measure, setCurrentStatisticsConfig]);

  const classes = React.useMemo(() => currentConfig.classes.map((c) => `${c[0]}-${c[1]}`), [currentConfig]);

  const dataSource = React.useMemo(() => {
    const { values } = measure;
    const { measureId } = currentConfig;
    const filteredValues = values.filter((v) => v.data[measureId] != null);

    const ds = filteredValues.map((v) => {
      const { timestamp, data } = v;
      const currentData = data[measureId];
      const sum = currentData.reduce((acc, item) => acc + item, 0);
      return [
        timestamp,
        ...currentData.map((val) => (val / sum) * 100),
      ];
    });

    return ds;
  }, [measure, currentConfig]);

  const handleDownloadExcel = () => {
    const [from, to] = currentTimeZoomIndexes;
    const { measureId } = currentConfig;
    const currentValues = dataSource.slice(from, to + 1);

    const header = ['timestamp', 'date', ...classes];
    const data = currentValues.map((cv) => ([cv[0], formatLocaleWithTimezone(cv[0], 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone), ...cv.slice(1)]));

    exportExcel(
      `${soundSession.name}_${measureId}_${soundSession.timezone}.xlsx`,
      `Statistics ${measure.config[0].classes.length}`,
      [
        header,
        ...data,
      ],
    );
  };

  const statisticsDataset = React.useMemo(() => {
    const datasets = [];
    datasets.push({
      id: 'ds',
      dimensions: ['timestamp', ...classes],
      source: dataSource,
    });
    datasets.push({
      id: 'statistics_ds',
      fromDatasetIndex: 0,
      transform: {
        type: 'deltaohm:StatisticsToHeatmapTransform',
        config: { measureConfig: currentConfig },
      },
    });
    return datasets;
  }, [dataSource, currentConfig, classes]);

  const detailDataset = React.useMemo(() => {
    if (currentStatisticsConfig.selectedPoint) {
      const classIndex = classes.indexOf(currentStatisticsConfig.selectedPoint[1]);
      const timestamp = currentStatisticsConfig.selectedPoint[0];

      const lineDataSource = dataSource.map((ds) => {
        const sum = ds.slice(1).reduce((acc, item) => acc + item, 0);
        const result = [utcToZonedTime(ds[0], timezone).getTime(), (ds[classIndex + 1] / sum) * 100];
        return result;
      });

      const currentTimestampClass = dataSource
        .find((ds) => ds[0] === timestamp);

      const barDataSource = [];
      for (let i = 0; i < classes.length; i += 1) {
        barDataSource.push([classes[i], currentTimestampClass[i + 1]]);
      }

      const datasets = [];
      datasets.push({
        id: 'line_percentage_ds',
        dimensions: ['timestamp', 'value'],
        source: lineDataSource,
      });
      datasets.push({
        id: 'bar_ds',
        dimensions: ['class', 'value'],
        source: barDataSource,
      });
      datasets.push({
        id: 'bar_percentage_ds',
        fromDatasetIndex: 1,
        transform: {
          type: 'deltaohm:PercentageTransform',
          config: { sumDimensionIndexes: [1] },
        },
      });
      datasets.push({
        id: 'cumulative_bar_ds',
        fromDatasetIndex: 2,
        transform: {
          type: 'deltaohm:CumulativePercentageTransform',
        },
      });
      return datasets;
    }
    return null;
  }, [dataSource, classes, currentStatisticsConfig.selectedPoint]);

  const handleStatisticsConfigSubmit = (d) => {
    setCurrentStatisticsConfig((old) => ({ ...old, ...d }));
  };

  const handleInfoModalToggle = (open) => {
    setIsInfoModalOpen(open);
  };

  const handleTableModalToggle = (open) => {
    setIsTableModalOpen(open);
  };

  const handleStatisticsConfigModalToggle = (open) => {
    setIsStatisticsConfigModalOpen(open);
  };

  const getVisualMap = (show) => {
    const result = {
      show,
      type: 'piecewise',
      splitNumber: currentStatisticsConfig.colorSteps - 2,
      inRange: {
        color: calculateColors(currentStatisticsConfig.colorSteps),
      },
      min: currentStatisticsConfig.minColorRange,
      max: currentStatisticsConfig.maxColorRange,
      top: '7%',
      right: '5%',
      minOpen: true,
      maxOpen: true,
      formatter: (value1, value2) => {
        const precision = 2;
        const unit = t('common.percent');
        if (value1 === -Infinity) {
          return `< ${value2.toFixed(precision)} ${unit}`;
        }
        if (value2 === Infinity) {
          return `> ${value1.toFixed(precision)} ${unit}`;
        }
        return `[${value1.toFixed(precision)} - ${value2.toFixed(precision)}] ${unit}`;
      },
    };

    return result;
  };

  const getToolboxOptionHeatmap = () => {
    const result = [
      {
        id: 'right_toolbox',
        show: true,
        right: '5%',
        feature: {
          myRestartTool: {
            show: true,
            title: t('common.restart'),
            icon: restartIcon,
            onclick: () => {
              onRequestFilter('restart');
            },
          },
          myNextTool: {
            show: true,
            title: t('common.next'),
            icon: nextIcon,
            onclick: () => {
              onRequestFilter('next');
            },
          },
          mySearchTool: {
            show: true,
            title: t('common.filter'),
            icon: searchIcon,
            onclick: () => {
              onRequestFilter();
            },
          },
          saveAsImage: {
            excludeComponents: ['toolbox', 'dataZoom'],
            icon: downloadImageIcon,
            title: t('soundMeasures.components.charts.saveImage'),
          },
          myInfoTool: {
            show: true,
            title: t('common.info'),
            icon: infoIcon,
            onclick: () => {
              handleInfoModalToggle(true);
            },
          },
          myTableTool: {
            show: true,
            title: t('soundMeasures.components.charts.table'),
            icon: tableIcon,
            onclick: () => {
              handleTableModalToggle(true);
            },
          },
          myExcelTool: {
            show: true,
            title: t('soundMeasures.components.charts.excel'),
            icon: excelIcon,
            onclick: () => {
              handleDownloadExcel();
            },
          },
          mySettingTool: {
            show: true,
            title: t('soundMeasures.components.charts.settings'),
            icon: settingIcon,
            onclick: () => {
              handleStatisticsConfigModalToggle(true);
            },
          },
          myShowPointsTool: {
            show: true,
            title: currentStatisticsConfig.showPoints ? t('soundMeasures.components.charts.hidePoints') : t('soundMeasures.components.charts.showPoints'),
            icon: currentStatisticsConfig.showPoints ? hidePointsIcon : showPointsIcon,
            onclick: () => {
              setCurrentStatisticsConfig((old) => ({ ...old, showPoints: !old.showPoints }));
            },
          },
          myShowGridTool: {
            show: true,
            title: currentStatisticsConfig.showGrid ? t('soundMeasures.components.charts.hideGrid') : t('soundMeasures.components.charts.showGrid'),
            icon: currentStatisticsConfig.showGrid ? hideGridIcon : showGridIcon,
            onclick: () => {
              setCurrentStatisticsConfig((old) => ({ ...old, showGrid: !old.showGrid }));
            },
          },
        },
      },
    ];

    return result;
  };

  const getXAxisOptionHeatmap = () => {
    const result = {
      id: 'x_heatmap_data',
      type: 'category',
      axisLabel: {
        formatter: (data) => {
          const date = formatLocaleWithTimezone(parseInt(data, 10), 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone);
          return date;
        },
      },
    };

    return result;
  };

  const getYAxisOptionHeatmap = () => {
    const result = {
      id: 'y_heatmap_data',
      type: 'category',
      name: t('common.db'),
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        show: currentStatisticsConfig.showGrid,
      },
    };

    return result;
  };

  const getSeriesOptionHeatmap = () => {
    const result = {
      id: 'heatmap_serie',
      type: 'heatmap',
      datasetIndex: 1,
      encode: {
        x: 'timestamp',
        y: 'class',
      },
      progressive: 100000,
      animation: false,
    };

    return result;
  };

  const getTitlesHeatmap = () => {
    const result = {
      id: 'heatmap_title',
      left: 'center',
      text: measure.name,
    };
    return result;
  };

  const getTooltipOptionHeatmap = () => {
    const result = {
      trigger: 'item',
      formatter: (data) => {
        const [timestamp, c, value] = data.value;
        const date = formatLocaleWithTimezone(timestamp, 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone);
        const currentClass = `${c} ${t('common.db')}`;
        const tooltip = `${date}\n${currentClass}: ${value.toFixed(PERCENT_DECIMAL)}${t('common.percent')}`;
        return tooltip;
      },
      showContent: true,
      renderMode: 'richText',
    };
    return result;
  };

  const getInitialChartOptionHeatmap = () => {
    const result = {
      title: getTitlesHeatmap(),
      tooltip: getTooltipOptionHeatmap(),
      grid: {
        right: '18%',
      },
      dataZoom: [
        {
          id: 'x_zoom_slider',
          type: 'slider',
          start: 0,
          end: 100,
          height: '3%',
          bottom: '3%',
          realtime: false,
          labelFormatter: (index, valueStr) => {
            const date = formatLocaleWithTimezone(parseInt(valueStr, 10), 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone);
            return date;
          },
        },
        {
          id: 'x_zoom_inside',
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          id: 'y_zoom_slider',
          type: 'slider',
          orient: 'vertical',
          show: true,
          start: 0,
          end: 100,
          left: '3%',
          width: '1%',
          yAxisIndex: [0],
          realtime: false,
        },
      ],
      dataset: statisticsDataset,
      visualMap: getVisualMap(true),
      toolbox: getToolboxOptionHeatmap(),
      xAxis: getXAxisOptionHeatmap(),
      yAxis: getYAxisOptionHeatmap(),
      series: getSeriesOptionHeatmap(),
    };

    return result;
  };

  const getTitlesDetail = () => {
    const result = [{
      left: 'center',
      text: currentStatisticsConfig.selectedPoint ? `${currentStatisticsConfig.selectedPoint[1]} ${t('common.db')}` : '',
    },
    {
      top: '50%',
      left: 'center',
      text: currentStatisticsConfig.selectedPoint ? formatLocale(currentStatisticsConfig.selectedPoint[0], 'yyyy-MM-dd HH:mm:ss:SSS') : '',
    }];

    return result;
  };

  const getXAxisOptionDetail = () => {
    const result = [
      {
        id: 'x_line',
        type: 'time',
        name: t('common.time'),
        axisLine: {
          show: true,
        },
        axisTick: {
          alignWithLabel: true,
          show: true,
        },
        splitLine: {
          alignWithLabel: true,
          show: currentStatisticsConfig.showGrid,
        },
        axisPointer: {
          snap: true,
          label: {
            formatter: (params) => formatLocaleWithTimezone(params.value, 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone),
          },
        },
        gridIndex: 0,
      },
      {
        id: 'x_bar',
        type: 'category',
        name: t('common.db'),
        axisLine: {
          show: true,
        },
        axisTick: {
          alignWithLabel: true,
          show: true,
        },
        splitLine: {
          alignWithLabel: true,
          show: currentStatisticsConfig.showGrid,
        },
        gridIndex: 1,
      },
    ];

    return result;
  };

  const getYAxisOptionDetail = () => {
    const result = [
      {
        id: 'y_line',
        type: 'value',
        name: t('common.percent'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: currentStatisticsConfig.showGrid,
        },
        min: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.min - air;
        },
        max: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.max + air;
        },
        axisLabel: {
          formatter: (value) => value.toFixed(PERCENT_DECIMAL),
        },
        gridIndex: 0,
      },
      {
        id: 'y_bar',
        type: 'value',
        name: t('common.percent'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: currentStatisticsConfig.showGrid,
        },
        min: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.min - air;
        },
        max: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.max + air;
        },
        axisLabel: {
          formatter: (value) => value.toFixed(PERCENT_DECIMAL),
        },
        gridIndex: 1,
      },
      {
        id: 'cumulative_y_bar',
        type: 'value',
        name: t('common.cumulativePercent'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: currentStatisticsConfig.showGrid,
        },
        min: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.min - air;
        },
        max: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.max + air;
        },
        axisLabel: {
          formatter: (value) => value.toFixed(PERCENT_DECIMAL),
        },
        gridIndex: 1,
      },
    ];
    return result;
  };

  const getSeriesOptionDetail = () => {
    const result = [
      {
        id: 'line_serie',
        step: 'start',
        type: 'line',
        sampling: 'lttb',
        showSymbol: currentStatisticsConfig.showPoints,
        symbolSize: 1,
        lineStyle: {
          width: 1,
        },
        datasetIndex: 0,
        encode: {
          x: 'timestamp',
          y: 'value',
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
      },
      {
        id: 'bar_serie',
        type: 'bar',
        datasetIndex: 2,
        encode: {
          x: 'class',
          y: 'value',
        },
        lineStyle: {
          color: 'blue',
        },
        itemStyle: {
          color: 'blue',
        },
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
      {
        id: 'cumulative_serie',
        step: 'start',
        type: 'line',
        sampling: 'lttb',
        symbolSize: 1,
        showSymbol: currentStatisticsConfig.showPoints,
        datasetIndex: 3,
        encode: {
          x: 'class',
          y: 'value',
        },
        lineStyle: {
          color: 'green',
        },
        itemStyle: {
          color: 'green',
        },
        xAxisIndex: 1,
        yAxisIndex: 2,
      },
    ];

    return result;
  };

  const getTooltipOptionDetail = () => {
    const result = {
      trigger: 'axis',
      triggerOn: 'click',
      axisPointer: {
        type: 'line',
        axis: 'x',
        snap: true,
      },
      formatter: (params) => {
        let tooltip;
        if (params.length === 1) { // gridIndex 0
          const [timestamp, value] = params[0].value;
          tooltip = `${format(timestamp, 'yyyy-MM-dd HH:mm:ss.SSS')}\n${value.toFixed(PERCENT_DECIMAL)}${t('common.percent')}`;
        }
        else {
          let currentClass;
          const values = [];
          for (let i = 0; i < params.length; i += 1) {
            const param = params[i];
            const [cc, value] = param.value;
            currentClass = cc;
            values.push(`${param.marker}${value.toFixed(PERCENT_DECIMAL)}${t('common.percent')}`);
          }
          tooltip = `${currentClass} ${t('common.db')}\n${values.join('\n')}`;
        }
        return tooltip;
      },
      showContent: true,
      renderMode: 'richText',
    };

    return result;
  };

  const getInitialChartOptionDetail = () => {
    const timeDataZoom = statisticsChartInstance.getOption().dataZoom[0];
    const classDataZoom = statisticsChartInstance.getOption().dataZoom[2];
    const result = {
      title: getTitlesDetail(),
      grid: [
        {
          id: 'line_grid',
          height: '30%',
          right: '18%',
        },
        {
          id: 'bar_grid',
          top: '60%',
          right: '18%',
          height: '30%',
        },
      ],
      tooltip: getTooltipOptionDetail(),
      toolbox: {
        id: 'right_toolbox',
        show: true,
        right: '5%',
        feature: {
          saveAsImage: {
            excludeComponents: ['toolbox', 'dataZoom'],
            icon: downloadImageIcon,
            title: t('soundMeasures.components.charts.saveImage'),
          },
        },
      },
      dataZoom: [
        {
          id: 'x_time_zoom_inside',
          type: 'inside',
          start: timeDataZoom.start,
          end: timeDataZoom.end,
          xAxisIndex: [0],
          filterMode: 'empty',
        },
        {
          id: 'x_class_zoom_slider',
          type: 'slider',
          show: true,
          start: classDataZoom.start,
          end: classDataZoom.end,
          xAxisIndex: [1],
          height: '3%',
          realtime: false,
          filterMode: 'empty',
        },
      ],
      dataset: detailDataset,
      // visualMap: getVisualMap(false),
      xAxis: getXAxisOptionDetail(),
      yAxis: getYAxisOptionDetail(),
      series: getSeriesOptionDetail(),
    };

    return result;
  };

  // Dispose charts on change page

  React.useEffect(() => {
    if (statisticsChartInstance) {
      return () => {
        statisticsChartInstance.dispose();
      };
    }
    return () => { };
  }, [statisticsChartInstance]);

  React.useEffect(() => {
    if (detailChartInstance) {
      return () => {
        detailChartInstance.dispose();
      };
    }
    return () => { };
  }, [detailChartInstance]);

  React.useEffect(() => {
    if (detailChartInstance && currentStatisticsConfig.selectedPoint) {
      detailChartInstance.setOption(getInitialChartOptionDetail());
    }
  }, [detailChartInstance, currentStatisticsConfig.selectedPoint, currentStatisticsConfig.measureId]);

  // dispose detail chart on unselect point on heatmap
  React.useEffect(() => {
    if (detailChartInstance && !currentStatisticsConfig.selectedPoint) {
      detailChartInstance.dispose();
      setDetailChartInstance(null);
    }
  }, [detailChartInstance, currentStatisticsConfig.selectedPoint]);

  React.useEffect(() => {
    if (statisticsChartRef && statisticsChartRef.current) {
      const instance = echarts.init(statisticsChartRef.current);
      instance.on('click', (event) => {
        setCurrentStatisticsConfig((old) => ({ ...old, selectedPoint: [event.value[0], `${event.value[1][0]}-${event.value[1][1]}`] }));
      });
      instance.on('datazoom', (e) => {
        if (e.batch || e.dataZoomId === 'x_zoom_slider') {
          const option = instance.getOption();
          setCurrentTimeZoomIndexes([option.dataZoom[0].startValue, option.dataZoom[0].endValue]);
        }
        if (e.dataZoomId === 'y_zoom_slider') {
          const option = instance.getOption();
          setCurrentClassZoomIndexes([option.dataZoom[2].start, option.dataZoom[2].end]);
        }
      });
      setStatisticsChartInstance(instance);
    }
  }, [statisticsChartRef, detailChartInstance]);

  // change statistics chart on measureId change
  React.useEffect(() => {
    if (statisticsChartInstance) {
      statisticsChartInstance.setOption(getInitialChartOptionHeatmap());
      setCurrentStatisticsConfig((old) => ({ ...old, selectedPoint: null }));
    }
  }, [statisticsChartInstance, currentStatisticsConfig.measureId, setCurrentStatisticsConfig]);

  // Datazoom on detail chart
  React.useEffect(() => {
    if (statisticsChartInstance && detailChartInstance) {
      const option = statisticsChartInstance.getOption();
      detailChartInstance.dispatchAction({
        type: 'dataZoom',
        dataZoomIndex: 0,
        start: option.dataZoom[0].start,
        end: option.dataZoom[0].end,
      });
    }
  }, [statisticsChartInstance, detailChartInstance, currentTimeZoomIndexes]);

  React.useEffect(() => {
    if (statisticsChartInstance && detailChartInstance) {
      const option = statisticsChartInstance.getOption();
      detailChartInstance.dispatchAction({
        type: 'dataZoom',
        dataZoomIndex: 1,
        start: option.dataZoom[2].start,
        end: option.dataZoom[2].end,
      });
    }
  }, [statisticsChartInstance, detailChartInstance, currentClassZoomIndexes]);

  React.useEffect(() => {
    if (detailChartRef && detailChartRef.current && currentStatisticsConfig.selectedPoint) {
      const instance = echarts.init(detailChartRef.current, null);
      instance.on('datazoom', (e) => {
        if (e.batch && e.batch[0].dataZoomId === 'x_time_zoom_inside') {
          const option = instance.getOption();
          if (statisticsChartInstance) {
            statisticsChartInstance.dispatchAction({
              type: 'dataZoom',
              dataZoomIndex: 0,
              start: option.dataZoom[0].start,
              end: option.dataZoom[0].end,
            });
          }
        }
        if (e.dataZoomId === 'x_class_zoom_slider') {
          const option = instance.getOption();
          if (statisticsChartInstance) {
            statisticsChartInstance.dispatchAction({
              type: 'dataZoom',
              dataZoomIndex: 2,
              start: option.dataZoom[1].start,
              end: option.dataZoom[1].end,
            });
          }
        }
      });
      setDetailChartInstance(instance);
    }
  }, [detailChartRef, statisticsChartInstance, currentStatisticsConfig.selectedPoint]);

  React.useEffect(() => {
    if (statisticsChartInstance) {
      statisticsChartInstance.setOption({
        toolbox: getToolboxOptionHeatmap(),
        xAxis: getXAxisOptionHeatmap(),
        yAxis: getYAxisOptionHeatmap(),
      });
    }
    if (detailChartInstance && currentStatisticsConfig.selectedPoint) {
      detailChartInstance.setOption({
        xAxis: getXAxisOptionDetail(),
        yAxis: getYAxisOptionDetail(),
      });
    }
  }, [statisticsChartInstance, detailChartInstance, currentStatisticsConfig.showGrid, currentStatisticsConfig.selectedPoint]);

  React.useEffect(() => {
    if (statisticsChartInstance) {
      statisticsChartInstance.setOption({
        toolbox: getToolboxOptionHeatmap(),
      });
    }
    if (detailChartInstance && currentStatisticsConfig.selectedPoint) {
      detailChartInstance.setOption({
        series: getSeriesOptionDetail(),
      });
    }
  }, [statisticsChartInstance, detailChartInstance, currentStatisticsConfig.showPoints]);

  React.useEffect(() => {
    if (statisticsChartInstance) {
      statisticsChartInstance.setOption({
        visualMap: getVisualMap(true),
      });
    }
  }, [statisticsChartInstance, currentStatisticsConfig.minColorRange, currentStatisticsConfig.maxColorRange, currentStatisticsConfig.colorSteps]);

  // React.useEffect(() => {
  //   if (detailChartInstance && currentStatisticsConfig.selectedPoint) {
  //     detailChartInstance.setOption({
  //       visualMap: getVisualMap(false),
  //     });
  //   }
  // }, [detailChartInstance, currentStatisticsConfig.minColorRange, currentStatisticsConfig.maxColorRange, currentStatisticsConfig.colorSteps, currentStatisticsConfig.selectedPoint]);

  return (
    <>
      <StatisticsMeasureChartConfigModal
        currentConfig={currentStatisticsConfig}
        onFormSubmit={handleStatisticsConfigSubmit}
        isModalOpen={isStatisticsConfigModalOpen}
        onClose={() => handleStatisticsConfigModalToggle(false)}
        onReset={handleResetColors}
      />
      <StatisticsMeasureTableModal
        soundSession={soundSession}
        measure={measure}
        dataSource={dataSource}
        isModalOpen={isTableModalOpen}
        currentConfig={currentStatisticsConfig}
        currentIndexes={currentTimeZoomIndexes}
        onClose={() => handleTableModalToggle(false)}
      />
      <SoundMeasureConfigModal
        workspaceId={workspaceId}
        soundSessionId={soundSession.id}
        soundMeasure={measure}
        isModalOpen={isInfoModalOpen}
        onClose={() => handleInfoModalToggle(false)}
      />
      <div style={{ height: 800 }} ref={statisticsChartRef} />
      <div style={{ height: 600 }} ref={detailChartRef} />
    </>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  soundSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  measure: PropTypes.shape({
    name: PropTypes.string.isRequired,
    kind: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    values: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onRequestFilter: PropTypes.func.isRequired,
};

StatisticsMeasureChart.propTypes = propTypes;

export default StatisticsMeasureChart;
