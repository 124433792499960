import { gql } from '@apollo/client';

export const WORKSPACE_LIST_ITEM_FRAGMENT = gql`
fragment WorkspaceListItemFragment on Workspace {
  id
  name
  owner
  updatedAt
}
`;

export const WORKSPACE_DETAIL_FRAGMENT = gql`
fragment WorkspaceDetailFragment on Workspace {
  id
  name
  owner
  iAmOwner
  myRoles
  userPermissions {
    email
    roles
  }
  createdAt
  updatedAt
},
`;

export const WORKSPACE_QUOTA_QUERY = gql`
query workspaceQuotaQuery {
  viewer {
    workspaceAvailableQuota
    workspaceUsedQuota
  }
}
`;

export const WORKSPACES_QUERY = gql`
query workspacesQuery($afterCursor: String, $pageSize: Int!, $filter: WorkspaceFilter) {
  viewer {
    workspaces(first: $pageSize, after: $afterCursor, filter: $filter) {
      edges {
        node {
          ...WorkspaceListItemFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
}
${WORKSPACE_LIST_ITEM_FRAGMENT}
`;

export const WORKSPACE_QUERY = gql`
query workspaceQuery($id: ID!) {
  viewer {
    workspace(id: $id) {
      ...WorkspaceDetailFragment
    }
  }
}
${WORKSPACE_DETAIL_FRAGMENT}
`;

export const WORKSPACE_SOUND_SESSIONS_JOBS_QUERY = gql`
  query workspaceSoundSessionsJobsQuery($workspaceId: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        soundSessionsConvertJobs {
          id
          kind
          message
          date
          originalFilename
        }
      }
    }
  }
`;

export const WORKSPACE_CREATE_MUTATION = gql`
  mutation WorkspaceCreateMutation($input: WorkspaceCreateInput!) {
    workspaceCreate(input: $input) {
      workspace {
        ...WorkspaceListItemFragment
      }
    }
  }
  ${WORKSPACE_LIST_ITEM_FRAGMENT}
`;

export const WORKSPACE_RETRY_CONVERT_MUTATION = gql`
  mutation WorkspaceRetryConvertJob($input: WorkspaceRetryConvertJobInput!) {
    workspaceRetryConvertJob(input: $input) {
      success
    }
  }
`;

export const WORKSPACE_DELETE_CONVERT_MUTATION = gql`
  mutation WorkspaceDeleteConvertJob($input: WorkspaceDeleteConvertJobInput!) {
    workspaceDeleteConvertJob(input: $input) {
      success
    }
  }
`;

export const WORKSPACE_UPDATE_MUTATION = gql`
  mutation WorkspaceUpdateMutation($input: WorkspaceUpdateInput!) {
    workspaceUpdate(input: $input) {
      workspace {
        ...WorkspaceDetailFragment
      }
    }
  }
  ${WORKSPACE_DETAIL_FRAGMENT}
`;

export const WORKSPACE_DELETE_MUTATION = gql`
  mutation WorkspaceDeleteMutation($input: WorkspaceDeleteInput!) {
    workspaceDelete(input: $input) {
      success
    }
  }
`;

export const WORKSPACE_UPLOAD_FILE_MUTATION = gql`
  mutation WorkspaceUploadFileMutation($input: WorkspaceUploadFileInput!) {
    workspaceUploadFile(input: $input) {
      workspace {
        ...WorkspaceDetailFragment
      }
    }
  }
  ${WORKSPACE_DETAIL_FRAGMENT}
`;

export const WORKSPACE_CHANGE_USER_PERMISSIONS_MUTATION = gql`
  mutation WorkspaceChangeUserPermissionsMutation($input: WorkspaceChangeUserPermissionsInput!) {
    workspaceChangeUserPermissions(input: $input) {
      workspace {
        ...WorkspaceDetailFragment
      }
    }
  }
  ${WORKSPACE_DETAIL_FRAGMENT}
`;

export const WORKSPACE_CHANGE_OWNER_MUTATION = gql`
  mutation WorkspaceChangeOwnerMutation($input: WorkspaceChangeOwnerInput!) {
    workspaceChangeOwner(input: $input) {
      success
    }
  }
`;
