import { gql } from '@apollo/client';

export const SOUND_SESSION_LIST_ITEM_FRAGMENT = gql`
  fragment SoundSessionListItemFragment on SoundSession {
    id
    name
    weight
    createdAt
    reference
    source
    minTimestamp
    maxTimestamp
    minSample
    maxSample
    timezone
    weight
    recordDate
    timeMeasurePreview {
      label
    }
    sampleMeasurePreview {
      label
    }
  }
`;

export const SOUND_SESSION_DETAIL_FRAGMENT = gql`
  fragment SoundSessionDetailFragment on SoundSession {
    id
    config
    note
    timeSingleMeasures {
      id
      name
      kind
      period
      config
      weight
      totalValues
    }
    timeOctaveMeasures {
      id
      name
      kind
      period
      config
      weight
      totalValues
    }
    timeThirdOctaveMeasures {
      id
      name
      kind
      period
      config
      weight
      totalValues
    }
    timeStatisticsMeasures {
      id
      name
      kind
      period
      config
      weight
      totalValues
    }
    timeFFTMeasures {
      id
      name
      kind
      period
      config
      weight
      totalValues
    }

    sampleSingleMeasures {
      id
      name
      kind
      period
      config
      weight
    }
    sampleOctaveMeasures {
      id
      name
      kind
      period
      config
      weight
    }
    sampleThirdOctaveMeasures {
      id
      name
      kind
      period
      config
      weight
    }

    eventSingleMeasures {
      id
      name
      kind
      config
      weight
    }
    eventThirdOctaveMeasures {
      id
      name
      kind
      config
      weight
    }
    eventStatisticsMeasures {
      id
      name
      kind
      config
      weight
    }
    ...SoundSessionListItemFragment
  }
  ${SOUND_SESSION_LIST_ITEM_FRAGMENT}
`;

export const WORKSPACE_SOUND_SESSIONS_QUERY = gql`
query workspaceSoundSessionsQuery($afterCursor: String, $pageSize: Int!, $workspaceId: ID!, $filter: SoundSessionFilter) {
  viewer {
    workspace(id: $workspaceId) {
      soundSessions(first: $pageSize, after: $afterCursor, filter: $filter) {
        edges {
          node {
            ...SoundSessionListItemFragment
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
}
${SOUND_SESSION_LIST_ITEM_FRAGMENT}
`;

export const WORKSPACE_SOUND_SESSION_QUERY = gql`
  query workspaceSoundSessionQuery($workspaceId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        soundSession(id: $id) {
          ...SoundSessionDetailFragment
        }
      }
    }
  }
  ${SOUND_SESSION_DETAIL_FRAGMENT}
`;

export const SOUND_SESSION_INFO_QUERY = gql`
  query SoundSessionInfoQuery($workspaceId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        soundSession(id: $id) {
          id
          timeSingleMeasures {
            id
            period
            config
          }
          timeOctaveMeasures {
            id
            period
            config
          }
          timeThirdOctaveMeasures {
            id
            period
            config
          }
          timeStatisticsMeasures {
            id
            period
            config
          }
          timeFFTMeasures {
            id
            period
            config
          }
      
          sampleSingleMeasures {
            id
            period
            config
          }
          sampleOctaveMeasures {
            id
            period
            config
          }
          sampleThirdOctaveMeasures {
            id
            period
            config
          }
      
          eventSingleMeasures {
            id
            eventName
            config
          }
          eventThirdOctaveMeasures {
            id
            eventName
            config
          }
          eventStatisticsMeasures {
            id
            eventName
            config
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_SOUND_TIME_MEASURES_PREVIEW_QUERY = gql`
  query SoundSessionSoundTimeMeasuresPreviewQuery($workspaceId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        soundSession(id: $id) {
          id
          timeMeasurePreview {
            label
            values
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_SOUND_SAMPLE_MEASURES_PREVIEW_QUERY = gql`
  query SoundSessionSoundSampleMeasuresPreviewQuery($workspaceId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        soundSession(id: $id) {
          id
          sampleMeasurePreview {
            label
            values
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_UPDATE_MUTATION = gql`
  mutation SoundSessionUpdateMutation($input: SoundSessionUpdateInput!) {
    soundSessionUpdate(input: $input) {
      soundSession {
        ...SoundSessionListItemFragment
      }
    }
  }
  ${SOUND_SESSION_LIST_ITEM_FRAGMENT}
`;

export const SOUND_SESSION_DELETE_MUTATION = gql`
  mutation SoundSessionDeleteMutation($input: SoundSessionDeleteInput!) {
    soundSessionDelete(input: $input) {
      success
    }
  }
`;

export const SOUND_SESSION_MULTI_DELETE_MUTATION = gql`
  mutation SoundSessionMultiDeleteMutation($input: SoundSessionMultiDeleteInput!) {
    soundSessionMultiDelete(input: $input) {
      success
    }
  }
`;
