/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Form,
  Modal,
  Button,
  InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';

const OctaveMeasureChartConfigModal = (props) => {
  const {
    currentConfig,
    onFormSubmit,
    onReset,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = React.useCallback((data) => {
    onFormSubmit(data);
    onClose();
  }, [onFormSubmit]);

  const handleReset = React.useCallback(() => {
    onReset();
    onClose();
  }, [onReset]);

  return (
    <Modal
      title={t('soundMeasures.components.charts.configModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <Form
        initialValues={currentConfig}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={12} md={8}>
            <Form.Item
              label={t('soundMeasures.components.charts.configModal.minColorRange')}
              name="minColorRange"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              label={t('soundMeasures.components.charts.configModal.maxColorRange')}
              name="maxColorRange"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label={t('soundMeasures.components.charts.configModal.colorSteps')}
              name="colorSteps"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={24}>
            <Col xs={12}>
              <Button onClick={handleReset} block>
                {t('common.reset')}
              </Button>
            </Col>
            <Col xs={12}>
              <Button type="primary" htmlType="submit" block>
                {t('common.save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const propTypes = {
  currentConfig: PropTypes.any.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

OctaveMeasureChartConfigModal.propTypes = propTypes;

export default OctaveMeasureChartConfigModal;
