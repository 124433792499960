import { format, utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';
import itLocale from 'date-fns/locale/it';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

import {
  timezonesNumeric,
  timezonesByCities,
} from '../enums/timezones';

export const getLocale = (locale) => {
  let dateFnsLocale;
  const cleanLocale = locale ? locale.split('-')[0] : 'en';
  switch (cleanLocale) {
    case 'it':
      dateFnsLocale = itLocale;
      break;
    case 'es':
      dateFnsLocale = esLocale;
      break;
    case 'fr':
      dateFnsLocale = frLocale;
      break;
    case 'ru':
      dateFnsLocale = ruLocale;
      break;
    case 'en':
      dateFnsLocale = enLocale;
      break;
    default: dateFnsLocale = enLocale;
  }

  return dateFnsLocale;
};

export const formatLocaleWithTimezone = (date, form, locale, timezone, includeTimezone) => {
  try {
    const converted = utcToZonedTime(date, timezone);
    if (includeTimezone) {
      return `${format(converted, form, { locale })} [${timezone}]`;
    }
    return format(converted, form, { locale });
  }
  catch (e) {
    return 'Invalid date';
  }
};

export const formatLocale = (date, form, locale) => {
  try {
    return format(date, form, { locale });
  }
  catch {
    return 'Invalid date';
  }
};

export const getTimezoneLabelWithOffset = (timezone) => {
  const offsetInMilliseconds = getTimezoneOffset(timezone);
  const offsetInSeconds = Math.abs(offsetInMilliseconds / 1000);
  const offsetMinutes = Math.floor((offsetInSeconds / 60) % 60);
  const offsetHours = Math.floor(offsetInSeconds / 3600);
  const offsetMinutesString = offsetMinutes > 10 ? offsetMinutes : `0${offsetMinutes}`;
  const offsetHoursString = offsetHours > 10 ? offsetHours : `0${offsetHours}`;
  const offsetSignString = offsetInMilliseconds > 0 ? '+' : '-';
  let offsetString = `[${offsetSignString}${offsetHoursString}:${offsetMinutesString}]`;
  if (offsetString === '[-00:00]') offsetString = '[00:00]';
  if (timezonesNumeric.find((tz) => timezone === tz)) {
    return `GMT/UTC ${offsetString}`;
  }
  if (timezonesByCities.find((tz) => timezone === tz)) {
    return `${timezone} ${offsetString}`;
  }
  return 'unsupported Timezone';
};

export const computeDuration = (minTimestamp, maxTimestamp) => {
  const twoDays = 48 * 60 * 60 * 1000;
  const diff = maxTimestamp - minTimestamp;
  if (diff < 1000) {
    return `${diff}ms`;
  }
  if (diff < twoDays) {
    const daysms = diff % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = diff % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = diff % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);
    const secms = diff % 1000;
    const decimal = Math.floor(secms / 10);
    return `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${sec.toString().padStart(2, '0')}.${decimal.toString().padStart(2, '0')}s`;
  }
  const days = Math.floor(diff / (24 * 60 * 60 * 1000));
  const daysms = diff % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = diff % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = diff % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  const secms = diff % 1000;
  const decimal = Math.floor(secms / 10);
  return `${days}d ${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${sec.toString().padStart(2, '0')}.${decimal.toString().padStart(2, '0')}s`;
};
