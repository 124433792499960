/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Form,
  Modal,
  Button,
  InputNumber,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UNIT_OF_MEASURES } from '../utils';

const OctaveMeasureChartConfigModal = (props) => {
  const {
    currentConfig,
    onFormSubmit,
    onReset,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const unitOfMeasures = React.useMemo(() => {
    const result = Object.keys(UNIT_OF_MEASURES).map((uof) => ({
      value: UNIT_OF_MEASURES[uof],
      label: t(`common.${UNIT_OF_MEASURES[uof]}`),
    }));
    return result;
  }, [currentConfig]);

  const handleSubmit = React.useCallback((data) => {
    onFormSubmit(data);
    onClose();
  }, [onFormSubmit]);

  const handleReset = React.useCallback(() => {
    onReset();
    onClose();
  }, [onReset]);

  return (
    <Modal
      title={t('vibrationMeasures.components.charts.configModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <Form
        initialValues={currentConfig}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col xs={12} md={8}>
            <Form.Item
              label={t('vibrationMeasures.components.charts.configModal.minColorRange')}
              name="minColorRange"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={12} md={8}>
            <Form.Item
              label={t('vibrationMeasures.components.charts.configModal.maxColorRange')}
              name="maxColorRange"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label={t('vibrationMeasures.components.charts.configModal.colorSteps')}
              name="colorSteps"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              label={t('common.unitOfMeasure')}
              name="unitOfMeasure"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <Select
                options={unitOfMeasures}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12}>
            <Button onClick={handleReset} block>
              {t('common.reset')}
            </Button>
          </Col>
          <Col xs={12}>
            <Button type="primary" htmlType="submit" block>
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const propTypes = {
  currentConfig: PropTypes.any.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

OctaveMeasureChartConfigModal.propTypes = propTypes;

export default OctaveMeasureChartConfigModal;
