/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Popconfirm,
  Space,
} from 'antd';
import {
  DeleteOutlined,
  InfoOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  PageSection,
  messageError,
} from '@deltaohm/ant-components';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';

import { VIBRATION_SESSION_DELETE_MUTATION, WORKSPACE_VIBRATION_SESSIONS_QUERY, VIBRATION_SESSION_MULTI_DELETE_MUTATION } from '../../vibrationSessions/queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import {
  computeDuration,
  formatLocale,
  formatLocaleWithTimezone,
  getLocale,
} from '../../utils/date';

import {
  VibrationSessionFilter,
  VibrationSessionInfoModal,
  VibrationSessionTimePreviewModal,
} from '../../vibrationSessions/components';
import { AuthenticationContext } from '../../authentications';

const WorkspaceVibrationSessionsTab = (props) => {
  const {
    tab,
    workspace,
  } = props;

  const [infoVibrationSession, setInfoVibrationSession] = React.useState(null);
  const [timePreviewVibrationSession, setTimePreviewVibrationSession] = React.useState(null);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { quota } = React.useContext(AuthenticationContext);

  const availableQuota = React.useMemo(() => {
    const {
      workspaceAvailableQuota,
    } = quota;
    return workspaceAvailableQuota;
  }, [quota]);

  const [searchObject, setSearchObject] = React.useState({
    search: null,
    from: null,
    to: null,
  });

  const locale = getLocale(i18n.language);

  const {
    error,
    loading,
    data,
    refetch,
    fetchMore,
  } = useQuery(WORKSPACE_VIBRATION_SESSIONS_QUERY, {
    variables: {
      workspaceId: workspace.id,
      filter: searchObject,
      pageSize: 20,
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => refetch(), [tab]);

  const [
    vibrationSessionDeleteMutationAction,
    {
      loading: loadingDelete,
      error: errorDelete,
    },
  ] = useMutation(VIBRATION_SESSION_DELETE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACE_VIBRATION_SESSIONS_QUERY,
          variables: {
            workspaceId: workspace.id,
            filter: searchObject,
            pageSize: 20,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    vibrationSessionMultiDeleteMutationAction,
    {
      loading: loadingMultiDelete,
      error: errorMultiDelete,
    },
  ] = useMutation(VIBRATION_SESSION_MULTI_DELETE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACE_VIBRATION_SESSIONS_QUERY,
          variables: {
            workspaceId: workspace.id,
            filter: searchObject,
            pageSize: 20,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  React.useEffect(() => {
    if (error || errorDelete || errorMultiDelete) {
      const errors = extractGraphQLErrors(error || errorDelete || errorMultiDelete, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, errorDelete, t]);

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { workspace: queryWorkspace } = viewer;
      const { vibrationSessions: vibrationSessionConnection } = queryWorkspace;
      const { pageInfo, edges } = vibrationSessionConnection;
      return {
        vibrationSessions: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      vibrationSessions: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: queryResult.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.workspace.vibrationSessions;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult
          .viewer.workspace.vibrationSessions;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              workspace: {
                ...previousResult.viewer.workspace,
                vibrationSessions: {
                  edges: [...oldEdges, ...newEdges],
                  pageInfo: newPageInfo,
                },
              },
            },
          }
          : previousResult;
      },
    });
  };

  const handleSearch = React.useCallback((newSearchObject) => {
    setSearchObject((old) => ({
      ...old,
      ...newSearchObject,
    }));
  }, [setSearchObject]);

  const columns = React.useMemo(() => ([
    {
      title: t('workspaces.vibrationSessionsTab.table.name'),
      dataIndex: 'name',
      fixed: 'left',
      render: (name) => (
        <div style={{ width: 200 }}>
          {name}
        </div>
      ),
    },
    {
      title: t('workspaces.vibrationSessionsTab.table.createdAt'),
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <div style={{ width: 200 }}>
          {formatLocale(createdAt, 'PPpp', locale)}
        </div>
      ),
    },
    {
      title: t('workspaces.vibrationSessionsTab.table.recordDate'),
      dataIndex: 'recordDate',
      render: (recordDate, record) => (
        <div style={{ width: 200 }}>
          <Tooltip title={formatLocaleWithTimezone(recordDate, 'PPpp', locale, record.timezone, true)}>
            {formatLocale(recordDate, 'PPpp', locale)}
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('workspaces.vibrationSessionsTab.table.source'),
      dataIndex: 'source',
    },
    {
      title: t('workspaces.vibrationSessionsTab.table.reference'),
      dataIndex: 'reference',
    },
    {
      title: t('workspaces.vibrationSessionsTab.table.duration'),
      dataIndex: 'minTimestamp',
      render: (minTimestamp, record) => {
        if (record.minTimestamp && record.maxTimestamp) {
          return computeDuration(record.minTimestamp, record.maxTimestamp);
        }
        return null;
      },
    },
    {
      title: t('workspaces.vibrationSessionsTab.table.weight'),
      dataIndex: 'weight',
      render: (weight) => {
        if (availableQuota === null) {
          return weight;
        }
        return `${(weight / availableQuota).toFixed(2)}%`;
      },
    },
    {
      title: t('common.actions'),
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => {
        const result = (
          <Space size="small">
            <Tooltip title={t('common.detail')}>
              <Button shape="circle" size="small" type="primary" onClick={() => navigate(`/workspaces/${workspace.id}/vibrationSessions/${id}`)}>
                <RightOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={t('common.info')}>
              <Button shape="circle" size="small" type="primary" onClick={() => setInfoVibrationSession(record)}>
                <InfoOutlined />
              </Button>
            </Tooltip>
            {record.timeMeasurePreview && (
              <Tooltip title={t('workspaces.vibrationSessionsTab.table.timePreview')}>
                <Button shape="circle" size="small" type="primary" onClick={() => setTimePreviewVibrationSession(record)}>
                  <SearchOutlined />
                </Button>
              </Tooltip>
            )}
            <Popconfirm
              title={t('common.sureToDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => vibrationSessionDeleteMutationAction({
                variables: {
                  input: {
                    workspaceId: workspace.id,
                    id,
                  },
                },
              })}
            >
              <Tooltip title={t('common.delete')}>
                <Button shape="circle" size="small" type="ghost">
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        );
        return result;
      },
    },
  ]), [t]);

  const tableFooter = React.useMemo(() => {
    if (queryResult.pageInfo && queryResult.pageInfo.hasNextPage) {
      return () => (
        <Button type="primary" block onClick={handleLoadMore}>
          {t('common.loadMore')}
        </Button>
      );
    }
    return null;
  }, [queryResult, handleLoadMore]);

  return (
    <>
      <PageSection
        title={t('workspaces.vibrationSessionsTab.title')}
        subtitle={t('workspaces.vibrationSessionsTab.subtitle')}
      >
        <Row>
          <Col xs={24}>
            <VibrationSessionFilter
              onSearch={handleSearch}
            />
          </Col>
        </Row>
        <Row justify="end" style={{ marginTop: 8, marginBottom: 8 }}>
          <Popconfirm
            title={t('common.sureToDeleteInView')}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={() => vibrationSessionMultiDeleteMutationAction({
              variables: {
                input: {
                  workspaceId: workspace.id,
                  ids: queryResult.vibrationSessions.map((ss) => ss.id),
                },
              },
            })}
          >
            <Button>
              {t('common.deleteInView')}
            </Button>
          </Popconfirm>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              size="small"
              scroll={{ x: true }}
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={queryResult.vibrationSessions}
              loading={loading || loadingDelete || loadingMultiDelete}
              pagination={false}
              footer={tableFooter}
            />
          </Col>
        </Row>
      </PageSection>
      {infoVibrationSession && (
        <VibrationSessionInfoModal
          workspaceId={workspace.id}
          vibrationSession={infoVibrationSession}
          isModalOpen={infoVibrationSession !== null}
          onClose={() => setInfoVibrationSession(null)}
        />
      )}
      {timePreviewVibrationSession && (
        <VibrationSessionTimePreviewModal
          workspaceId={workspace.id}
          vibrationSession={timePreviewVibrationSession}
          onClose={() => setTimePreviewVibrationSession(null)}
          isModalOpen={timePreviewVibrationSession !== null}
        />
      )}
    </>
  );
};

const propTypes = {
  workspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  tab: PropTypes.string.isRequired,
};

WorkspaceVibrationSessionsTab.propTypes = propTypes;

export default WorkspaceVibrationSessionsTab;
