import React from 'react';
import PropTypes from 'prop-types';
import { ComponentLoader, messageError } from '@deltaohm/ant-components';
import { t } from 'i18next';

import {
  SingleMeasureChart,
  OctaveMeasureChart,
  StatisticsMeasureChart,
} from '../../charts/times';

import { useVibrationTimeMeasureValues } from '../../hooks';
import { extractGraphQLErrors } from '../../../utils/graphql';
import { ErrorList } from '../../../generics';

const VibrationMeasureChartContainer = (props) => {
  const {
    from,
    to,
    workspaceId,
    vibrationSessionId,
    vibrationMeasureId,
    onRequestFilter,
    type,
  } = props;

  const {
    loading,
    error,
    decoding,
    currentVibrationTimeSeries,
    vibrationSession,
    vibrationMeasure,
    changeTimestamps,
  } = useVibrationTimeMeasureValues(workspaceId, vibrationSessionId, vibrationMeasureId, from, to);

  React.useEffect(() => {
    changeTimestamps(from, to);
  }, [from, to, changeTimestamps]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  return (
    <ComponentLoader
      loading={loading || decoding}
      tip={loading ? t('common.loading') : t('common.decoding')}
    >
      {vibrationMeasure && type === 'single' && (
        <SingleMeasureChart
          workspaceId={workspaceId}
          vibrationSession={vibrationSession}
          measure={vibrationMeasure}
          timeSeries={currentVibrationTimeSeries}
          onRequestFilter={onRequestFilter}
        />
      )}
      {vibrationMeasure && type === 'octave' && (
        <OctaveMeasureChart
          workspaceId={workspaceId}
          vibrationSession={vibrationSession}
          measure={vibrationMeasure}
          onRequestFilter={onRequestFilter}
        />
      )}
      {vibrationMeasure && type === 'thirdOctave' && (
        <OctaveMeasureChart
          workspaceId={workspaceId}
          vibrationSession={vibrationSession}
          measure={vibrationMeasure}
          onRequestFilter={onRequestFilter}
        />
      )}
      {vibrationMeasure && type === 'statistics' && (
        <StatisticsMeasureChart
          workspaceId={workspaceId}
          vibrationSession={vibrationSession}
          measure={vibrationMeasure}
          onRequestFilter={onRequestFilter}
        />
      )}
    </ComponentLoader>
  );
};

const propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  workspaceId: PropTypes.string.isRequired,
  vibrationSessionId: PropTypes.string.isRequired,
  vibrationMeasureId: PropTypes.string.isRequired,
  onRequestFilter: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['single', 'octave', 'thirdOctave', 'statistics']).isRequired,
};

VibrationMeasureChartContainer.propTypes = propTypes;

export default VibrationMeasureChartContainer;
