import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

import {
  useVibrationSessionTimePreview,
} from '../hooks';
import { DECIMALS, UNIT_OF_MEASURES } from '../../vibrationMeasures/charts/utils';

const VibrationSessionTimePreviewModal = (props) => {
  const {
    workspaceId,
    vibrationSession,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    error,
    loading,
    preview,
  } = useVibrationSessionTimePreview(workspaceId, vibrationSession.id);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const chartRef = React.useRef(null);
  const [chartInstance, setChartInstance] = React.useState(null);

  const dataset = React.useMemo(() => {
    if (preview) {
      const { values } = preview;

      const dataSource = values.map((v) => ({
        timestamp: utcToZonedTime(v.timestamp, vibrationSession.timezone).getTime(),
        ...v.data,
        timeSeries: null,
      }));

      const result = [
        {
          dimensions: ['timestamp', ...preview.config.map((c) => c.measureId)],
          source: dataSource,
        },
      ];

      return result;
    }
    return null;
  }, [preview]);

  const chartTitle = React.useMemo(() => {
    // if (preview) {
    //   if (preview.label) {
    //     return preview.label;
    //   }
    // }
    // TODO: che titolo mettere
    const result = t('common.preview');
    return result;
  }, [preview]);

  const getLegendOption = () => ({
    id: 'legend',
    top: '7%',
    right: '5%',
    type: 'scroll',
    orient: 'horizontal',
    scrollable: 'scroll',
    data: preview.config.map((conf) => conf.label),
  });

  const getSeriesOption = () => {
    const result = [];
    const { config } = preview;
    for (let i = 0; i < config.length; i += 1) {
      const conf = config[i];
      const name = conf.label;
      const thisSerie = {
        id: conf.measureId,
        step: 'start',
        name,
        type: 'line',
        sampling: 'lttb',
        showSymbol: false,
        datasetIndex: 0,
        yAxisIndex: 0,
        xAxisIndex: 0,
        encode: {
          x: 'timestamp',
          y: conf.measureId,
        },
      };
      result.push(thisSerie);
    }

    return result;
  };

  const getInitialChartOption = () => {
    const result = {
      animation: false,
      title: {
        id: 'title',
        text: chartTitle,
      },
      grid: {
        id: 'graph_grid',
        show: true,
        top: '20%',
        bottom: '15%',
      },
      legend: getLegendOption(),
      tooltip: {
        id: 'tooltip',
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          snap: true,
        },
        showContent: true,
        formatter: (params) => {
          let timestamp;
          const series = [];
          for (let i = 0; i < params.length; i += 1) {
            const current = params[i];
            timestamp = current.value.timestamp;
            const { value } = current.value;
            if (value != null) {
              const realValue = value.toFixed(DECIMALS[UNIT_OF_MEASURES.MS2]);
              const unitOfMeasure = t('common.ms2');
              series.push(`${current.marker} ${current.seriesName}: ${realValue} ${unitOfMeasure}`);
            }
          }
          const tooltip = `${format(timestamp, 'yyyy-MM-dd HH:mm:ss.SSS')}\n${series.join('\n')}`;
          return tooltip;
        },
      },
      dataZoom: [
        {
          id: 'x_zoom_slider',
          type: 'slider',
          show: true,
          xAxisIndex: [0],
          start: 0,
          end: 100,
          height: '3%',
          bottom: '3%',
          filterMode: 'none',
        },
        {
          id: 'y_zoom_slider',
          type: 'slider',
          show: true,
          yAxisIndex: [0],
          left: '1%',
          start: 0,
          end: 100,
          width: '3%',
          filterMode: 'none',
        },
        {
          id: 'x_zoom_inside',
          type: 'inside',
          xAxisIndex: [0],
          start: 0,
          end: 100,
          filterMode: 'none',
        },
      ],
      dataset,
      xAxis: {
        id: 'x_data',
        type: 'time',
        name: t('common.time'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        gridIndex: 0,
      },
      yAxis: {
        id: 'y_data',
        type: 'value',
        name: t('common.value'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        gridIndex: 0,
      },
      series: getSeriesOption(),
    };

    return result;
  };

  React.useEffect(() => {
    if (chartInstance) {
      return () => {
        chartInstance.dispose();
      };
    }
    return () => { };
  }, [chartInstance]);

  React.useEffect(() => {
    if (preview && chartRef && chartRef.current && !chartInstance) {
      const instance = echarts.init(chartRef.current, null);
      instance.setOption(getInitialChartOption());
      setChartInstance(instance);
    }
  }, [chartRef, preview]);

  return (
    <Modal
      title={t('common.info')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        <div style={{ minHeight: 400 }} ref={chartRef} />
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  vibrationSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

VibrationSessionTimePreviewModal.propTypes = propTypes;

export default VibrationSessionTimePreviewModal;
