/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import {
  Row,
  Col,
  Form,
  Modal,
  Button,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { UNIT_OF_MEASURES } from '../utils';

const SingleMeasureChartConfigModal = (props) => {
  const {
    currentConfig,
    onFormSubmit,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = React.useCallback((data) => {
    onFormSubmit(data);
    onClose();
  }, [onFormSubmit]);

  const unitOfMeasures = React.useMemo(() => {
    const result = Object.keys(UNIT_OF_MEASURES).map((uof) => ({
      value: UNIT_OF_MEASURES[uof],
      label: t(`common.${UNIT_OF_MEASURES[uof]}`),
    }));
    return result;
  }, [currentConfig]);

  return (
    <Modal
      title={t('vibrationMeasures.components.charts.configModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <Form
        initialValues={currentConfig}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Row gutter={24}>
          {Object.keys(currentConfig.colors).map((key) => (
            <Col xs={12} md={4} key={key}>
              <Form.Item
                label={key}
                name={['colors', key]}
                rules={[{ required: true, message: t('validations.required') }]}
                valuePropName="color"
                getValueFromEvent={(e) => e.hex}
              >
                <SketchPicker
                  width="100%"
                  disableAlpha
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              label={t('common.unitOfMeasure')}
              name="unitOfMeasure"
              rules={[{ required: true, message: t('validations.required') }]}
            >
              <Select
                options={unitOfMeasures}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Button type="primary" htmlType="submit" block>
              {t('common.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const propTypes = {
  currentConfig: PropTypes.any.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SingleMeasureChartConfigModal.propTypes = propTypes;

export default SingleMeasureChartConfigModal;
