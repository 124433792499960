import React from 'react';

import {
  Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useWorkspace } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';
import {
  WorkspaceSoundSessionsTab,
  WorkspaceVibrationSessionsTab,
  WorkspaceFileTab,
  WorkspacePropertyTab,
  WorkspaceUserTab,
} from './tabs';

import WORKSPACE_ROLES from '../enums/workspaceRoles';

const { TabPane } = Tabs;

const WorkspaceDetailPage = () => {
  const { workspaceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const {
    error,
    loading,
    workspace,
  } = useWorkspace(workspaceId);

  const canUpdateUserPermissions = React.useMemo(() => {
    if (workspace) {
      const correctRole = workspace.myRoles.includes(WORKSPACE_ROLES.WORKSPACE_MANAGER);
      return workspace.iAmOwner || correctRole;
    }
    return false;
  }, [workspace]);

  const canManageWorkspace = React.useMemo(() => {
    if (workspace) {
      const correctRole = workspace.myRoles.includes(WORKSPACE_ROLES.WORKSPACE_MANAGER);
      return workspace.iAmOwner || correctRole;
    }
    return false;
  }, [workspace]);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/workspaces',
        breadcrumbName: 'Workspaces',
      },
    ];
    if (workspace) {
      result.push({
        path: `/workspaces/${workspaceId}`,
        breadcrumbName: workspace.name,
      });
    }
    return result;
  }, [workspace]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const activeTab = React.useMemo(() => {
    const tab = searchParams.get('tab');
    if (!tab) {
      return 'soundSessions';
    }
    return tab;
  }, [searchParams]);

  const handleChangeTab = React.useCallback((newTab) => {
    setSearchParams({ tab: newTab });
  }, [setSearchParams]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={loading}
    >
      <PageSection
        title={workspace ? workspace.name : ''}
        breadcrumb={breadcrumb}
      >
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <TabPane tab={t('workspaces.detail.tabs.soundSessions')} key="soundSessions">
            {workspace && (
              <WorkspaceSoundSessionsTab
                workspace={workspace}
                tab={activeTab}
              />
            )}
          </TabPane>
          <TabPane tab={t('workspaces.detail.tabs.vibrationSessions')} key="vibrationSessions">
            {workspace && (
              <WorkspaceVibrationSessionsTab
                workspace={workspace}
                tab={activeTab}
              />
            )}
          </TabPane>
          {canManageWorkspace && (
            <TabPane tab={t('workspaces.detail.tabs.file')} key="file">
              {workspace && (
                <WorkspaceFileTab
                  workspace={workspace}
                />
              )}
            </TabPane>
          )}
          {canUpdateUserPermissions && (
            <TabPane tab={t('workspaces.detail.tabs.users')} key="users">
              {workspace && (
                <WorkspaceUserTab
                  workspace={workspace}
                />
              )}
            </TabPane>
          )}
          {canManageWorkspace && (
            <TabPane tab={t('workspaces.detail.tabs.properties')} key="properties">
              {workspace && (
                <WorkspacePropertyTab
                  workspace={workspace}
                />
              )}
            </TabPane>
          )}
        </Tabs>
      </PageSection>
    </ComponentLoader>
  );
};

export default WorkspaceDetailPage;
