import React from 'react';
import { useQuery } from '@apollo/client';
import { decodeAsync } from '@msgpack/msgpack';

import {
  VIBRATION_SESSION_VIBRATION_TIME_MEASURES_PREVIEW_QUERY,
} from '../vibrationSessions/queries';

import {
  VIBRATION_SESSION_VIBRATION_TIME_MEASURES_DETAILS_QUERY,
  VIBRATION_SESSION_VIBRATION_TIME_MEASURES_VALUES_QUERY,
} from './queries';

export const useVibrationTimeMeasureDetail = (
  workspaceId,
  vibrationSessionId,
  vibrationMeasureId,
) => {
  const [previewValues, setPreviewValues] = React.useState(null);
  const [previewDecoding, setPreviewDecoding] = React.useState(false);

  const {
    error: detailError,
    loading: detailLoading,
    data: detailData,
    refetch,
  } = useQuery(VIBRATION_SESSION_VIBRATION_TIME_MEASURES_DETAILS_QUERY, {
    variables: {
      workspaceId,
      vibrationSessionId,
      id: vibrationMeasureId,
      from: null,
    },
  });

  const {
    error: previewError,
    loading: previewLoading,
    data: previewData,
  } = useQuery(VIBRATION_SESSION_VIBRATION_TIME_MEASURES_PREVIEW_QUERY, {
    variables: {
      workspaceId,
      id: vibrationSessionId,
    },
  });

  const workspace = React.useMemo(() => {
    if (!detailLoading && !detailError) {
      return detailData.viewer.workspace;
    }
    return null;
  }, [detailError, detailLoading, detailData]);

  const vibrationSession = React.useMemo(() => {
    if (workspace) {
      return workspace.vibrationSession;
    }
    return null;
  }, [workspace]);

  const vibrationMeasure = React.useMemo(() => {
    if (vibrationSession) {
      return vibrationSession.measure;
    }
    return null;
  }, [vibrationSession]);

  const previewQueryData = React.useMemo(() => {
    if (!previewLoading && !previewError) {
      return previewData.viewer.workspace.vibrationSession.timeMeasurePreview;
    }
    return null;
  }, [previewLoading, previewError, previewData]);

  React.useEffect(() => {
    if (previewQueryData) {
      const decode = async () => {
        setPreviewDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${previewQueryData.values}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setPreviewValues(decoded);
        setPreviewDecoding(false);
      };
      decode();
    }
  }, [previewQueryData, previewError, previewData, setPreviewDecoding, setPreviewValues]);

  const preview = React.useMemo(() => {
    if (previewQueryData && previewValues) {
      return {
        ...previewQueryData,
        values: previewValues,
      };
    }
    return null;
  }, [previewQueryData, previewValues]);

  const setFrom = React.useCallback((from) => {
    refetch({
      workspaceId,
      vibrationSessionId,
      id: vibrationMeasureId,
      from,
    });
  }, [refetch]);

  return {
    workspace,
    vibrationSession,
    vibrationMeasure,
    detailLoading,
    previewLoading,
    previewDecoding,
    error: detailError || previewError,
    preview,
    setFrom,
  };
};

export const useVibrationTimeMeasureValues = (
  workspaceId,
  vibrationSessionId,
  vibrationMeasureId,
  from,
  to,
) => {
  const [values, setValues] = React.useState(null);
  const [decoding, setDecoding] = React.useState(true);

  const {
    error,
    loading,
    data,
    refetch,
  } = useQuery(VIBRATION_SESSION_VIBRATION_TIME_MEASURES_VALUES_QUERY, {
    variables: {
      workspaceId,
      vibrationSessionId,
      id: vibrationMeasureId,
      filter: {
        from,
        to,
      },
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [loading, error, data]);

  const vibrationSession = React.useMemo(() => {
    if (workspace) {
      return workspace.vibrationSession;
    }
    return null;
  }, [workspace]);

  const vibrationMeasure = React.useMemo(() => {
    if (vibrationSession) {
      return vibrationSession.measure;
    }
    return null;
  }, [vibrationSession]);

  const valuesPacked = React.useMemo(() => {
    if (vibrationMeasure) {
      const result = vibrationMeasure.values;
      return result;
    }
    return null;
  }, [vibrationMeasure]);

  React.useEffect(() => {
    if (valuesPacked) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${valuesPacked}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      decode();
    }
  }, [valuesPacked, setValues, setDecoding]);

  const currentMinTimestamp = React.useMemo(() => {
    if (values && values.length) {
      return values[0].timestamp;
    }
    return null;
  }, [values]);

  const currentMaxTimestamp = React.useMemo(() => {
    if (values && values.length) {
      return values[values.length - 1].timestamp;
    }
    return null;
  }, [values]);

  const resultVibrationMeasure = React.useMemo(() => {
    if (vibrationMeasure && values) {
      return {
        ...vibrationMeasure,
        values,
      };
    }
    return null;
  }, [vibrationMeasure, values]);

  const currentVibrationTimeSeries = React.useMemo(() => {
    if (currentMinTimestamp && currentMaxTimestamp && vibrationSession) {
      const { vibrationTimeSeries } = vibrationSession;
      const newVibrationSeries = vibrationTimeSeries.map((ss) => ({
        ...ss,
        values: [...ss.values],
      }));
      for (let i = 0; i < newVibrationSeries.length; i += 1) {
        const vibrationSerie = newVibrationSeries[i];
        vibrationSerie.values = vibrationSerie.values.reduce((acc, v) => {
          if ((currentMinTimestamp >= v.from && currentMinTimestamp <= v.to)
          || (currentMaxTimestamp >= v.from && currentMaxTimestamp <= v.to)
          || (v.from >= currentMinTimestamp && v.from <= currentMaxTimestamp)
          || (v.to >= currentMinTimestamp && v.to <= currentMaxTimestamp)) {
            const newFrom = v.from < currentMinTimestamp ? currentMinTimestamp : v.from;
            const newTo = v.to > currentMaxTimestamp ? currentMaxTimestamp : v.to;
            acc.push({
              ...v,
              from: newFrom,
              to: newTo,
            });
          }
          return acc;
        }, []);
      }

      const result = newVibrationSeries.filter((ss) => ss.values.length);
      return result;
    }
    return [];
  }, [currentMinTimestamp, currentMaxTimestamp, vibrationMeasure]);

  const changeTimestamps = React.useCallback((newFrom, newTo) => {
    refetch({
      workspaceId,
      vibrationSessionId,
      id: vibrationMeasureId,
      filter: {
        from: newFrom,
        to: newTo,
      },
    });
  }, [refetch]);

  return {
    workspace,
    vibrationSession,
    vibrationMeasure: resultVibrationMeasure,
    loading,
    error,
    decoding,
    currentVibrationTimeSeries,
    changeTimestamps,
  };
};
