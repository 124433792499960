import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Tooltip,
  Button,
  Space,
  Table,
} from 'antd';
import {
  RightOutlined,
  SearchOutlined,
  InfoOutlined,
} from '@ant-design/icons';

import {
  PageSection,
} from '@deltaohm/ant-components';
import { useNavigate } from 'react-router-dom';
import {
  VibrationSessionTimePreviewModal,
} from '../components';

import { VibrationMeasureConfigModal } from '../../vibrationMeasures/components';
import { AuthenticationContext } from '../../authentications';

const VibrationSessionMeasuresTab = (props) => {
  const {
    workspaceId,
    vibrationSession,
  } = props;

  const { quota } = React.useContext(AuthenticationContext);

  const availableQuota = React.useMemo(() => {
    const {
      workspaceAvailableQuota,
    } = quota;
    return workspaceAvailableQuota;
  }, [quota]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [vibrationMeasureConfig, setVibrationMeasureConfig] = React.useState(null);
  const [showTimePreview, setShowTimePreview] = React.useState(false);

  const dataSource = React.useMemo(() => {
    const result = [];

    result.push(...vibrationSession.timeSingleMeasures);
    result.push(...vibrationSession.timeOctaveMeasures);
    result.push(...vibrationSession.timeThirdOctaveMeasures);
    result.push(...vibrationSession.timeStatisticsMeasures);

    return result;
  }, [vibrationSession]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('vibrationSessions.measuresTab.table.name'),
        dataIndex: 'name',
        fixed: 'left',
      },
      {
        title: t('vibrationSessions.measuresTab.table.kind'),
        dataIndex: 'kind',
        render: (kind) => {
          const lower = kind.charAt(0).toLowerCase() + kind.slice(1);
          return t(`vibrationMeasures.kinds.${lower}`);
        },
      },
      {
        title: t('vibrationSessions.measuresTab.table.weight'),
        dataIndex: 'weight',
        render: (weight) => {
          if (availableQuota === null) {
            return weight;
          }
          return `${(weight / availableQuota).toFixed(2)}%`;
        },
      },
      {
        title: t('common.actions'),
        dataIndex: 'kind',
        render: (kind, record) => {
          const actions = [
            <Tooltip key={`${record.id}-detail`} title={t('common.detail')}>
              <Button shape="circle" type="primary" onClick={() => navigate(`/workspaces/${workspaceId}/vibrationSessions/${vibrationSession.id}/${kind}/${record.id}`)}>
                <RightOutlined />
              </Button>
            </Tooltip>,
            <Tooltip key={`${record.id}-info`} title={t('common.info')}>
              <Button shape="circle" type="primary" onClick={() => setVibrationMeasureConfig(record)}>
                <InfoOutlined />
              </Button>
            </Tooltip>,
          ];
          if (kind.includes('Time')) {
            actions.push((
              <Tooltip key={`${record.id}-tooltip`} title={t('common.preview')}>
                <Button shape="circle" type="primary" onClick={() => setShowTimePreview(true)}>
                  <SearchOutlined />
                </Button>
              </Tooltip>
            ));
          }
          return (
            <Space size="small">
              {actions}
            </Space>
          );
        },
      },
    ];

    return result;
  }, []);

  return (
    <>
      <PageSection
        title={t('vibrationSessions.measuresTab.title')}
      >
        <Row>
          <Col xs={24}>
            <Table
              bordered
              size="small"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={dataSource}
              pagination={false}
            />
          </Col>
        </Row>
      </PageSection>
      {vibrationMeasureConfig && (
        <VibrationMeasureConfigModal
          workspaceId={workspaceId}
          vibrationSessionId={vibrationSession.id}
          vibrationMeasure={vibrationMeasureConfig}
          isModalOpen={vibrationMeasureConfig != null}
          onClose={() => setVibrationMeasureConfig(null)}
        />
      )}
      {showTimePreview && (
        <VibrationSessionTimePreviewModal
          workspaceId={workspaceId}
          vibrationSession={vibrationSession}
          onClose={() => setShowTimePreview(false)}
          isModalOpen={showTimePreview}
        />
      )}
    </>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  vibrationSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timeSingleMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    timeOctaveMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    timeThirdOctaveMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    timeStatisticsMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
  }).isRequired,
};

VibrationSessionMeasuresTab.propTypes = propTypes;

export default VibrationSessionMeasuresTab;
