import React from 'react';
import { useQuery } from '@apollo/client';
import { decodeAsync } from '@msgpack/msgpack';

import {
  SOUND_SESSION_SOUND_TIME_MEASURES_PREVIEW_QUERY,
  SOUND_SESSION_SOUND_SAMPLE_MEASURES_PREVIEW_QUERY,
} from '../soundSessions/queries';

import {
  SOUND_SESSION_SOUND_TIME_MEASURES_DETAILS_QUERY,
  SOUND_SESSION_SOUND_TIME_MEASURES_VALUES_QUERY,
  SOUND_SESSION_SOUND_TIME_GLOBAL_MEASURES_VALUES_QUERY,
  SOUND_SESSION_SOUND_SAMPLE_MEASURES_DETAILS_QUERY,
  SOUND_SESSION_SOUND_SAMPLE_MEASURES_VALUES_QUERY,
  SOUND_SESSION_SOUND_EVENT_MEASURES_DETAILS_QUERY,
  SOUND_SESSION_SOUND_EVENT_MEASURES_VALUES_QUERY,
} from './queries';

export const useSoundTimeMeasureDetail = (workspaceId, soundSessionId, soundMeasureId) => {
  const [previewValues, setPreviewValues] = React.useState(null);
  const [previewDecoding, setPreviewDecoding] = React.useState(false);

  const {
    error: detailError,
    loading: detailLoading,
    data: detailData,
    refetch,
  } = useQuery(SOUND_SESSION_SOUND_TIME_MEASURES_DETAILS_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      from: null,
    },
  });

  const {
    error: previewError,
    loading: previewLoading,
    data: previewData,
  } = useQuery(SOUND_SESSION_SOUND_TIME_MEASURES_PREVIEW_QUERY, {
    variables: {
      workspaceId,
      id: soundSessionId,
    },
  });

  const workspace = React.useMemo(() => {
    if (!detailLoading && !detailError) {
      return detailData.viewer.workspace;
    }
    return null;
  }, [detailError, detailLoading, detailData]);

  const soundSession = React.useMemo(() => {
    if (workspace) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  const soundMeasure = React.useMemo(() => {
    if (soundSession) {
      return soundSession.measure;
    }
    return null;
  }, [soundSession]);

  const previewQueryData = React.useMemo(() => {
    if (!previewLoading && !previewError) {
      return previewData.viewer.workspace.soundSession.timeMeasurePreview;
    }
    return null;
  }, [previewLoading, previewError, previewData]);

  React.useEffect(() => {
    if (previewQueryData) {
      const decode = async () => {
        setPreviewDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${previewQueryData.values}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setPreviewValues(decoded);
        setPreviewDecoding(false);
      };
      decode();
    }
  }, [previewQueryData, previewError, previewData, setPreviewDecoding, setPreviewValues]);

  const preview = React.useMemo(() => {
    if (previewQueryData && previewValues) {
      return {
        ...previewQueryData,
        values: previewValues,
      };
    }
    return null;
  }, [previewQueryData, previewValues]);

  const setFrom = React.useCallback((from) => {
    refetch({
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      from,
    });
  }, [refetch]);

  return {
    workspace,
    soundSession,
    soundMeasure,
    detailLoading,
    previewLoading,
    previewDecoding,
    error: detailError || previewError,
    preview,
    setFrom,
  };
};

export const useSoundTimeMeasureValues = (
  workspaceId,
  soundSessionId,
  soundMeasureId,
  from,
  to,
) => {
  const [values, setValues] = React.useState(null);
  const [decoding, setDecoding] = React.useState(true);

  const {
    error,
    loading,
    data,
    refetch,
  } = useQuery(SOUND_SESSION_SOUND_TIME_MEASURES_VALUES_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      filter: {
        from,
        to,
      },
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [loading, error, data]);

  const soundSession = React.useMemo(() => {
    if (workspace) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  const soundMeasure = React.useMemo(() => {
    if (soundSession) {
      return soundSession.measure;
    }
    return null;
  }, [soundSession]);

  const valuesPacked = React.useMemo(() => {
    if (soundMeasure) {
      const result = soundMeasure.values;
      return result;
    }
    return null;
  }, [soundMeasure]);

  React.useEffect(() => {
    if (valuesPacked) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${valuesPacked}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      decode();
    }
  }, [valuesPacked, setValues, setDecoding]);

  const currentMinTimestamp = React.useMemo(() => {
    if (values && values.length) {
      return values[0].timestamp;
    }
    return null;
  }, [values]);

  const currentMaxTimestamp = React.useMemo(() => {
    if (values && values.length) {
      return values[values.length - 1].timestamp;
    }
    return null;
  }, [values]);

  const resultSoundMeasure = React.useMemo(() => {
    if (soundMeasure && values) {
      return {
        ...soundMeasure,
        values,
      };
    }
    return null;
  }, [soundMeasure, values]);

  const currentSoundTimeSeries = React.useMemo(() => {
    if (currentMinTimestamp && currentMaxTimestamp && soundSession) {
      const { soundTimeSeries } = soundSession;
      const newSoundSeries = soundTimeSeries.map((ss) => ({
        ...ss,
        values: [...ss.values],
      }));
      for (let i = 0; i < newSoundSeries.length; i += 1) {
        const soundSerie = newSoundSeries[i];
        soundSerie.values = soundSerie.values.reduce((acc, v) => {
          if ((currentMinTimestamp >= v.from && currentMinTimestamp <= v.to)
          || (currentMaxTimestamp >= v.from && currentMaxTimestamp <= v.to)
          || (v.from >= currentMinTimestamp && v.from <= currentMaxTimestamp)
          || (v.to >= currentMinTimestamp && v.to <= currentMaxTimestamp)) {
            const newFrom = v.from < currentMinTimestamp ? currentMinTimestamp : v.from;
            const newTo = v.to > currentMaxTimestamp ? currentMaxTimestamp : v.to;
            acc.push({
              ...v,
              from: newFrom,
              to: newTo,
            });
          }
          return acc;
        }, []);
      }

      const result = newSoundSeries.filter((ss) => ss.values.length);
      return result;
    }
    return [];
  }, [currentMinTimestamp, currentMaxTimestamp, soundMeasure]);

  const changeTimestamps = React.useCallback((newFrom, newTo) => {
    refetch({
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      filter: {
        from: newFrom,
        to: newTo,
      },
    });
  }, [refetch]);

  return {
    workspace,
    soundSession,
    soundMeasure: resultSoundMeasure,
    loading,
    error,
    decoding,
    currentSoundTimeSeries,
    changeTimestamps,
  };
};

export const useSoundTimeGlobalMeasureValues = (
  workspaceId,
  soundSessionId,
  soundMeasureId,
) => {
  const [values, setValues] = React.useState(null);
  const [decoding, setDecoding] = React.useState(true);

  const {
    error,
    loading,
    data,
  } = useQuery(SOUND_SESSION_SOUND_TIME_GLOBAL_MEASURES_VALUES_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [loading, error, data]);

  const soundSession = React.useMemo(() => {
    if (workspace) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  const soundMeasure = React.useMemo(() => {
    if (soundSession) {
      return soundSession.measure;
    }
    return null;
  }, [soundSession]);

  const valuesPacked = React.useMemo(() => {
    if (soundMeasure) {
      const result = soundMeasure.values;
      return result;
    }
    return null;
  }, [soundMeasure]);

  const timeSeries = React.useMemo(() => {
    if (soundSession) {
      const { soundTimeSeries } = soundSession;
      return soundTimeSeries;
    }
    return [];
  }, [soundSession]);

  React.useEffect(() => {
    if (valuesPacked) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${valuesPacked}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      decode();
    }
  }, [valuesPacked, setValues, setDecoding]);

  const resultSoundMeasure = React.useMemo(() => {
    if (soundMeasure && values) {
      return {
        ...soundMeasure,
        values,
      };
    }
    return null;
  }, [soundMeasure, values]);

  return {
    workspace,
    soundSession,
    soundMeasure: resultSoundMeasure,
    timeSeries,
    loading,
    error,
    decoding,
  };
};

export const useSoundSampleMeasureDetail = (workspaceId, soundSessionId, soundMeasureId) => {
  const [previewValues, setPreviewValues] = React.useState(null);
  const [previewDecoding, setPreviewDecoding] = React.useState(false);

  const {
    error: detailError,
    loading: detailLoading,
    data: detailData,
    refetch,
  } = useQuery(SOUND_SESSION_SOUND_SAMPLE_MEASURES_DETAILS_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      from: null,
    },
  });

  const {
    error: previewError,
    loading: previewLoading,
    data: previewData,
  } = useQuery(SOUND_SESSION_SOUND_SAMPLE_MEASURES_PREVIEW_QUERY, {
    variables: {
      workspaceId,
      id: soundSessionId,
    },
  });

  const workspace = React.useMemo(() => {
    if (!detailLoading && !detailError) {
      return detailData.viewer.workspace;
    }
    return null;
  }, [detailError, detailLoading, detailData]);

  const soundSession = React.useMemo(() => {
    if (workspace) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  const soundMeasure = React.useMemo(() => {
    if (soundSession) {
      return soundSession.measure;
    }
    return null;
  }, [soundSession]);

  const previewQueryData = React.useMemo(() => {
    if (!previewLoading && !previewError) {
      return previewData.viewer.workspace.soundSession.sampleMeasurePreview;
    }
    return null;
  }, [previewLoading, previewError, previewData]);

  React.useEffect(() => {
    if (previewQueryData) {
      const decode = async () => {
        setPreviewDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${previewQueryData.values}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setPreviewValues(decoded);
        setPreviewDecoding(false);
      };
      decode();
    }
  }, [previewQueryData, previewError, previewData, setPreviewDecoding, setPreviewValues]);

  const preview = React.useMemo(() => {
    if (previewQueryData && previewValues) {
      return {
        ...previewQueryData,
        values: previewValues,
      };
    }
    return null;
  }, [previewQueryData, previewValues]);

  const setFrom = React.useCallback((from) => {
    refetch({
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      from,
    });
  }, [refetch]);

  return {
    workspace,
    soundSession,
    soundMeasure,
    detailLoading,
    previewLoading,
    previewDecoding,
    error: detailError || previewError,
    preview,
    setFrom,
  };
};

export const useSoundSampleMeasureValues = (
  workspaceId,
  soundSessionId,
  soundMeasureId,
  from,
  to,
) => {
  const [values, setValues] = React.useState(null);
  const [decoding, setDecoding] = React.useState(true);

  const {
    error,
    loading,
    data,
    refetch,
  } = useQuery(SOUND_SESSION_SOUND_SAMPLE_MEASURES_VALUES_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      filter: {
        from,
        to,
      },
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [loading, error, data]);

  const soundSession = React.useMemo(() => {
    if (workspace) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  const soundMeasure = React.useMemo(() => {
    if (soundSession) {
      return soundSession.measure;
    }
    return null;
  }, [soundSession]);

  const valuesPacked = React.useMemo(() => {
    if (soundMeasure) {
      const result = soundMeasure.values;
      return result;
    }
    return null;
  }, [soundMeasure]);

  React.useEffect(() => {
    if (valuesPacked) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${valuesPacked}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      decode();
    }
  }, [valuesPacked, setValues, setDecoding]);

  const resultSoundMeasure = React.useMemo(() => {
    if (soundMeasure && values) {
      return {
        ...soundMeasure,
        values,
      };
    }
    return null;
  }, [soundMeasure, values]);

  const changeSamples = React.useCallback((newFrom, newTo) => {
    refetch({
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      filter: {
        from: newFrom,
        to: newTo,
      },
    });
  }, [refetch]);

  return {
    workspace,
    soundSession,
    soundMeasure: resultSoundMeasure,
    loading,
    error,
    decoding,
    changeSamples,
  };
};

export const useSoundEventMeasureDetail = (workspaceId, soundSessionId, soundMeasureId) => {
  const {
    error,
    loading,
    data,
    refetch,
  } = useQuery(SOUND_SESSION_SOUND_EVENT_MEASURES_DETAILS_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      from: null,
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [error, loading, data]);

  const soundSession = React.useMemo(() => {
    if (workspace) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  const soundMeasure = React.useMemo(() => {
    if (soundSession) {
      return soundSession.measure;
    }
    return null;
  }, [soundSession]);

  const setFrom = React.useCallback((from) => {
    refetch({
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      from,
    });
  }, [refetch]);

  return {
    loading,
    error,
    workspace,
    soundSession,
    soundMeasure,
    setFrom,
  };
};

export const useSoundEventMeasureValues = (
  workspaceId,
  soundSessionId,
  soundMeasureId,
  from,
  to,
) => {
  const [values, setValues] = React.useState(null);
  const [decoding, setDecoding] = React.useState(true);

  const {
    error,
    loading,
    data,
    refetch,
  } = useQuery(SOUND_SESSION_SOUND_EVENT_MEASURES_VALUES_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      filter: {
        from,
        to,
      },
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [error, loading, data]);

  const soundSession = React.useMemo(() => {
    if (workspace) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  const soundMeasure = React.useMemo(() => {
    if (soundSession) {
      return soundSession.measure;
    }
    return null;
  }, [soundSession]);

  const valuesPacked = React.useMemo(() => {
    if (soundMeasure) {
      const result = soundMeasure.values;
      return result;
    }
    return null;
  }, [soundMeasure]);

  React.useEffect(() => {
    if (valuesPacked) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${valuesPacked}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      decode();
    }
  }, [valuesPacked, setValues, setDecoding]);

  const resultSoundMeasure = React.useMemo(() => {
    if (soundMeasure && values) {
      return {
        ...soundMeasure,
        values,
      };
    }
    return null;
  }, [soundMeasure, values]);

  const changeTimestamps = React.useCallback((newFrom, newTo) => {
    refetch({
      workspaceId,
      soundSessionId,
      id: soundMeasureId,
      filter: {
        from: newFrom,
        to: newTo,
      },
    });
  }, [refetch]);

  return {
    loading,
    error,
    decoding,
    workspace,
    soundSession,
    soundMeasure: resultSoundMeasure,
    changeTimestamps,
  };
};
