import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
} from 'antd';

import { DatePicker } from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';

const SoundSessionFilter = (props) => {
  const { searchObject, onSearch } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={searchObject}
          onFinish={onSearch}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t('soundSessions.components.filter.search')}
                name="search"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={t('soundSessions.components.filter.from')}
                name="from"
                getValueFromEvent={(event) => {
                  if (event != null) {
                    return event.valueOf();
                  }
                  return null;
                }}
                getValueProps={(value) => {
                  if (value) {
                    return {
                      value: new Date(value),
                    };
                  }
                  return { value: null };
                }}
              >
                <DatePicker allowClear style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('soundSessions.components.filter.to')}
                name="to"
                getValueFromEvent={(event) => {
                  if (event != null) {
                    return event.valueOf();
                  }
                  return null;
                }}
                getValueProps={(value) => {
                  if (value) {
                    return {
                      value: new Date(value),
                    };
                  }
                  return { value: null };
                }}
              >
                <DatePicker allowClear style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {t('common.filter')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  searchObject: PropTypes.shape({
    search: PropTypes.string,
    range: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
  }),
  onSearch: PropTypes.func.isRequired,
};

const defaultProps = {
  searchObject: {
    search: '',
    range: {},
  },
};

SoundSessionFilter.propTypes = propTypes;
SoundSessionFilter.defaultProps = defaultProps;

export default SoundSessionFilter;
