import React from 'react';
import PropTypes from 'prop-types';

import { List, Typography } from 'antd';

const ErrorList = (props) => {
  const {
    errors,
  } = props;

  return (
    <List
      dataSource={errors}
      renderItem={(item) => (
        <List.Item>
          <Typography.Text>{item}</Typography.Text>
        </List.Item>
      )}
    />
  );
};

const propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ErrorList.propTypes = propTypes;

export default ErrorList;
