export default {
  it: {
    translations: {
      common: {
        noData: 'Nessun dato disponibile',
        loading: 'Loading',
        decoding: 'Decoding',
        retry: 'Riprova',
        add: 'Aggiungi',
        filter: 'Filtra',
        actions: 'Azioni',
        create: 'Aggiungi',
        detail: 'Dettagli',
        upload: 'Upload',
        reset: 'Reset',
        restart: 'Inizio',
        samples: 'campioni',
        next: 'Successivo',
        selectFile: 'Seleziona un file',
        selectDirectory: 'Seleziona una directory',
        save: 'Salva',
        delete: 'Elimina',
        deleteInView: 'Elimina visualizzati',
        sureToDelete: 'Sei sicuro di eliminare l\'oggetto?',
        sureToDeleteInView: 'Sei sicuro di eliminare gli oggetti visibili',
        yes: 'Si',
        no: 'No',
        loadMore: 'Carica ancora',
        successMessage: 'Operazione eseguita con successo',
        info: 'Info',
        period: 'Periodo',
        pa: 'Pa',
        db: 'dB',
        hz: 'Hz',
        percent: '%',
        cumulativePercent: '% cumulativa',
        ms2: 'm/s²',
        cms2: 'cm/s²',
        mms2: 'mm/s²',
        ins2: 'in/s²',
        fts2: 'ft/s²',
        g: 'g',
        dbStep: 'dB step',
        preview: 'Preview',
        value: 'Valore',
        channel: 'Canale',
        time: 'Tempo',
        sample: 'Campione',
        parameter: 'Parametro',
        apply: 'Applica',
        unitOfMeasure: 'Unità di misura',
        parameters: {
          label: 'Parametro',
          parameterClass: 'Classe',
          pond: 'Ponderazione',
          measureId: 'Id',
          type: 'Tipo',
          integration: 'Integrazione',
          unitOfMeasure: 'Unità di misura',
          measureType: 'Tipo misura',
          measureStep: 'Step misura',
          preview: 'Anteprima',
          period: 'Periodo',
          bands: 'Bande',
        },
      },
      validations: {
        required: 'Richiesto',
      },
      headerMenu: {
        workspaces: 'Workspaces',
        quotas: 'Quote',
      },
      rightMenu: {
        quota: 'Quota utilizzata {{ quota }}%',
        editProfile: 'Modifica profilo',
        logout: 'Esci',
      },
      extractGraphQLErrors: {
        networkError: 'Server non raggiungibile, controllare connessione',
        unexpectedError: 'Si è verificato un errore imprevisto',
      },
      enums: {
        workspaceRoles: {
          WORKSPACE_MANAGER: 'Manager',
          WORKSPACE_VIEWER: 'Visualizzatore',
        },
      },
      quotas: {
        list: {
          title: 'Quote',
          subtitle: 'Lista delle quote',
          table: {
            user: 'Utente',
            quota: 'Quota',
            unlimited: 'Illimitato',
          },
        },
        components: {
          filter: {
            search: 'Ricerca',
          },
          form: {
            quota: 'Quota',
            quotaInfo: 'Inserire il numero di mesi di terzi d\'ottava a 1/8 di secondo',
            unlimited: 'Illimitato',
          },
          updateModal: {
            title: 'Aggiornamento quota',
          },
        },
      },
      workspaces: {
        list: {
          title: 'Workspaces',
          subtitle: 'Lista dei workspaces',
          table: {
            name: 'Nome',
            owner: 'Proprietario',
          },
        },
        detail: {
          tabs: {
            soundSessions: 'Sessioni di misura (suono)',
            vibrationSessions: 'Sessioni di misura (vibrazioni)',
            file: 'File',
            users: 'Utenti',
            properties: 'Proprietà',
          },
        },
        soundSessionsTab: {
          title: 'Sessioni di misura',
          subtitle: 'Lista di sessioni disponibili',
          table: {
            name: 'Nome',
            createdAt: 'Data creazione',
            recordDate: 'Data registrazione',
            source: 'Provenienza',
            reference: 'Riferimento',
            duration: 'Durata',
            weight: 'Dimensione',
            timePreview: 'Time preview',
            samplePreview: 'Sample preview',
          },
        },
        vibrationSessionsTab: {
          title: 'Sessioni di misura',
          subtitle: 'Lista di sessioni disponibili',
          table: {
            name: 'Nome',
            createdAt: 'Data creazione',
            recordDate: 'Data registrazione',
            source: 'Provenienza',
            reference: 'Riferimento',
            duration: 'Durata',
            weight: 'Dimensione',
            timePreview: 'Time preview',
          },
        },
        fileTab: {
          formTitle: 'Form',
          uploadSuccess: 'File caricato correttamente',
          jobTitle: 'Jobs',
          jobSubtitle: 'Lista dei file caricati in questo workspace e relativo stato di decodifica',
          table: {
            date: 'Data',
            filename: 'Nome file',
            jobStatus: 'Status',
          },
        },
        userTab: {
          title: 'Utenti',
          userPermissionsTitle: 'Permessi',
          ownerTitle: 'Proprietario',
          ownerDisclaimer: 'Cambiando il proprietario, non avrai più accesso a questo workspace.',
        },
        propertyTab: {
          title: 'Modifica workspace',
        },
        components: {
          filter: {
            search: 'Ricerca',
          },
          form: {
            name: 'Nome',
          },
          createModal: {
            title: 'Nuovo workspace',
          },
          fileForm: {
            namePrefix: 'Prefisso del nome sessione',
            timezone: 'Fuso orario',
            file: 'DL5/VIB/DODL/WAV (100 max)',
          },
        },
      },
      soundSessions: {
        setup: {
          preview: 'Anteprima',
          inputChannel: 'Input Channel',
          inputAmplification: 'Input Amplification',
          samplingPeriod: 'Sampling Period',
          profilePeriod: 'Profile Period',
          spectrePeriod: 'Spectre Period',
          integrationTime: 'Integration Time',
          backErase: 'Back Erase',
          integrationMode: 'Integration Mode',
          exchangeRate: 'Exchange Rate',
          threshold: 'Threshold',
          criterion: 'Criterion',
          overload: 'Overload',
          L1: 'L1',
          L2: 'L2',
          L3: 'L3',
          L4: 'L4',
          acousticFieldCorrection: 'Acoustic Field Correction',
          windscreenCorrection: 'Windscreen Correction',
          environmentCorrection: 'Environment Correction',
          reportPeriod: 'Report Period',
          triggerEvent: 'Trigger Event',
          statisticParameter: 'Statistic Parameter',
          statisticClass: 'Statistic Class',
          fastLeqPond: 'Fast Leq Pond',
          fftBand: 'FFT Band',
          classIEC61672: 'Class IEC61672',
          parameterArrayIndex: 'Parameter Array Index',
          preamplifierModel: 'Modello preamplificatore',
          preamplifierSerial: 'Seriale preamplificatore',
          microphoneModel: 'Modello microfono',
          microphoneSerial: 'Seriale microfono',
        },
        detail: {
          tabs: {
            setup: 'Setup misura',
            measures: 'Misure',
            properties: 'Proprietà',
          },
        },
        setupTab: {
          title: 'Setup sessione',
        },
        measuresTab: {
          title: 'Misure',
          table: {
            name: 'Nome',
            kind: 'Tipo',
            period: 'Step',
            parameters: 'Parametri',
            weight: 'Dimensione',
          },
        },
        propertyTab: {
          title: 'Modifica sessione',
        },
        components: {
          filter: {
            search: 'Ricerca',
            from: 'Inizio registrazione',
            to: 'Fine registrazione',
          },
          form: {
            name: 'Nome',
            reference: 'Riferimento',
            note: 'Note',
            source: 'Provenienza',
          },
          infoModal: {
            timeSingleMeasureTitle: 'Time history',
            timeOctaveMeasureTitle: 'Time history ottave',
            timeThirdOctaveMeasureTitle: 'Time history terzi d\'ottava',
            timeStatisticsMeasureTitle: 'Time history statistiche',
            timeFFTMeasureTitle: 'Time history FFT',

            sampleSingleMeasureTitle: 'Sample history',
            sampleOctaveMeasureTitle: 'Sample history ottave',
            sampleThirdOctaveMeasureTitle: 'Sample history terzi d\'ottava',

            eventSingleMeasureTitle: 'Time history {{eventName}}',
            eventThirdOctaveMeasureTitle: 'Time history terzi d\'ottava {{eventName}}',
            eventStatisticsMeasureTitle: 'Time history statistiche {{eventName}}',
          },
        },
      },
      soundMeasures: {
        kinds: {
          soundTimeSingleMeasure: 'Time History',
          soundTimeOctaveMeasure: 'Octave Time History',
          soundTimeThirdOctaveMeasure: 'Third-Octave Time History',
          soundTimeFFTMeasure: 'FFT Time History',
          soundTimeStatisticsMeasure: 'Statistics Time History',
          soundSampleSingleMeasure: 'Sample Time History',
          soundSampleOctaveMeasure: 'Sample Octave Time History',
          soundSampleThirdOctaveMeasure: 'Sample Third-Octave Time History',
          soundEventSingleMeasure: 'Event Table',
          soundEventThirdOctaveMeasure: 'Event Third-Octave Table',
          soundEventStatisticsMeasure: 'Event Statistics Table',
        },
        config: {
          type: 'Type',
          integration: 'Integration',
          parameterClass: 'Parameter class',
          label: 'Label',
          id: 'Id',
          unitOfMeasure: 'Unit Of Measure',
          pond: 'Ponderation',
          measureId: 'Measure Id',
          period: 'Period',

          bands: 'Bands',

          classes: 'Classes',
          class: 'Class',

          preview: 'Anteprima',

          measureType: 'Tipo misura',
          measureStep: 'Step misura',
        },
        components: {
          filterModal: {
            title: 'Filtro',
            availableTimes: 'Hai selezionato il periodo {{- from}}-{{- to}}',
            range: 'Range',
          },
          tableModal: {
            title: 'Tabella dati',
          },
          charts: {
            table: 'Tabella',
            excel: 'Excel',
            settings: 'Impostazioni',
            showPa: 'Converti in Pa',
            showDB: 'Converti in dB',
            saveImage: 'Salva immagine',
            showGrid: 'Visualizza griglia',
            hideGrid: 'Nascondi griglia',
            showPoints: 'Visualizza points',
            hidePoints: 'Nascondi points',
            configModal: {
              title: 'Impostazioni',
              minColorRange: 'Minimo',
              maxColorRange: 'Massimo',
              colorSteps: 'Step colori',
            },
          },
          serieAttachmentList: {
            title: 'Attachments',
          },
        },
      },
      soundTimeMeasures: {
        chart: {
          title: '',
          subtitle: 'Dati disponibili da {{- from}} a {{- to}}. Sono presenti {{- totalValues}} misurazioni totali.',
        },
      },
      soundSampleMeasures: {
        chart: {
          title: '',
          subtitle: 'Dati disponibili da {{- from}} a {{- to}}. Sono presenti {{- totalValues}} misurazioni totali.',
          subsubtitle: 'Primo campione il {{- date}}',
        },
      },
      vibrationSessions: {
        setup: {
          instrument: {
            model: 'Modello',
            serialNumber: 'Numero seriale',
            version: 'Versione',
            option: 'Opzione',
          },
          sensor: {
            channelSensitibities: 'Sensibilità canali',
            polarizationVoltages: 'Polarizzazione',
            configuration: '# configurazione',
            type: 'Tipo',
            sensibility: 'Sensibilità',
            manufacturer: 'Producttore',
            model: 'Modello',
            serialNumber: 'Numero seriale',
            fullScale: 'Fondo scala',
          },
          measure: {
            rightPonds: 'Ponderazioni sensore destro',
            leftPonds: 'Ponderazioni sensore sinistro',
            rightRatios: 'Coefficienti sensore destro',
            leftRatios: 'Coefficienti sensore sinistro',
            mode: 'Modalità',
            gain: 'Guadagno',
            integrationDelay: 'Ritardo integrazione',
            integrationMode: 'Modalità integrazione',
          },
        },
        components: {
          form: {
            name: 'Nome',
            reference: 'Riferimento',
            note: 'Note',
            source: 'Provenienza',
          },
          infoModal: {
            timeSingleMeasureTitle: 'Time history',
            timeOctaveMeasureTitle: 'Time history ottave',
            timeThirdOctaveMeasureTitle: 'Time history terzi d\'ottava',
            timeStatisticsMeasureTitle: 'Time history statistiche',
          },
        },
        detail: {
          tabs: {
            setup: 'Setup misura',
            measures: 'Misure',
            properties: 'Proprietà',
          },
        },
        setupTab: {
          title: 'Setup sessione',
          instrument: 'Strumento',
          rightSensor: 'Sensore destro',
          leftSensor: 'Sensore sinistro',
          measure: 'Misura',
        },
        measuresTab: {
          title: 'Misure',
          table: {
            name: 'Nome',
            kind: 'Tipo',
            weight: 'Dimensione',
          },
        },
        propertyTab: {
          title: 'Modifica sessione',
        },
      },
      vibrationMeasures: {
        kinds: {
          vibrationTimeSingleMeasure: 'Time History',
          vibrationOctaveMeasure: 'Octave Time History',
          vibrationTimeThirdOctaveMeasure: 'Third-Octave Time History',
          vibrationTimeFFTMeasure: 'FFT Time History',
          vibrationTimeStatisticsMeasure: 'Statistics Time History',
        },
        config: {
          id: 'Id',
          label: 'Label',
          parameter: 'Parameter',
          unitOfMeasure: 'Unit Of Measure',
          pond: 'Ponderation',
          measureId: 'Measure Id',
          period: 'Period',
          channel: 'Channel',

          bands: 'Bands',

          classes: 'Classes',

          preview: 'Anteprima',
        },
        components: {
          filterModal: {
            title: 'Filtro',
            availableTimes: 'Hai selezionato il periodo {{- from}}-{{- to}}',
            range: 'Range',
          },
          tableModal: {
            title: 'Tabella dati',
          },
          charts: {
            table: 'Tabella',
            excel: 'Excel',
            settings: 'Impostazioni',
            showPa: 'Converti in Pa',
            showDB: 'Converti in dB',
            saveImage: 'Salva immagine',
            showGrid: 'Visualizza griglia',
            hideGrid: 'Nascondi griglia',
            showPoints: 'Visualizza points',
            hidePoints: 'Nascondi points',
            configModal: {
              title: 'Impostazioni',
              minColorRange: 'Minimo',
              maxColorRange: 'Massimo',
              colorSteps: 'Step colori',
            },
          },
        },
      },
      vibrationTimeMeasures: {
        chart: {
          title: '',
          subtitle: 'Dati disponibili da {{- from}} a {{- to}}. Sono presenti {{- totalValues}} misurazioni totali.',
        },
      },
    },
  },
};
