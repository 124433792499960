import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SoundSessionForm = (props) => {
  const {
    soundSession,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = React.useCallback((data) => {
    const formData = {
      ...data,
    };
    delete formData.source;
    onFormSubmit(formData);
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={soundSession}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item
                label={t('soundSessions.components.form.name')}
                name="name"
                rules={[{ required: true, message: t('validations.required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                label={t('soundSessions.components.form.reference')}
                name="reference"
                rules={[{ required: true, message: t('validations.required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t('soundSessions.components.form.source')}
                name="source"
                rules={[{ required: true, message: t('validations.required') }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={t('soundSessions.components.form.note')}
                name="note"
                rules={[{ required: true, message: t('validations.required') }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.save')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  soundSession: PropTypes.shape({
    name: PropTypes.string,
    reference: PropTypes.string,
    note: PropTypes.string,
    source: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  soundSession: null,
};

SoundSessionForm.propTypes = propTypes;
SoundSessionForm.defaultProps = defaultProps;

export default SoundSessionForm;
