import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import QuotaForm from './QuotaForm';
import { QUOTA_UPDATE_MUTATION, QUOTA_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const QuotaUpdateModal = (props) => {
  const {
    id,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    error: quotaError,
    loading: quotaLoading,
    data: quotaData,
  } = useQuery(QUOTA_QUERY, {
    variables: {
      id,
    },
    skip: id == null,
  });

  const quota = React.useMemo(() => {
    if (!quotaLoading && !quotaError && quotaData) {
      return quotaData.viewer.quota;
    }
    return null;
  }, [quotaError, quotaLoading, quotaData]);

  const [
    quotaUpdateMutationAction,
    {
      loading: mutationLoading,
      error: mutationError,
    },
  ] = useMutation(QUOTA_UPDATE_MUTATION);

  const error = React.useMemo(() => {
    const result = quotaError || mutationError;
    return result;
  }, [quotaError, mutationError]);

  const loading = React.useMemo(() => {
    const result = quotaLoading || mutationLoading;
    return result;
  }, [quotaLoading, mutationLoading]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const title = React.useMemo(() => {
    if (quota != null) {
      return quota.user;
    }
    return t('quotas.components.updateModal.title');
  }, [quota, t]);

  const handleFormSubmit = async (data) => {
    try {
      await quotaUpdateMutationAction({
        variables: {
          input: {
            ...data,
          },
        },
      });
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <Modal
      title={title}
      visible={id != null}
      footer={null}
      onCancel={onClose}
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        {quota && (
          <QuotaForm
            quota={quota}
            onFormSubmit={handleFormSubmit}
          />
        )}
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  id: null,
};

QuotaUpdateModal.propTypes = propTypes;
QuotaUpdateModal.defaultProps = defaultProps;

export default QuotaUpdateModal;
