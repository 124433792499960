export const extractGraphQLErrors = (error, t) => {
  if (error.graphQLErrors && error.graphQLErrors.length) {
    // if (error.message && error.message.includes('E11000 duplicate key')) {
    //   return [t('extractGraphQLErrors.noDuplicate')];
    // }
    return error.graphQLErrors.map((e) => e.message);
  }
  if (error.message) {
    if (error.message.includes('Network error')) {
      return [t('extractGraphQLErrors.networkError')];
    }
  }
  return [t('extractGraphQLErrors.unexpectedError')];
};

export const clearTypenames = (data) => {
  const isObject = (a) => (!!a) && (a.constructor === Object);

  if (isObject(data)) {
    Object.keys(data).forEach((key) => {
      if (key === '__typename') {
        delete data[key]; // eslint-disable-line
      }
      else if (isObject(data[key])) {
        clearTypenames(data[key]);
      }
    });
  }

  return data;
};
