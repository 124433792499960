/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import {
  Row,
  Col,
  Modal,
  Typography,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { formatLocaleWithTimezone, getLocale } from '../../../utils/date';
import { DECIMALS, UNIT_OF_MEASURES } from '../../charts/utils';

const VibrationValueFilterModal = (props) => {
  const {
    timezone,
    availableFrom,
    availableTo,
    preview,
    onFromChange,
    onFormSubmit,
    isModalOpen,
    onClose,
  } = props;

  const { t, i18n } = useTranslation();

  const locale = getLocale(i18n.language);

  const chartRef = React.useRef(null);
  const [chartInstance, setChartInstance] = React.useState(null);

  const handleSubmit = () => {
    onFormSubmit({
      from: availableFrom,
      to: availableTo,
    });
  };

  const currentTimestampsText = React.useMemo(() => {
    if (availableFrom && availableTo) {
      const from = formatLocaleWithTimezone(availableFrom, 'yyyy/MM/dd HH:mm:ss:SSS', locale, timezone, false);
      const to = formatLocaleWithTimezone(availableTo, 'yyyy/MM/dd HH:mm:ss:SSS', locale, timezone, false);
      return t('vibrationMeasures.components.filterModal.availableTimes', { from, to });
    }
    return null;
  }, [t, availableFrom, availableTo]);

  const dataset = React.useMemo(() => {
    const { values } = preview;
    const dataSource = values.map((v) => ({
      timestamp: utcToZonedTime(v.timestamp, timezone).getTime(),
      value: v.value,
    }));

    const result = [
      {
        dimensions: ['timestamp', 'value'],
        source: dataSource,
      },
    ];
    return result;
  }, [preview]);

  const chartTitle = React.useMemo(() => {
    if (preview.label) {
      return preview.label;
    }
    return t('common.preview');
  }, [preview]);

  const getSeriesOption = () => {
    const serie = {
      id: 'preview',
      name: chartTitle,
      type: 'line',
      sampling: 'lttb',
      showSymbol: false,
      datasetIndex: 0,
      yAxisIndex: 0,
      xAxisIndex: 0,
      encode: {
        x: 'timestamp',
        y: 'value',
      },
    };

    if (availableFrom != null && availableTo != null) {
      serie.markArea = {
        silent: true,
        itemStyle: {
          color: 'green',
          opacity: 0.3,
        },
        data: [
          [{
            xAxis: utcToZonedTime(availableFrom, timezone),
          },
          {
            xAxis: utcToZonedTime(availableTo, timezone),
          }],
        ],
      };
      serie.markLine = {
        silent: true,
        symbol: ['none', 'none'],
        label: { show: false },
        lineStyle: {
          color: 'green',
        },
        data: [
          {
            xAxis: utcToZonedTime(availableFrom, timezone),
          },
          {
            xAxis: utcToZonedTime(availableTo, timezone),
          },
        ],
      };
    }

    return [serie];
  };

  const getInitialChartOption = () => {
    const result = {
      animation: false,
      title: {
        id: 'title',
        text: chartTitle,
      },
      grid: {
        id: 'graph_grid',
        show: true,
        top: '17%',
        bottom: '25%',
      },
      tooltip: {
        id: 'tooltip',
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          snap: true,
        },
        formatter: (params) => {
          let timestamp;
          const series = [];
          for (let i = 0; i < params.length; i += 1) {
            const current = params[i];
            timestamp = current.value.timestamp;
            const { value } = current.value;
            if (value != null) {
              const realValue = value.toFixed(DECIMALS[UNIT_OF_MEASURES.MS2]);
              const unitOfMeasure = t('common.ms2');
              series.push(`${current.marker} ${current.seriesName}: ${realValue} ${unitOfMeasure}`);
            }
          }
          const tooltip = `${format(timestamp, 'yyyy-MM-dd HH:mm:ss.SSS')}\n${series.join('\n')}`;
          return tooltip;
        },
        showContent: true,
        renderMode: 'richText',
      },
      dataZoom: [
        {
          id: 'x_zoom_slider',
          type: 'slider',
          show: true,
          xAxisIndex: [0],
          start: 0,
          end: 100,
          height: '3%',
          bottom: '3%',
          filterMode: 'none',
        },
        {
          id: 'y_zoom_slider',
          type: 'slider',
          show: true,
          yAxisIndex: [0],
          left: '1%',
          start: 0,
          end: 100,
          width: '3%',
          filterMode: 'none',
        },
        {
          id: 'x_zoom_inside',
          type: 'inside',
          xAxisIndex: [0],
          start: 0,
          end: 100,
          filterMode: 'none',
        },
      ],
      dataset,
      xAxis: {
        id: 'x_data',
        type: 'time',
        name: t('common.time'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        gridIndex: 0,
      },
      yAxis: {
        id: 'y_data',
        type: 'value',
        name: t('common.value'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        gridIndex: 0,
      },
      series: getSeriesOption(),
    };

    return result;
  };

  React.useEffect(() => {
    if (chartInstance) {
      return () => {
        chartInstance.dispose();
      };
    }
    return () => { };
  }, [chartInstance]);

  React.useEffect(() => {
    if (isModalOpen && chartRef && chartRef.current) {
      const instance = echarts.init(chartRef.current, null);
      instance.setOption(getInitialChartOption());
      instance.on('click', (event) => {
        if (event.seriesId === 'preview') {
          onFromChange(zonedTimeToUtc(event.data.timestamp, timezone).getTime());
        }
      });
      instance.getZr().on('click', (params) => {
        const pointInPixel = [params.offsetX, params.offsetY];
        const pointInGrid = instance.convertFromPixel('grid', pointInPixel);
        onFromChange(zonedTimeToUtc(parseInt(pointInGrid[0], 10), timezone).getTime());
      });
      setChartInstance(instance);
    }
  }, [isModalOpen, chartRef]);

  React.useEffect(() => {
    if (chartInstance && availableFrom != null && availableTo != null) {
      chartInstance.setOption({
        series: getSeriesOption(),
      });
    }
  }, [chartInstance, availableFrom, availableTo]);

  return (
    <Modal
      title={t('vibrationMeasures.components.filterModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Typography.Title level={3}>{currentTimestampsText}</Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ minHeight: 400 }} ref={chartRef} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" onClick={handleSubmit} block>
            {t('common.apply')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const propTypes = {
  timezone: PropTypes.string.isRequired,
  availableFrom: PropTypes.number,
  availableTo: PropTypes.number,
  preview: PropTypes.shape({
    label: PropTypes.string,
    values: PropTypes.any.isRequired,
  }).isRequired,
  onFromChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  availableFrom: null,
  availableTo: null,
};

VibrationValueFilterModal.propTypes = propTypes;
VibrationValueFilterModal.defaultProps = defaultProps;

export default VibrationValueFilterModal;
