/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const VIBRATION_MEASURE_CONFIG_QUERY = gql`
  query VibrationMeasureConfigQuery($workspaceId: ID!, $vibrationSessionId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        vibrationSession(id: $vibrationSessionId) {
          id
          measure(id: $id) {
            id
            config
          }
        }
      }
    }
  }
`;

export const VIBRATION_SESSION_VIBRATION_TIME_MEASURES_DETAILS_QUERY = gql`
query VibrationSessionVibrationTimeMeasuresDetailQuery($workspaceId: ID!, $vibrationSessionId: ID!, $id: ID!, $from: Float) {
  viewer {
    workspace(id: $workspaceId) {
      id
      name
      vibrationSession(id: $vibrationSessionId) {
        id
        name
        timezone
        measure(id: $id) {
          id
          name
          kind
          name
          config
          totalValues
          ...on VibrationTimeMeasure {
            minTimestamp
            maxTimestamp
            availableWindow(from: $from)
          }
        }
      }
    }
  }
}
`;

export const VIBRATION_SESSION_VIBRATION_TIME_MEASURES_VALUES_QUERY = gql`
  query VibrationSessionVibrationTimeMeasuresValuesQuery($workspaceId: ID!, $vibrationSessionId: ID!, $id: ID!, $filter: VibrationTimeValueFilter!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        vibrationSession(id: $vibrationSessionId) {
          id
          name
          timezone
          config
          vibrationTimeSeries {
            id
            name
            values {
              from
              to
            }
          }
          measure(id: $id) {
            id
            kind
            name
            config
            ...on VibrationTimeMeasure {
              values(filter: $filter)
            }
          }
        }
      }
    }
  }
`;
