import React from 'react';
import PropTypes from 'prop-types';
import { ComponentLoader, messageError } from '@deltaohm/ant-components';
import { t } from 'i18next';

import {
  SingleMeasureTable,
  OctaveMeasureTable,
  StatisticsMeasureTable,
} from '../../tables/events';

import { useSoundEventMeasureValues } from '../../hooks';
import { extractGraphQLErrors } from '../../../utils/graphql';
import { ErrorList } from '../../../generics';

const SoundMeasureChartContainer = (props) => {
  const {
    from,
    to,
    workspaceId,
    soundSessionId,
    soundMeasureId,
    type,
  } = props;

  const {
    loading,
    error,
    decoding,
    soundSession,
    soundMeasure,
    changeTimestamps,
  } = useSoundEventMeasureValues(workspaceId, soundSessionId, soundMeasureId, from, to);

  React.useEffect(() => {
    changeTimestamps(from, to);
  }, [from, to, changeTimestamps]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  return (
    <ComponentLoader
      loading={loading || decoding}
      tip={loading ? t('common.loading') : t('common.decoding')}
    >
      {soundMeasure && type === 'single' && (
        <SingleMeasureTable
          workspaceId={workspaceId}
          soundSession={soundSession}
          measure={soundMeasure}
        />
      )}
      {soundMeasure && type === 'thirdOctave' && (
        <OctaveMeasureTable
          workspaceId={workspaceId}
          soundSession={soundSession}
          measure={soundMeasure}
        />
      )}
      {soundMeasure && type === 'statistics' && (
        <StatisticsMeasureTable
          workspaceId={workspaceId}
          soundSession={soundSession}
          measure={soundMeasure}
        />
      )}
    </ComponentLoader>
  );
};

const propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  workspaceId: PropTypes.string.isRequired,
  soundSessionId: PropTypes.string.isRequired,
  soundMeasureId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['single', 'thirdOctave', 'statistics']).isRequired,
};

SoundMeasureChartContainer.propTypes = propTypes;

export default SoundMeasureChartContainer;
