import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useSoundEventMeasureDetail } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';
import { formatLocaleWithTimezone, getLocale } from '../utils/date';
import { SoundMeasureTableContainer, SoundValueFilter } from './components/events';

const SoundEventMeasureTablePage = (props) => {
  const {
    type,
  } = props;
  const { workspaceId, soundSessionId, soundMeasureId } = useParams();

  const { t, i18n } = useTranslation();

  const locale = getLocale(i18n.language);

  const {
    workspace,
    soundSession,
    soundMeasure,
    loading,
    error,
    decoding,
  } = useSoundEventMeasureDetail(workspaceId, soundSessionId, soundMeasureId);

  const [requestTimestamp, setRequestTimestamp] = React.useState(null);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/workspaces',
        breadcrumbName: 'Workspaces',
      },
    ];
    if (workspace) {
      result.push({
        path: `/workspaces/${workspaceId}`,
        breadcrumbName: workspace.name,
      });
      if (soundSession) {
        result.push({
          path: `/workspaces/${workspaceId}/soundSessions/${soundSessionId}`,
          breadcrumbName: soundSession.name,
        });
        if (soundMeasure) {
          result.push({
            path: '',
            breadcrumbName: soundMeasure.name,
          });
        }
      }
    }
    return result;
  }, [workspace, soundSession, soundMeasure]);

  React.useEffect(() => {
    if (soundMeasure && !requestTimestamp) {
      setRequestTimestamp({
        from: soundMeasure.firstEventTimestamp,
        to: soundMeasure.lastEventTimestamp,
      });
    }
  }, [soundMeasure, requestTimestamp]);

  const handleFilter = (data) => {
    setRequestTimestamp(data);
  };

  const subtitle = React.useMemo(() => {
    if (soundMeasure && soundSession) {
      const from = formatLocaleWithTimezone(soundMeasure.firstEventTimestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, soundSession.timezone, false);
      const to = formatLocaleWithTimezone(soundMeasure.lastEventTimestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, soundSession.timezone, false);
      return t('soundTimeMeasures.chart.subtitle', { from, to, totalValues: soundMeasure.totalValues });
    }
    return '';
  }, [t, soundSession, soundMeasure, locale]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={loading}
    >
      <PageSection
        title={soundMeasure ? soundMeasure.name : ''}
        breadcrumb={breadcrumb}
        subtitle={subtitle}
      >
        {!error && soundSession && !loading && !decoding && requestTimestamp && (
          <SoundValueFilter
            requestTimestamp={requestTimestamp}
            timezone={soundSession.timezone}
            onFormSubmit={handleFilter}
          />
        )}
        {soundMeasure && requestTimestamp && (
          <SoundMeasureTableContainer
            from={requestTimestamp.from}
            to={requestTimestamp.to}
            workspaceId={workspaceId}
            soundSessionId={soundSessionId}
            soundMeasureId={soundMeasureId}
            type={type}
          />
        )}
      </PageSection>
    </ComponentLoader>
  );
};

const propTypes = {
  type: PropTypes.oneOf(['single', 'thirdOctave', 'statistics']).isRequired,
};

SoundEventMeasureTablePage.propTypes = propTypes;

export default SoundEventMeasureTablePage;
