import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Button,
} from 'antd';

import {
  DatePicker,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const SoundValueFilter = (props) => {
  const {
    requestTimestamp,
    timezone,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = React.useCallback((data) => {
    if (data.range && data.range.length === 2) {
      onFormSubmit({ from: data.range[0], to: data.range[1] });
    }
    else {
      onFormSubmit({ from: null, to: null });
    }
  }, [onFormSubmit]);

  const initialValues = React.useMemo(() => {
    const { from, to } = requestTimestamp;
    if (from && to) {
      return { range: [from, to] };
    }
    return null;
  }, [requestTimestamp]);

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={initialValues}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={t('soundMeasures.components.filterModal.range')}
            name="range"
            getValueFromEvent={(event) => {
              const [from, to] = event;
              let realFrom = null;
              let realTo = null;
              if (from != null) {
                realFrom = zonedTimeToUtc(from, timezone).getTime();
              }
              if (from != null) {
                realTo = zonedTimeToUtc(to, timezone).getTime();
              }
              return [realFrom, realTo];
            }}
            getValueProps={(value) => {
              if (value) {
                return {
                  value: [utcToZonedTime(value[0], timezone), utcToZonedTime(value[1], timezone)],
                };
              }
              return { value: null };
            }}
          >
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              allowClear
              showTime={{ format: 'HH:mm:ss' }}
              format="YYYY-MM-DD HH:mm:ss"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.apply')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  requestTimestamp: PropTypes.shape(({
    from: PropTypes.number,
    to: PropTypes.number,
  })).isRequired,
  timezone: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

SoundValueFilter.propTypes = propTypes;

export default SoundValueFilter;
