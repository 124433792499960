import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  Row,
  Col,
} from 'antd';

import {
  PageSection,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';

const SoundSessionSetupTab = (props) => {
  const {
    soundSession,
  } = props;

  const { t } = useTranslation();

  const dataSource = React.useMemo(() => {
    const result = Object.keys(soundSession.config).map((key) => ({
      parameter: t(`soundSessions.setup.${key}`),
      value: soundSession.config[key],
    }));
    return result;
  }, [soundSession, t]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('common.parameter'),
        dataIndex: 'parameter',
      },
      {
        title: t('common.value'),
        dataIndex: 'value',
      },
    ];
    return result;
  }, [t]);

  return (
    <PageSection
      title={t('soundSessions.setupTab.title')}
    >
      <Row>
        <Col xs={24}>
          <Table
            bordered
            size="small"
            columns={columns}
            rowKey={(record) => record.parameter}
            dataSource={dataSource}
            pagination={false}
          />
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  soundSession: PropTypes.shape({
    id: PropTypes.string,
    config: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

SoundSessionSetupTab.propTypes = propTypes;

export default SoundSessionSetupTab;
