import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Tooltip,
  Button,
  Space,
  Table,
} from 'antd';
import {
  RightOutlined,
  SearchOutlined,
  InfoOutlined,
} from '@ant-design/icons';

import {
  PageSection,
} from '@deltaohm/ant-components';
import { useNavigate } from 'react-router-dom';
import {
  SoundSessionTimePreviewModal,
  SoundSessionSamplePreviewModal,
} from '../components';

import { SoundMeasureConfigModal } from '../../soundMeasures/components';
import { AuthenticationContext } from '../../authentications';
import { computeDuration } from '../../utils/date';

const SoundSessionMeasuresTab = (props) => {
  const {
    workspaceId,
    soundSession,
  } = props;

  const { quota } = React.useContext(AuthenticationContext);

  const availableQuota = React.useMemo(() => {
    const {
      workspaceAvailableQuota,
    } = quota;
    return workspaceAvailableQuota;
  }, [quota]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [soundMeasureConfig, setSoundMeasureConfig] = React.useState(null);
  const [showTimePreview, setShowTimePreview] = React.useState(false);
  const [showSamplePreview, setShowSamplePreview] = React.useState(false);

  const dataSource = React.useMemo(() => {
    const result = [];

    result.push(...soundSession.timeSingleMeasures);
    result.push(...soundSession.timeOctaveMeasures);
    result.push(...soundSession.timeThirdOctaveMeasures);
    result.push(...soundSession.timeStatisticsMeasures);
    result.push(...soundSession.timeFFTMeasures);

    result.push(...soundSession.sampleSingleMeasures);
    result.push(...soundSession.sampleOctaveMeasures);
    result.push(...soundSession.sampleThirdOctaveMeasures);

    result.push(...soundSession.eventSingleMeasures);
    result.push(...soundSession.eventThirdOctaveMeasures);
    result.push(...soundSession.eventStatisticsMeasures);

    return result;
  }, [soundSession]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('soundSessions.measuresTab.table.kind'),
        dataIndex: 'kind',
        fixed: 'left',
        render: (kind, record) => {
          const lower = kind.charAt(0).toLowerCase() + kind.slice(1);
          let str = t(`soundMeasures.kinds.${lower}`);
          if (record.config[0].measureType) {
            str += ` (${record.config[0].measureType}`;
            if (record.config[0].measureStep) {
              str += ` ${record.config[0].measureStep}`;
            }
            str += ')';
          }
          return str;
        },
      },
      {
        title: t('soundSessions.measuresTab.table.parameters'),
        dataIndex: 'config',
        render: (config) => {
          if (config.length) {
            const labels = [...new Set(config.map((c) => c.label))];
            if (labels.length > 3) {
              return `${labels.slice(0, 3).join(', ')}...`;
            }
            return labels.join(', ');
          }
          return '';
        },
      },
      {
        title: t('soundSessions.measuresTab.table.period'),
        dataIndex: 'period',
        render: (period) => {
          if (period) {
            return computeDuration(0, period);
          }
          return '';
        },
      },
      {
        title: t('soundSessions.measuresTab.table.weight'),
        dataIndex: 'weight',
        render: (weight) => {
          if (availableQuota === null) {
            return weight;
          }
          return `${(weight / availableQuota).toFixed(2)}%`;
        },
      },
      {
        title: t('common.actions'),
        dataIndex: 'kind',
        render: (kind, record) => {
          const actions = [];
          if (record.totalValues === 0) {
            actions.push(
              <Tooltip key={`${record.id}-detail`} title={t('common.noData')}>
                <Button shape="circle" type="primary" disabled>
                  <RightOutlined />
                </Button>
              </Tooltip>,
            );
          }
          else if (record.totalValues === 1) {
            actions.push(
              <Tooltip key={`${record.id}-detail`} title={t('common.detail')}>
                <Button shape="circle" type="primary" onClick={() => navigate(`/workspaces/${workspaceId}/soundSessions/${soundSession.id}/SoundGlobalMeasure/${record.id}`)}>
                  <RightOutlined />
                </Button>
              </Tooltip>,
            );
          }
          else {
            actions.push(
              <Tooltip key={`${record.id}-detail`} title={t('common.detail')}>
                <Button shape="circle" type="primary" onClick={() => navigate(`/workspaces/${workspaceId}/soundSessions/${soundSession.id}/${kind}/${record.id}`)}>
                  <RightOutlined />
                </Button>
              </Tooltip>,
            );
          }
          actions.push(
            <Tooltip key={`${record.id}-info`} title={t('common.info')}>
              <Button shape="circle" type="primary" onClick={() => setSoundMeasureConfig(record)}>
                <InfoOutlined />
              </Button>
            </Tooltip>,
          );
          if (record.totalValues > 1) {
            if (kind.includes('Time')) {
              actions.push((
                <Tooltip title={t('common.preview')}>
                  <Button shape="circle" type="primary" onClick={() => setShowTimePreview(true)}>
                    <SearchOutlined />
                  </Button>
                </Tooltip>
              ));
            }
            if (kind.includes('Sample')) {
              actions.push((
                <Tooltip title={t('common.preview')}>
                  <Button shape="circle" type="primary" onClick={() => setShowSamplePreview(true)}>
                    <SearchOutlined />
                  </Button>
                </Tooltip>
              ));
            }
          }
          return (
            <Space size="small">
              {actions}
            </Space>
          );
        },
      },
    ];

    return result;
  }, []);

  return (
    <>
      <PageSection
        title={t('soundSessions.measuresTab.title')}
      >
        <Row>
          <Col xs={24}>
            <Table
              bordered
              size="small"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={dataSource}
              pagination={false}
            />
          </Col>
        </Row>
      </PageSection>
      {soundMeasureConfig && (
        <SoundMeasureConfigModal
          workspaceId={workspaceId}
          soundSessionId={soundSession.id}
          soundMeasure={soundMeasureConfig}
          isModalOpen={soundMeasureConfig != null}
          onClose={() => setSoundMeasureConfig(null)}
        />
      )}
      {showTimePreview && (
        <SoundSessionTimePreviewModal
          workspaceId={workspaceId}
          soundSession={soundSession}
          onClose={() => setShowTimePreview(false)}
          isModalOpen={showTimePreview}
        />
      )}
      {showSamplePreview && (
        <SoundSessionSamplePreviewModal
          workspaceId={workspaceId}
          soundSession={soundSession}
          onClose={() => setShowSamplePreview(false)}
          isModalOpen={showSamplePreview}
        />
      )}
    </>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  soundSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timeSingleMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    timeOctaveMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    timeThirdOctaveMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    timeStatisticsMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    timeFFTMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,

    sampleSingleMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    sampleOctaveMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,
    sampleThirdOctaveMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })).isRequired,

    eventSingleMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })),
    eventThirdOctaveMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })),
    eventStatisticsMeasures: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      kind: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
    })),
  }).isRequired,
};

SoundSessionMeasuresTab.propTypes = propTypes;

export default SoundSessionMeasuresTab;
