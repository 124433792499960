/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Modal, Typography,
} from 'antd';
import {
  VirtualizedTable,
} from '@deltaohm/ant-components';

import {
  getLocale,
  formatLocaleWithTimezone,
} from '../../../utils/date';

import { pascalToDecibel } from '../../../utils/converter';
import { PA_DECIMAL, DB_DECIMAL } from '../../charts/utils';

const TIMESTAMP_WIDTH = 250;
const VALUE_WIDTH = 125;

const OctaveMeasureTableModal = (props) => {
  const {
    soundSession,
    measure,
    currentConfig,
    currentIndexes,
    isModalOpen,
    onClose,
  } = props;

  const { timezone } = soundSession;

  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language);

  const dataSource = React.useMemo(() => {
    const [from, to] = currentIndexes;
    return measure.values.slice(from, to + 1);
  }, [currentIndexes, measure]);

  const leftColumns = React.useMemo(() => {
    const result = [
      {
        title: 'timestamp',
        dataIndex: 'timestamp',
        render: (timestamp) => {
          const text = formatLocaleWithTimezone(timestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, timezone, false);
          return (
            <Typography.Text style={{ fontFamily: 'monospace' }}>
              {text}
            </Typography.Text>
          );
        },
        width: TIMESTAMP_WIDTH,
      },
    ];
    return result;
  }, [timezone, locale]);

  const columns = React.useMemo(() => {
    const { config } = measure;
    const thisConfig = config.find((c) => c.measureId === currentConfig.measureId);

    const result = [
      ...thisConfig.bands.map((band, index) => ({
        title: band,
        dataIndex: '',
        render: (_, record) => {
          let text;
          const currentValue = record.data[currentConfig.measureId][index];
          if (currentValue != null && currentConfig.showDB) {
            text = pascalToDecibel(currentValue, DB_DECIMAL).toFixed(DB_DECIMAL);
          }
          if (currentValue != null && !currentConfig.showDB) {
            text = currentValue.toFixed(PA_DECIMAL);
          }
          return (
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Typography.Text style={{ fontFamily: 'monospace' }}>
                {text}
              </Typography.Text>
            </div>
          );
        },
        width: VALUE_WIDTH,
      })),
    ];
    return result;
  }, [measure, currentConfig]);

  return (
    <Modal
      title={t('soundMeasures.components.tableModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
      destroyOnClose
    >
      <VirtualizedTable
        height={500}
        bordered
        size="small"
        leftColumns={leftColumns}
        columns={columns}
        rowKey={(record) => record.timestamp}
        dataSource={dataSource}
        pagination={false}
      />
    </Modal>
  );
};

const propTypes = {
  soundSession: PropTypes.shape({
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  measure: PropTypes.shape({
    kind: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    values: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  currentIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentConfig: PropTypes.shape({
    measureId: PropTypes.string.isRequired,
    showDB: PropTypes.bool.isRequired,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

OctaveMeasureTableModal.propTypes = propTypes;

export default OctaveMeasureTableModal;
