/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Typography,
  Button,
  Tooltip,
} from 'antd';
import {
  VirtualizedTable,
} from '@deltaohm/ant-components';
import { DownloadOutlined } from '@ant-design/icons';

import {
  getLocale,
  formatLocaleWithTimezone,
} from '../../../utils/date';

import { PERCENT_DECIMAL, exportExcel } from '../../charts/utils';

const TIMESTAMP_WIDTH = 250;
const VALUE_WIDTH = 125;

const OctaveMeasureTableModal = (props) => {
  const {
    soundSession,
    measure,
  } = props;

  const { timezone } = soundSession;

  const { t, i18n } = useTranslation();
  const locale = getLocale(i18n.language);

  const dataSource = React.useMemo(() => {
    const { values, config } = measure;
    const { measureId } = config[0];
    const filteredValues = values.filter((v) => v.data[measureId] != null);

    const ds = filteredValues.map((v) => {
      const { fromTimestamp, toTimestamp, data } = v;
      const currentData = data[measureId];
      const sum = currentData.reduce((acc, item) => acc + item, 0);
      return [
        fromTimestamp,
        toTimestamp,
        ...currentData.map((val) => (val / sum) * 100),
      ];
    });
    return ds;
  }, [measure]);

  const downloadExcel = () => {
    const { config } = measure;
    const thisConfig = config[0];
    const { measureId, classes } = thisConfig;

    const header = ['from', 'to', 'fromDate', 'toDate', ...classes];

    const data = dataSource.map((ds) => ([
      ds[0],
      ds[1],
      formatLocaleWithTimezone(ds[0], 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone),
      formatLocaleWithTimezone(ds[1], 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone),
      // eslint-disable-next-line max-len
      ...ds.slice(2).map((d) => d.toFixed(PERCENT_DECIMAL)),
    ]));

    exportExcel(
      `${soundSession.name}_${measureId}_${soundSession.timezone}.xlsx`,
      `Event Statistics ${classes.length}`,
      [
        header,
        ...data,
      ],
    );
  };

  const leftColumns = React.useMemo(() => {
    const result = [
      {
        title: 'fromTimestamp',
        dataIndex: 'fromTimestamp',
        render: (_, record) => {
          const text = formatLocaleWithTimezone(record[0], 'yyyy/MM/dd HH:mm:ss:SSS', locale, timezone, false);
          return (
            <Typography.Text style={{ fontFamily: 'monospace' }}>
              {text}
            </Typography.Text>
          );
        },
        width: TIMESTAMP_WIDTH,
      },
      {
        title: 'toTimestamp',
        dataIndex: 'toTimestamp',
        render: (_, record) => {
          const text = formatLocaleWithTimezone(record[1], 'yyyy/MM/dd HH:mm:ss:SSS', locale, timezone, false);
          return (
            <Typography.Text style={{ fontFamily: 'monospace' }}>
              {text}
            </Typography.Text>
          );
        },
        width: TIMESTAMP_WIDTH,
      },
    ];
    return result;
  }, [timezone, locale]);

  const columns = React.useMemo(() => {
    const { config } = measure;
    const thisConfig = config[0];
    const result = [
      ...thisConfig.classes.map((c, index) => ({
        title: `${c[0]}-${c[1]}`,
        dataIndex: '',
        render: (_, record) => {
          const currentValue = record[index + 2];
          const text = currentValue.toFixed(PERCENT_DECIMAL);
          return (
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Typography.Text style={{ fontFamily: 'monospace' }}>
                {text}
              </Typography.Text>
            </div>
          );
        },
        width: VALUE_WIDTH,
      })),
    ];
    return result;
  }, [measure]);

  return (
    <>
      <Row justify="end">
        <Col>
          <Tooltip title={t('soundMeasures.components.charts.excel')}>
            <Button shape="circle" size="small" type="primary" onClick={downloadExcel}>
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <div style={{ height: 10 }} />
      <VirtualizedTable
        height={500}
        bordered
        size="small"
        leftColumns={leftColumns}
        columns={columns}
        rowKey={(record) => record.timestamp}
        dataSource={dataSource}
        pagination={false}
      />
    </>
  );
};

const propTypes = {
  soundSession: PropTypes.shape({
    name: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  measure: PropTypes.shape({
    kind: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    values: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

OctaveMeasureTableModal.propTypes = propTypes;

export default OctaveMeasureTableModal;
