/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useQuery } from '@apollo/client';
import { decodeAsync } from '@msgpack/msgpack';

import {
  SOUND_SESSION_SOUND_TIME_MEASURES_PREVIEW_QUERY,
  SOUND_SESSION_SOUND_SAMPLE_MEASURES_PREVIEW_QUERY,
  WORKSPACE_SOUND_SESSION_QUERY,
} from './queries';

export const useSoundSessionTimePreview = (workspaceId, soundSessionId) => {
  const [decoding, setDecoding] = React.useState(false);
  const [values, setValues] = React.useState(null);

  const {
    error,
    loading,
    data,
  } = useQuery(SOUND_SESSION_SOUND_TIME_MEASURES_PREVIEW_QUERY, {
    variables: {
      workspaceId,
      id: soundSessionId,
    },
  });

  const queryData = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace.soundSession.timeMeasurePreview;
    }
    return null;
  }, [loading, error, data]);

  React.useEffect(() => {
    if (queryData) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${queryData.values}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      if (!decoding && !values) {
        decode();
      }
    }
  }, [queryData, error, data, decoding]);

  return {
    preview: queryData && values
      ? {
        label: queryData.label,
        values,
      }
      : null,
    loading,
    error,
  };
};

export const useSoundSessionSamplePreview = (workspaceId, soundSessionId) => {
  const [decoding, setDecoding] = React.useState(false);
  const [values, setValues] = React.useState(null);

  const {
    error,
    loading,
    data,
  } = useQuery(SOUND_SESSION_SOUND_SAMPLE_MEASURES_PREVIEW_QUERY, {
    variables: {
      workspaceId,
      id: soundSessionId,
    },
  });

  const queryData = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace.soundSession.sampleMeasurePreview;
    }
    return null;
  }, [loading, error, data]);

  React.useEffect(() => {
    if (queryData) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${queryData.values}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      if (!decoding && !values) {
        decode();
      }
    }
  }, [queryData, error, data, decoding]);

  return {
    preview: queryData && values
      ? {
        label: queryData.label,
        values,
      }
      : null,
    loading,
    error,
  };
};

export const useSoundSession = (workspaceId, soundSessionId) => {
  const {
    error,
    loading,
    data,
  } = useQuery(WORKSPACE_SOUND_SESSION_QUERY, {
    variables: {
      id: soundSessionId,
      workspaceId,
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [error, data, loading]);

  const soundSession = React.useMemo(() => {
    if (!loading && !error) {
      return workspace.soundSession;
    }
    return null;
  }, [workspace]);

  return {
    error,
    loading,
    workspace,
    soundSession,
  };
};
