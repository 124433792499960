import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
  Descriptions,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import { VIBRATION_SESSION_INFO_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const VibrationSessionInfoModal = (props) => {
  const {
    workspaceId,
    vibrationSession,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    error,
    loading,
    data,
  } = useQuery(VIBRATION_SESSION_INFO_QUERY, {
    variables: {
      workspaceId,
      id: vibrationSession.id,
    },
  });

  const queryData = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace.vibrationSession;
    }
    return null;
  }, [loading, error, data]);

  const periodData = React.useMemo(() => {
    if (queryData) {
      const result = [];
      queryData.timeSingleMeasures.forEach((timeSingleMeasure) => {
        const parameterPonds = [...new Set(timeSingleMeasure.config.map((c) => c.label))].join(',');
        const channels = [...new Set(timeSingleMeasure.config.map((c) => c.channel))].join(',');
        result.push({
          title: t('vibrationSessions.components.infoModal.timeSingleMeasureTitle'),
          info: parameterPonds,
          period: `${timeSingleMeasure.period}ms`,
          channels,
        });
      });
      queryData.timeOctaveMeasures.forEach((timeOctaveMeasure) => {
        const parameterPonds = [...new Set(timeOctaveMeasure.config.map((c) => c.label))].join(',');
        const channels = [...new Set(timeOctaveMeasure.config.map((c) => c.channel))].join(',');
        result.push({
          title: t('vibrationSessions.components.infoModal.timeOctaveMeasureTitle'),
          info: parameterPonds,
          period: `${timeOctaveMeasure.period}ms`,
          channels,
        });
      });
      queryData.timeThirdOctaveMeasures.forEach((timeThirdOctaveMeasure) => {
        const parameterPonds = [...new Set(timeThirdOctaveMeasure.config.map((c) => c.label))].join(',');
        const channels = [...new Set(timeThirdOctaveMeasure.config.map((c) => c.channel))].join(',');
        result.push({
          title: t('vibrationSessions.components.infoModal.timeThirdOctaveMeasureTitle'),
          info: parameterPonds,
          period: `${timeThirdOctaveMeasure.period}ms`,
          channels,
        });
      });
      queryData.timeStatisticsMeasures.forEach((timeStatisticsMeasure) => {
        const parameterPonds = [...new Set(timeStatisticsMeasure.config.map((c) => c.label))].join(',');
        const channels = [...new Set(timeStatisticsMeasure.config.map((c) => c.channel))].join(',');
        result.push({
          title: t('vibrationSessions.components.infoModal.timeStatisticsMeasureTitle'),
          info: parameterPonds,
          period: `${timeStatisticsMeasure.period}ms`,
          channels,
        });
      });

      return result;
    }
    return [];
  }, [queryData]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  return (
    <Modal
      title={t('common.info')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        {periodData.map((item) => (
          <Descriptions column={2} title={item.title}>
            <Descriptions.Item label={t('common.info')}>
              {item.info}
            </Descriptions.Item>
            <Descriptions.Item label={t('common.channel')}>
              {item.channels}
            </Descriptions.Item>
            <Descriptions.Item label={t('common.period')}>
              {item.period}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  vibrationSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

VibrationSessionInfoModal.propTypes = propTypes;

export default VibrationSessionInfoModal;
