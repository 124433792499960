import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

import {
  useSoundSessionTimePreview,
} from '../hooks';
import { DB_DECIMAL } from '../../soundMeasures/charts/utils';

const SoundSessionTimePreviewModal = (props) => {
  const {
    workspaceId,
    soundSession,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    error,
    loading,
    preview,
  } = useSoundSessionTimePreview(workspaceId, soundSession.id);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const chartRef = React.useRef(null);
  const [chartInstance, setChartInstance] = React.useState(null);

  const dataset = React.useMemo(() => {
    if (preview) {
      const { values } = preview;

      const dataSource = values.map((v) => ({
        timestamp: utcToZonedTime(v.timestamp, soundSession.timezone),
        value: v.value,
      }));

      const result = [
        {
          dimensions: ['timestamp', 'value'],
          source: dataSource,
        },
      ];
      return result;
    }
    return null;
  }, [preview]);

  const chartTitle = React.useMemo(() => {
    if (preview) {
      if (preview.label) {
        return preview.label;
      }
    }
    return t('common.preview');
  }, [preview]);

  const getSeriesOption = () => {
    const serie = {
      id: 'preview',
      name: chartTitle,
      type: 'line',
      sampling: 'lttb',
      showSymbol: false,
      datasetIndex: 0,
      yAxisIndex: 0,
      xAxisIndex: 0,
      encode: {
        x: 'timestamp',
        y: 'value',
      },
    };

    return [serie];
  };

  const getInitialChartOption = () => {
    const result = {
      animation: false,
      title: {
        id: 'title',
        text: chartTitle,
      },
      grid: {
        id: 'graph_grid',
        show: true,
        top: '17%',
        bottom: '25%',
      },
      tooltip: {
        id: 'tooltip',
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          snap: true,
        },
        showContent: true,
        formatter: (params) => {
          let timestamp;
          const series = [];
          for (let i = 0; i < params.length; i += 1) {
            const current = params[i];
            timestamp = current.value.timestamp;
            const { value } = current.value;
            if (value != null) {
              const realValue = value.toFixed(DB_DECIMAL);
              const unitOfMeasure = t('common.db');
              series.push(`${current.marker} ${current.seriesName}: ${realValue} ${unitOfMeasure}`);
            }
          }
          const tooltip = `${format(timestamp, 'yyyy-MM-dd HH:mm:ss.SSS')}\n${series.join('\n')}`;
          return tooltip;
        },
      },
      dataZoom: [
        {
          id: 'x_zoom_slider',
          type: 'slider',
          show: true,
          xAxisIndex: [0],
          start: 0,
          end: 100,
          height: '3%',
          bottom: '3%',
          filterMode: 'none',
        },
        {
          id: 'y_zoom_slider',
          type: 'slider',
          show: true,
          yAxisIndex: [0],
          left: '1%',
          start: 0,
          end: 100,
          width: '3%',
          filterMode: 'none',
        },
        {
          id: 'x_zoom_inside',
          type: 'inside',
          xAxisIndex: [0],
          start: 0,
          end: 100,
          filterMode: 'none',
        },
      ],
      dataset,
      xAxis: {
        id: 'x_data',
        type: 'time',
        name: t('common.time'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        gridIndex: 0,
      },
      yAxis: {
        id: 'y_data',
        type: 'value',
        name: t('common.value'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        gridIndex: 0,
      },
      series: getSeriesOption(),
    };

    return result;
  };

  React.useEffect(() => {
    if (chartInstance) {
      return () => {
        chartInstance.dispose();
      };
    }
    return () => { };
  }, [chartInstance]);

  React.useEffect(() => {
    if (preview && chartRef && chartRef.current && !chartInstance) {
      const instance = echarts.init(chartRef.current, null);
      instance.setOption(getInitialChartOption());
      setChartInstance(instance);
    }
  }, [chartRef, preview]);

  return (
    <Modal
      title={t('common.info')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        <div style={{ minHeight: 400 }} ref={chartRef} />
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  soundSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SoundSessionTimePreviewModal.propTypes = propTypes;

export default SoundSessionTimePreviewModal;
