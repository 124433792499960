import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
  Descriptions,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import { SOUND_SESSION_INFO_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const SoundSessionInfoModal = (props) => {
  const {
    workspaceId,
    soundSession,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    error,
    loading,
    data,
  } = useQuery(SOUND_SESSION_INFO_QUERY, {
    variables: {
      workspaceId,
      id: soundSession.id,
    },
  });

  const queryData = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace.soundSession;
    }
    return null;
  }, [loading, error, data]);

  const periodData = React.useMemo(() => {
    if (queryData) {
      const result = [];
      result.push(...queryData.timeSingleMeasures.map((timeSingleMeasure) => {
        const parameters = timeSingleMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.timeSingleMeasureTitle'),
          info: parameters,
          period: `${timeSingleMeasure.period}ms`,
        };
      }));
      result.push(...queryData.timeOctaveMeasures.map((timeOctaveMeasure) => {
        const parameters = timeOctaveMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.timeOctaveMeasureTitle'),
          info: parameters,
          period: `${timeOctaveMeasure.period}ms`,
        };
      }));
      result.push(...queryData.timeThirdOctaveMeasures.map((timeThirdOctaveMeasure) => {
        const parameters = timeThirdOctaveMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.timeThirdOctaveMeasureTitle'),
          info: parameters,
          period: `${timeThirdOctaveMeasure.period}ms`,
        };
      }));
      result.push(...queryData.timeStatisticsMeasures.map((timeStatisticsMeasure) => {
        const parameters = timeStatisticsMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.timeStatisticsMeasureTitle'),
          info: parameters,
          period: `${timeStatisticsMeasure.period}ms`,
        };
      }));
      result.push(...queryData.timeFFTMeasures.map((timeFFTMeasure) => {
        const parameters = timeFFTMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.timeFFTMeasureTitle'),
          info: parameters,
          period: `${timeFFTMeasure.period}ms`,
        };
      }));

      result.push(...queryData.sampleSingleMeasures.map((sampleSingleMeasure) => {
        const parameters = sampleSingleMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.sampleSingleMeasureTitle'),
          info: parameters,
          period: `${sampleSingleMeasure.period}ms`,
        };
      }));
      result.push(...queryData.sampleOctaveMeasures.map((sampleOctaveMeasure) => {
        const parameters = sampleOctaveMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.sampleOctaveMeasureTitle'),
          info: parameters,
          period: `${sampleOctaveMeasure.period}ms`,
        };
      }));
      result.push(...queryData.sampleThirdOctaveMeasures.map((sampleThirdOctaveMeasure) => {
        const parameters = sampleThirdOctaveMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.sampleThirdOctaveMeasureTitle'),
          info: parameters,
          period: `${sampleThirdOctaveMeasure.period}ms`,
        };
      }));

      return result;
    }
    return [];
  }, [queryData]);

  const eventData = React.useMemo(() => {
    if (queryData) {
      const result = [];
      result.push(...queryData.eventSingleMeasures.map((eventSingleMeasure) => {
        const parameters = eventSingleMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.eventSingleMeasureTitle', { eventName: eventSingleMeasure.eventName }),
          info: parameters,
        };
      }));

      result.push(...queryData.eventThirdOctaveMeasures.map((eventThirdOctaveMeasure) => {
        const parameters = eventThirdOctaveMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.eventThirdOctaveMeasureTitle', { eventName: eventThirdOctaveMeasure.eventName }),
          info: parameters,
        };
      }));

      result.push(...queryData.eventStatisticsMeasures.map((eventStatisticsMeasure) => {
        const parameters = eventStatisticsMeasure.config.map((c) => c.label).join(',');
        return {
          title: t('soundSessions.components.infoModal.eventStatisticsMeasureTitle', { eventName: eventStatisticsMeasure.eventName }),
          info: parameters,
        };
      }));

      return result;
    }
    return [];
  }, [queryData]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  return (
    <Modal
      title={t('common.info')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        {periodData.map((item) => (
          <Descriptions column={2} title={item.title}>
            <Descriptions.Item label={t('common.info')}>
              {item.info}
            </Descriptions.Item>
            <Descriptions.Item label={t('common.period')}>
              {item.period}
            </Descriptions.Item>
          </Descriptions>
        ))}
        {eventData.map((item) => (
          <Descriptions column={1} title={item.title}>
            <Descriptions.Item label={t('common.info')}>
              {item.info}
            </Descriptions.Item>
          </Descriptions>
        ))}
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  soundSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SoundSessionInfoModal.propTypes = propTypes;

export default SoundSessionInfoModal;
