import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
} from 'antd';
import { useTranslation } from 'react-i18next';

const WorkspaceFilter = (props) => {
  const { searchObject, onSearch } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={searchObject}
          onFinish={onSearch}
          autoComplete="off"
        >
          <Form.Item
            label={t('workspaces.components.filter.search')}
            name="search"
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.filter')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  searchObject: PropTypes.shape({
    search: PropTypes.string,
  }),
  onSearch: PropTypes.func.isRequired,
};

const defaultProps = {
  searchObject: {
    search: '',
  },
};

WorkspaceFilter.propTypes = propTypes;
WorkspaceFilter.defaultProps = defaultProps;

export default WorkspaceFilter;
