/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
} from 'antd';
import {
  PageSection,
  messageError,
  messageSuccess,
  ComponentLoader,
} from '@deltaohm/ant-components';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors, clearTypenames } from '../../utils/graphql';

import { ErrorList } from '../../generics';

import { WorkspaceChangeUserPermissionsForm, WorkspaceChangeOwnerForm } from '../components';
import { WORKSPACE_CHANGE_OWNER_MUTATION, WORKSPACE_CHANGE_USER_PERMISSIONS_MUTATION } from '../queries';

import WORKSPACE_ROLES from '../../enums/workspaceRoles';

const WorkspaceUserTab = (props) => {
  const {
    workspace,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const canUpdateUserPermissions = React.useMemo(() => {
    const correctRole = workspace.myRoles.includes(WORKSPACE_ROLES.WORKSPACE_MANAGER);
    return workspace.iAmOwner || correctRole;
  }, [workspace]);

  const [
    workspaceChangeUserPermissionsAction,
    {
      loading: loadingChangeUserPermissions,
      error: errorChangeUserPermissions,
    },
  ] = useMutation(WORKSPACE_CHANGE_USER_PERMISSIONS_MUTATION);

  const [
    workspaceChangeOwnerAction,
    {
      loading: loadingChangeOwner,
      error: errorChangeOwner,
    },
  ] = useMutation(WORKSPACE_CHANGE_OWNER_MUTATION);

  React.useEffect(() => {
    if (errorChangeUserPermissions || errorChangeOwner) {
      const errors = extractGraphQLErrors(errorChangeUserPermissions || errorChangeOwner, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorChangeUserPermissions, errorChangeOwner, t]);

  const handleChangeUsersFormSubmit = async (data) => {
    try {
      const realUserPermissions = data.userPermissions
        ? data.userPermissions.map((up) => clearTypenames({ ...up }))
        : null;
      await workspaceChangeUserPermissionsAction({
        variables: {
          input: {
            id: workspace.id,
            userPermissions: realUserPermissions,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  const handleChangeOwnerFormSubmit = async (data) => {
    try {
      await workspaceChangeOwnerAction({
        variables: {
          input: {
            id: workspace.id,
            owner: data.owner,
          },
        },
      });
      navigate('/workspaces');
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('workspaces.userTab.title')}
    >
      <Row gutter={24}>
        <Col xs={24} md={workspace.iAmOwner ? 12 : 24}>
          <Divider>
            {t('workspaces.userTab.userPermissionsTitle')}
          </Divider>
          <ComponentLoader
            message={t('common.loading')}
            loading={loadingChangeUserPermissions}
          >
            <WorkspaceChangeUserPermissionsForm
              workspace={workspace}
              onFormSubmit={handleChangeUsersFormSubmit}
              canEdit={canUpdateUserPermissions}
            />
          </ComponentLoader>
        </Col>
        {workspace.iAmOwner && (
          <Col xs={24} md={12}>
            <Divider>
              {t('workspaces.userTab.ownerTitle')}
            </Divider>
            <ComponentLoader
              message={t('common.loading')}
              loading={loadingChangeOwner}
            >
              <WorkspaceChangeOwnerForm
                workspace={workspace}
                onFormSubmit={handleChangeOwnerFormSubmit}
                canEdit={workspace.iAmOwner}
              />
            </ComponentLoader>
          </Col>
        )}
      </Row>
    </PageSection>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workspace: PropTypes.any.isRequired,
};

WorkspaceUserTab.propTypes = propTypes;

export default WorkspaceUserTab;
