/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Typography,
} from 'antd';
import {
  VirtualizedTable,
} from '@deltaohm/ant-components';

import {
  getLocale,
  formatLocaleWithTimezone,
} from '../../../utils/date';

import { pascalToDecibel } from '../../../utils/converter';
import { DB_DECIMAL } from '../../charts/utils';

const TIMESTAMP_WIDTH = 250;
const VALUE_WIDTH = 125;

const SingleMeasureTableModal = (props) => {
  const {
    soundSession,
    measure,
    height,
  } = props;

  const { timezone } = soundSession;

  const { i18n } = useTranslation();
  const locale = getLocale(i18n.language);

  const dataSource = React.useMemo(() => measure.values, [measure]);

  const leftColumns = React.useMemo(() => {
    const result = [
      {
        title: 'timestamp',
        dataIndex: 'timestamp',
        render: (timestamp) => {
          const text = formatLocaleWithTimezone(timestamp, 'yyyy/MM/dd HH:mm:ss:SSS', locale, timezone, false);
          return (
            <Typography.Text style={{ fontFamily: 'monospace' }}>
              {text}
            </Typography.Text>
          );
        },
        width: TIMESTAMP_WIDTH,
      },
    ];
    return result;
  }, [timezone, locale]);

  const columns = React.useMemo(() => {
    const { config } = measure;

    const result = [
      ...config.map((conf) => ({
        title: conf.label,
        dataIndex: '',
        render: (_, record) => {
          let text;
          const currentValue = record.data[conf.measureId];
          if (currentValue != null) {
            text = pascalToDecibel(currentValue, DB_DECIMAL).toFixed(DB_DECIMAL);
          }
          return (
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Typography.Text style={{ fontFamily: 'monospace' }}>
                {text}
              </Typography.Text>
            </div>
          );
        },
        width: VALUE_WIDTH,
      })),
    ];
    return result;
  }, [measure]);

  return (
    <VirtualizedTable
      height={height}
      bordered
      size="small"
      leftColumns={leftColumns}
      columns={columns}
      rowKey={(record) => record.timestamp}
      dataSource={dataSource}
      pagination={false}
    />
  );
};

const propTypes = {
  soundSession: PropTypes.shape({
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  measure: PropTypes.shape({
    kind: PropTypes.string,
    config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    values: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  height: PropTypes.number,
};

const defaultProps = {
  height: 500,
};

SingleMeasureTableModal.propTypes = propTypes;
SingleMeasureTableModal.defaultProps = defaultProps;

export default SingleMeasureTableModal;
