/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const SOUND_MEASURE_CONFIG_QUERY = gql`
  query SoundMeasureConfigQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        soundSession(id: $soundSessionId) {
          id
          measure(id: $id) {
            id
            config
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_SOUND_TIME_MEASURES_DETAILS_QUERY = gql`
query SoundSessionSoundTimeMeasuresDetailQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!, $from: Float) {
  viewer {
    workspace(id: $workspaceId) {
      id
      name
      soundSession(id: $soundSessionId) {
        id
        name
        timezone
        measure(id: $id) {
          id
          name
          kind
          name
          config
          totalValues
          ...on SoundTimeMeasure {
            minTimestamp
            maxTimestamp
            availableWindow(from: $from)
          }
        }
      }
    }
  }
}
`;

export const SOUND_SESSION_SOUND_TIME_MEASURES_VALUES_QUERY = gql`
  query SoundSessionSoundTimeMeasuresValuesQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!, $filter: SoundTimeValueFilter!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        soundSession(id: $soundSessionId) {
          id
          name
          timezone
          config
          soundTimeSeries {
            id
            name
            values {
              from
              to
              attachments {
                name
                url
                metadata
              }
            }
          }
          measure(id: $id) {
            id
            kind
            name
            config
            ...on SoundTimeMeasure {
              values(filter: $filter)
            }
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_SOUND_TIME_GLOBAL_MEASURES_VALUES_QUERY = gql`
  query SoundSessionSoundTimeGlobalMeasuresValuesQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        soundSession(id: $soundSessionId) {
          id
          name
          timezone
          config
          recordDate
          soundTimeSeries {
            id
            name
            values {
              from
              to
              attachments {
                name
                url
                metadata
              }
            }
          }
          measure(id: $id) {
            id
            kind
            name
            config
            ...on SoundTimeMeasure {
              values
            }
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_SOUND_SAMPLE_MEASURES_DETAILS_QUERY = gql`
query SoundSessionSoundSampleMeasuresDetailQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!, $from: Int) {
  viewer {
    workspace(id: $workspaceId) {
      id
      name
      soundSession(id: $soundSessionId) {
        id
        name
        timezone
        recordDate
        measure(id: $id) {
          id
          name
          kind
          name
          config
          totalValues
          ...on SoundSampleMeasure {
            minSample
            maxSample
            availableWindow(from: $from)
          }
        }
      }
    }
  }
}
`;

export const SOUND_SESSION_SOUND_SAMPLE_MEASURES_VALUES_QUERY = gql`
  query SoundSessionSoundTimeMeasuresValuesQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!, $filter: SoundSampleValueFilter!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        soundSession(id: $soundSessionId) {
          id
          name
          timezone
          config
          measure(id: $id) {
            id
            kind
            name
            config
            ...on SoundSampleMeasure {
              values(filter: $filter)
            }
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_SOUND_EVENT_MEASURES_DETAILS_QUERY = gql`
  query SoundSessionSoundEventMeasuresDetailQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        soundSession(id: $soundSessionId) {
          id
          name
          timezone
          measure(id: $id) {
            id
            kind
            name
            config
            totalValues
            ...on SoundEventMeasure {
              firstEventTimestamp
              lastEventTimestamp
              totalValues
            }
          }
        }
      }
    }
  }
`;

export const SOUND_SESSION_SOUND_EVENT_MEASURES_VALUES_QUERY = gql`
  query SoundSessionSoundEventMeasuresValuesQuery($workspaceId: ID!, $soundSessionId: ID!, $id: ID!, $filter: SoundEventValueFilter!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        soundSession(id: $soundSessionId) {
          id
          name
          timezone
          measure(id: $id) {
            id
            name
            kind
            config
            ...on SoundEventMeasure {
              values(filter: $filter)
            }
          }
        }
      }
    }
  }
`;
