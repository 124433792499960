/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
  Row,
  Col,
  Table,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
} from '@deltaohm/ant-components';

import { SOUND_MEASURE_CONFIG_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const SoundMeasureConfigModal = (props) => {
  const {
    workspaceId,
    soundSessionId,
    soundMeasure,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    error,
    loading,
    data,
  } = useQuery(SOUND_MEASURE_CONFIG_QUERY, {
    variables: {
      workspaceId,
      soundSessionId,
      id: soundMeasure.id,
    },
    skip: soundMeasure.config != null,
  });

  const config = React.useMemo(() => {
    if (soundMeasure.config) {
      return soundMeasure.config;
    }
    if (!loading && !error) {
      return data.viewer.workspace.soundSession.measure.config;
    }
    return null;
  }, [loading, error, data, soundMeasure]);

  const columns = React.useMemo(() => {
    const toExclude = ['id'];
    const set = config.reduce((acc, conf) => {
      const keys = Object.keys(conf);
      keys.forEach((key) => {
        if (!toExclude.includes(key)) {
          acc.add(key);
        }
      });
      return acc;
    }, new Set(['label']));

    return [...set].map((key) => ({
      title: t(`common.parameters.${key}`),
      dataIndex: key,
    }));
  }, [config, t]);

  const dataSource = React.useMemo(() => config.map((conf) => {
    const keys = Object.keys(conf);
    return keys.reduce((acc, key) => {
      let value = conf[key];
      if (Array.isArray(value)) {
        value = value.length;
      }
      acc[key] = value;
      return acc;
    }, {});
  }), [config]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  return (
    <Modal
      title={t('common.info')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <Row>
        <Col span={24}>
          <Table
            bordered
            size="small"
            scroll={{ x: true }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={dataSource}
            pagination={false}
          />
        </Col>
      </Row>
    </Modal>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  soundSessionId: PropTypes.string.isRequired,
  soundMeasure: PropTypes.shape({
    id: PropTypes.string.isRequired,
    config: PropTypes.any,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SoundMeasureConfigModal.propTypes = propTypes;

export default SoundMeasureConfigModal;
