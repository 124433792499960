/* eslint-disable no-param-reassign */
import * as echarts from 'echarts';
import {
  ms2ToCms2,
  ms2ToDecibel,
  ms2ToFts2,
  ms2ToG,
  ms2ToIns2,
  ms2ToMms2,
  pascalToDecibel,
} from './utils/converter';

const PercentageTransform = {
  type: 'deltaohm:PercentageTransform',
  transform: function transform(params) {
    console.time('deltaohm:PercentageTransform');
    const { sumDimensionIndexes } = params.config;
    const dimensions = params.upstream.cloneAllDimensionInfo();
    const rawData = params.upstream.cloneRawData();

    const sums = {};
    for (let i = 0; i < sumDimensionIndexes.length; i += 1) {
      const currentIndex = sumDimensionIndexes[i];
      sums[currentIndex] = rawData
        .reduce((acc, item) => acc + item[currentIndex], 0);
    }

    const source = [];
    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      source.push(value.map((data, index) => {
        if (sumDimensionIndexes.includes(index)) {
          return (data / sums[index]) * 100;
        }
        return data;
      }));
    }
    console.timeEnd('deltaohm:PercentageTransform');
    return [{
      dimensions: [...dimensions],
      data: source,
    }];
  },
};

const CumulativePercentageTransform = {
  type: 'deltaohm:CumulativePercentageTransform',
  transform: function transform(params) {
    console.time('deltaohm:CumulativePercentageTransform');
    const rawData = params.upstream.cloneRawData();

    let current = 100;
    const source = [];
    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      const [timestamp, currentValue] = value;
      current -= currentValue;
      source.push([
        timestamp,
        current,
      ]);
    }
    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:CumulativePercentageTransform');
    return [{
      dimensions: [dimensions[0].name, 'value'],
      data: source,
    }];
  },
};

const StatisticsToHeatmapTransform = {
  type: 'deltaohm:StatisticsToHeatmapTransform',
  transform: function transform(params) {
    console.time('deltaohm:StatisticsToHeatmapTransform');
    const { measureConfig } = params.config;
    const { classes, period } = measureConfig;
    const rawData = params.upstream.cloneRawData();

    let result = rawData;
    if (result.length) {
      let [prev] = result;
      result = [prev];
      for (let i = 1; i < rawData.length; i += 1) {
        const current = rawData[i];
        const [currentTimestamp] = current;
        const [prevTimestamp] = prev;
        for (let j = prevTimestamp + period; j < currentTimestamp; j += period) {
          result.push([j, ...Array(classes.length).fill(null)]);
        }
        result.push(current);
        prev = current;
      }
    }

    const source = [];
    for (let i = 0; i < result.length; i += 1) {
      const value = result[i];
      const [timestamp, ...classesData] = value;
      for (let j = 0; j < classes.length; j += 1) {
        source.push([
          timestamp,
          classes[j],
          classesData[j],
        ]);
      }
    }
    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:StatisticsToHeatmapTransform');
    return [{
      dimensions: [dimensions[0].name, 'class', 'value'],
      data: source,
    }];
  },
};

const FFTToHeatmapTransform = {
  type: 'deltaohm:FFTToHeatmapTransform',
  transform: function transform(params) {
    console.time('deltaohm:FFTToHeatmapTransform');
    const { measureConfig } = params.config;
    const { bands, period } = measureConfig;
    const rawData = params.upstream.cloneRawData();
    let result = rawData;
    if (result.length) {
      let [prev] = result;
      result = [prev];
      for (let i = 1; i < rawData.length; i += 1) {
        const current = rawData[i];
        const [currentTimestamp] = current;
        const [prevTimestamp] = prev;
        for (let j = prevTimestamp + period; j < currentTimestamp; j += period) {
          result.push([j, ...Array(bands.length).fill(null)]);
        }
        result.push(current);
        prev = current;
      }
    }

    const source = [];
    for (let i = 0; i < result.length; i += 1) {
      const value = result[i];
      const [timestamp, ...bandsData] = value;
      for (let j = 0; j < bands.length; j += 1) {
        source.push([
          timestamp,
          bands[j],
          bandsData[j],
        ]);
      }
    }
    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:FFTToHeatmapTransform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: source,
    }];
  },
};

const SpectreToHeatmapTransform = {
  type: 'deltaohm:SpectreToHeatmapTransform',
  transform: function transform(params) {
    console.time('deltaohm:SpectreToHeatmapTransform');
    const { measureConfig } = params.config;
    const { bands, period } = measureConfig;
    const rawData = params.upstream.cloneRawData();
    let result = rawData;
    if (result.length) {
      let [prev] = result;
      result = [prev];
      for (let i = 1; i < rawData.length; i += 1) {
        const current = rawData[i];
        const [currentTimestamp] = current;
        const [prevTimestamp] = prev;
        for (let j = prevTimestamp + period; j < currentTimestamp; j += period) {
          result.push([j, ...Array(bands.length).fill(null)]);
        }
        result.push(current);
        prev = current;
      }
    }

    const source = [];
    for (let i = 0; i < result.length; i += 1) {
      const value = result[i];
      const [timestamp, ...bandsData] = value;
      for (let j = 0; j < bands.length; j += 1) {
        source.push([
          timestamp,
          bands[j],
          bandsData[j],
        ]);
      }
    }
    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:SpectreToHeatmapTransform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: source,
    }];
  },
};

const HeatmapPAtoDBTransform = {
  type: 'deltaohm:HeatmapPAtoDBTransform',
  transform: function transform(params) {
    console.time('deltaohm:HeatmapPAtoDBTransform');
    const rawData = params.upstream.cloneRawData();

    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      value[2] = pascalToDecibel(value[2], 2);
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:HeatmapPAtoDBTransform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: rawData,
    }];
  },
};

const PAtoDBTransform = {
  type: 'deltaohm:PAtoDBTransform',
  transform: function transform(params) {
    console.time('deltaohm:PAtoDBTransform');
    const { measureConfig, indexes } = params.config;
    const data = params.upstream.cloneRawData();
    if (measureConfig) {
      const paMeasureIds = measureConfig.filter((mc) => mc.unitOfMeasure === 'Pa')
        .map((mc) => mc.measureId);
      data.forEach((d) => {
        paMeasureIds.forEach((id) => {
          if (d[id] != null) {
            d[id] = pascalToDecibel(d[id], 2);
          }
        });
      });
    }
    if (indexes) {
      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        for (let j = indexes[0]; j < indexes[1] + 1; j += 1) {
          if (currentData[j] != null) {
            currentData[j] = pascalToDecibel(currentData[j], 2);
          }
        }
      }
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:PAtoDBTransform');

    return [{
      dimensions: dimensions.map((d) => d.name),
      data,
    }];
  },
};

echarts.registerTransform(PercentageTransform);
echarts.registerTransform(CumulativePercentageTransform);
echarts.registerTransform(StatisticsToHeatmapTransform);
echarts.registerTransform(SpectreToHeatmapTransform);
echarts.registerTransform(FFTToHeatmapTransform);
echarts.registerTransform(HeatmapPAtoDBTransform);
echarts.registerTransform(PAtoDBTransform);

const MS2toDBTransform = {
  type: 'deltaohm:MS2toDBTransform',
  transform: function transform(params) {
    console.time('deltaohm:MS2toDBTransform');
    const { measureConfig, indexes } = params.config;
    const data = params.upstream.cloneRawData();
    if (measureConfig) {
      const ms2MeasureIds = measureConfig.filter((mc) => mc.unitOfMeasure === 'm/s²')
        .map((mc) => mc.measureId);
      data.forEach((d) => {
        ms2MeasureIds.forEach((id) => {
          if (d[id] != null) {
            d[id] = ms2ToDecibel(d[id], 2);
          }
        });
      });
    }
    if (indexes) {
      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        for (let j = indexes[0]; j < indexes[1] + 1; j += 1) {
          if (currentData[j] != null) {
            currentData[j] = ms2ToDecibel(currentData[j], 2);
          }
        }
      }
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:MS2toDBTransform');

    return [{
      dimensions: dimensions.map((d) => d.name),
      data,
    }];
  },
};

const MS2toCMS2Transform = {
  type: 'deltaohm:MS2toCMS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:MS2toDBTransform');
    const { measureConfig, indexes } = params.config;
    const data = params.upstream.cloneRawData();
    if (measureConfig) {
      const ms2MeasureIds = measureConfig.filter((mc) => mc.unitOfMeasure === 'm/s²')
        .map((mc) => mc.measureId);
      data.forEach((d) => {
        ms2MeasureIds.forEach((id) => {
          if (d[id] != null) {
            d[id] = ms2ToCms2(d[id], 2);
          }
        });
      });
    }
    if (indexes) {
      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        for (let j = indexes[0]; j < indexes[1] + 1; j += 1) {
          if (currentData[j] != null) {
            currentData[j] = ms2ToCms2(currentData[j], 2);
          }
        }
      }
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:MS2toCMS2Transform');

    return [{
      dimensions: dimensions.map((d) => d.name),
      data,
    }];
  },
};

const MS2toMMS2Transform = {
  type: 'deltaohm:MS2toMMS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:MS2toMMS2Transform');
    const { measureConfig, indexes } = params.config;
    const data = params.upstream.cloneRawData();
    if (measureConfig) {
      const ms2MeasureIds = measureConfig.filter((mc) => mc.unitOfMeasure === 'm/s²')
        .map((mc) => mc.measureId);
      data.forEach((d) => {
        ms2MeasureIds.forEach((id) => {
          if (d[id] != null) {
            d[id] = ms2ToMms2(d[id], 2);
          }
        });
      });
    }
    if (indexes) {
      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        for (let j = indexes[0]; j < indexes[1] + 1; j += 1) {
          if (currentData[j] != null) {
            currentData[j] = ms2ToMms2(currentData[j], 2);
          }
        }
      }
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:MS2toMMS2Transform');

    return [{
      dimensions: dimensions.map((d) => d.name),
      data,
    }];
  },
};

const MS2toINS2Transform = {
  type: 'deltaohm:MS2toINS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:MS2toINS2Transform');
    const { measureConfig, indexes } = params.config;
    const data = params.upstream.cloneRawData();
    if (measureConfig) {
      const ms2MeasureIds = measureConfig.filter((mc) => mc.unitOfMeasure === 'm/s²')
        .map((mc) => mc.measureId);
      data.forEach((d) => {
        ms2MeasureIds.forEach((id) => {
          if (d[id] != null) {
            d[id] = ms2ToIns2(d[id], 2);
          }
        });
      });
    }
    if (indexes) {
      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        for (let j = indexes[0]; j < indexes[1] + 1; j += 1) {
          if (currentData[j] != null) {
            currentData[j] = ms2ToIns2(currentData[j], 2);
          }
        }
      }
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:MS2toINS2Transform');

    return [{
      dimensions: dimensions.map((d) => d.name),
      data,
    }];
  },
};

const MS2toFTS2Transform = {
  type: 'deltaohm:MS2toFTS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:MS2toFTS2Transform');
    const { measureConfig, indexes } = params.config;
    const data = params.upstream.cloneRawData();
    if (measureConfig) {
      const ms2MeasureIds = measureConfig.filter((mc) => mc.unitOfMeasure === 'm/s²')
        .map((mc) => mc.measureId);
      data.forEach((d) => {
        ms2MeasureIds.forEach((id) => {
          if (d[id] != null) {
            d[id] = ms2ToFts2(d[id], 2);
          }
        });
      });
    }
    if (indexes) {
      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        for (let j = indexes[0]; j < indexes[1] + 1; j += 1) {
          if (currentData[j] != null) {
            currentData[j] = ms2ToFts2(currentData[j], 2);
          }
        }
      }
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:MS2toFTS2Transform');

    return [{
      dimensions: dimensions.map((d) => d.name),
      data,
    }];
  },
};

const MS2toGTransform = {
  type: 'deltaohm:MS2toGTransform',
  transform: function transform(params) {
    console.time('deltaohm:MS2toGTransform');
    const { measureConfig, indexes } = params.config;
    const data = params.upstream.cloneRawData();
    if (measureConfig) {
      const ms2MeasureIds = measureConfig.filter((mc) => mc.unitOfMeasure === 'm/s²')
        .map((mc) => mc.measureId);
      data.forEach((d) => {
        ms2MeasureIds.forEach((id) => {
          if (d[id] != null) {
            d[id] = ms2ToG(d[id], 2);
          }
        });
      });
    }
    if (indexes) {
      for (let i = 0; i < data.length; i += 1) {
        const currentData = data[i];
        for (let j = indexes[0]; j < indexes[1] + 1; j += 1) {
          if (currentData[j] != null) {
            currentData[j] = ms2ToG(currentData[j], 2);
          }
        }
      }
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:MS2toGTransform');

    return [{
      dimensions: dimensions.map((d) => d.name),
      data,
    }];
  },
};

const HeatmapMS2toDBTransform = {
  type: 'deltaohm:HeatmapMS2toDBTransform',
  transform: function transform(params) {
    console.time('deltaohm:HeatmapMS2toDBTransform');
    const rawData = params.upstream.cloneRawData();

    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      value[2] = ms2ToDecibel(value[2], 2);
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:HeatmapMS2toDBTransform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: rawData,
    }];
  },
};

const HeatmapMS2toCMS2Transform = {
  type: 'deltaohm:HeatmapMS2toCMS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:HeatmapMS2toDBTransform');
    const rawData = params.upstream.cloneRawData();

    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      value[2] = ms2ToCms2(value[2], 2);
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:HeatmapMS2toCMS2Transform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: rawData,
    }];
  },
};

const HeatmapMS2toMMS2Transform = {
  type: 'deltaohm:HeatmapMS2toMMS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:HeatmapMS2toMMS2Transform');
    const rawData = params.upstream.cloneRawData();

    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      value[2] = ms2ToMms2(value[2], 2);
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:HeatmapMS2toMMS2Transform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: rawData,
    }];
  },
};

const HeatmapMS2toINS2Transform = {
  type: 'deltaohm:HeatmapMS2toINS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:HeatmapMS2toINS2Transform');
    const rawData = params.upstream.cloneRawData();

    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      value[2] = ms2ToIns2(value[2], 2);
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:HeatmapMS2toINS2Transform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: rawData,
    }];
  },
};

const HeatmapMS2toFTS2Transform = {
  type: 'deltaohm:HeatmapMS2toFTS2Transform',
  transform: function transform(params) {
    console.time('deltaohm:HeatmapMS2toFTS2Transform');
    const rawData = params.upstream.cloneRawData();

    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      value[2] = ms2ToFts2(value[2], 2);
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:HeatmapMS2toFTS2Transform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: rawData,
    }];
  },
};

const HeatmapMS2toGTransform = {
  type: 'deltaohm:HeatmapMS2toGTransform',
  transform: function transform(params) {
    console.time('deltaohm:HeatmapMS2toGTransform');
    const rawData = params.upstream.cloneRawData();

    for (let i = 0; i < rawData.length; i += 1) {
      const value = rawData[i];
      value[2] = ms2ToG(value[2], 2);
    }

    const dimensions = params.upstream.cloneAllDimensionInfo();
    console.timeEnd('deltaohm:HeatmapMS2toGTransform');
    return [{
      dimensions: [dimensions[0].name, 'band', 'value'],
      data: rawData,
    }];
  },
};

// echarts.registerTransform(PercentageTransform);
// echarts.registerTransform(CumulativePercentageTransform);
// echarts.registerTransform(StatisticsToHeatmapTransform);

echarts.registerTransform(HeatmapMS2toDBTransform);
echarts.registerTransform(HeatmapMS2toCMS2Transform);
echarts.registerTransform(HeatmapMS2toMMS2Transform);
echarts.registerTransform(HeatmapMS2toINS2Transform);
echarts.registerTransform(HeatmapMS2toFTS2Transform);
echarts.registerTransform(HeatmapMS2toGTransform);
echarts.registerTransform(MS2toDBTransform);
echarts.registerTransform(MS2toCMS2Transform);
echarts.registerTransform(MS2toMMS2Transform);
echarts.registerTransform(MS2toINS2Transform);
echarts.registerTransform(MS2toFTS2Transform);
echarts.registerTransform(MS2toGTransform);
