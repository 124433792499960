/* eslint-disable max-len */
import React from 'react';

import {
  Routes,
  Route,
  Outlet,
  Link,
  useLocation,
} from 'react-router-dom';

import {
  Menu,
  Avatar,
  Dropdown,
} from 'antd';

import { TopbarLayout } from '@deltaohm/ant-components';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../authentications';

import config from '../config';
import {
  WorkspaceListPage,
  WorkspaceDetailPage,
} from '../workspaces';

import {
  SoundSessionDetailPage,
} from '../soundSessions';

import {
  SoundTimeMeasureChartPage,
  SoundSampleMeasureChartPage,
  SoundEventMeasureTablePage,
  SoundGlobalMeasureTablePage,
} from '../soundMeasures';
import { VibrationSessionDetailPage } from '../vibrationSessions';
import { VibrationTimeMeasureChartPage } from '../vibrationMeasures';
import { QuotaListPage } from '../quotas';

const AuthenticatedLayout = () => {
  const { currentUser, quota, logout } = React.useContext(AuthenticationContext);
  const { t } = useTranslation();
  const location = useLocation();

  const initials = React.useMemo(() => {
    // eslint-disable-next-line prefer-regex-literals
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    const userInitials = [...currentUser.displayName.matchAll(rgx)] || [];

    const result = (
      (userInitials.shift()?.[1] || '') + (userInitials.pop()?.[1] || '')
    ).toUpperCase();

    return result;
  }, [currentUser]);

  const isAdmin = React.useMemo(() => {
    const result = currentUser.roles.includes('ADMIN');
    return result;
  }, [currentUser]);

  const quotaPercent = React.useMemo(() => {
    if (quota != null) {
      const {
        workspaceUsedQuota,
        workspaceAvailableQuota,
      } = quota;
      if (workspaceAvailableQuota == null) {
        return 0;
      }
      return ((workspaceUsedQuota / workspaceAvailableQuota) * 100);
    }
    return null;
  }, [quota]);

  const selectedHeaderMenuKeys = React.useMemo(() => {
    const result = [];
    if (location.pathname.includes('/workspaces')) {
      result.push('workspaces');
    }
    return result;
  }, [location]);

  const headerMenu = React.useMemo(() => (
    <Menu selectedKeys={selectedHeaderMenuKeys}>
      <Menu.Item key="workspaces">
        <Link to="/workspaces">
          {t('headerMenu.workspaces')}
        </Link>
      </Menu.Item>
      {isAdmin && (
        <Menu.Item key="quotas">
          <Link to="/quotas">
            {t('headerMenu.quotas')}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  ), [selectedHeaderMenuKeys, isAdmin]);

  const avatarMenu = React.useMemo(() => (
    <Menu>
      {quota != null && (
        <Menu.Item key="quota" style={{ color: quotaPercent >= 100 ? 'red' : null }}>
          {t('rightMenu.quota', { quota: quotaPercent.toFixed(2) })}
        </Menu.Item>
      )}
      <Menu.Item key="editProfile">
        <a href={config.edit_profile_uri}>
          {t('rightMenu.editProfile')}
        </a>
      </Menu.Item>
      <Menu.Item key="info">
        <a href="https://noise-studio.senseca.com/desktop-modules/Documentation_ITA/wiki_cloud/index.php" target="_blank" rel="noreferrer">
          Info
        </a>
      </Menu.Item>
      <Menu.Item key="logout" onClick={logout}>
        {t('rightMenu.logout')}
      </Menu.Item>
    </Menu>

  ), [quota]);

  return (
    <TopbarLayout
      headerMenu={headerMenu}
      headerRight={(
        <Dropdown overlay={avatarMenu} placement="bottomCenter">
          <Avatar>{initials}</Avatar>
        </Dropdown>
      )}
    >
      <Routes>
        <Route path="workspaces" element={<Outlet />}>
          <Route path=":workspaceId" element={<Outlet />}>
            <Route path="soundSessions" element={<Outlet />}>
              <Route path=":soundSessionId" element={<Outlet />}>
                <Route path="SoundGlobalMeasure/:soundMeasureId" element={<SoundGlobalMeasureTablePage />} />
                <Route path="SoundTimeSingleMeasure/:soundMeasureId" element={<SoundTimeMeasureChartPage type="single" />} />
                <Route path="SoundTimeOctaveMeasure/:soundMeasureId" element={<SoundTimeMeasureChartPage type="octave" />} />
                <Route path="SoundTimeThirdOctaveMeasure/:soundMeasureId" element={<SoundTimeMeasureChartPage type="thirdOctave" />} />
                <Route path="SoundTimeStatisticsMeasure/:soundMeasureId" element={<SoundTimeMeasureChartPage type="statistics" />} />
                <Route path="SoundTimeFFTMeasure/:soundMeasureId" element={<SoundTimeMeasureChartPage type="FFT" />} />

                <Route path="SoundSampleSingleMeasure/:soundMeasureId" element={<SoundSampleMeasureChartPage type="single" />} />
                <Route path="SoundSampleOctaveMeasure/:soundMeasureId" element={<SoundSampleMeasureChartPage type="octave" />} />
                <Route path="SoundSampleThirdOctaveMeasure/:soundMeasureId" element={<SoundSampleMeasureChartPage type="thirdOctave" />} />

                <Route path="SoundEventSingleMeasure/:soundMeasureId" element={<SoundEventMeasureTablePage type="single" />} />
                <Route path="SoundEventThirdOctaveMeasure/:soundMeasureId" element={<SoundEventMeasureTablePage type="thirdOctave" />} />
                <Route path="SoundEventStatisticsMeasure/:soundMeasureId" element={<SoundEventMeasureTablePage type="statistics" />} />

                <Route path="" element={<SoundSessionDetailPage />} />
              </Route>
            </Route>
            <Route path="vibrationSessions" element={<Outlet />}>
              <Route path=":vibrationSessionId" element={<Outlet />}>
                <Route path="VibrationTimeSingleMeasure/:vibrationMeasureId" element={<VibrationTimeMeasureChartPage type="single" />} />
                <Route path="VibrationTimeOctaveMeasure/:vibrationMeasureId" element={<VibrationTimeMeasureChartPage type="octave" />} />
                <Route path="VibrationTimeThirdOctaveMeasure/:vibrationMeasureId" element={<VibrationTimeMeasureChartPage type="thirdOctave" />} />
                <Route path="VibrationTimeStatisticsMeasure/:vibrationMeasureId" element={<VibrationTimeMeasureChartPage type="statistics" />} />
                <Route path="" element={<VibrationSessionDetailPage />} />
              </Route>
            </Route>
            <Route path="" element={<WorkspaceDetailPage />} />
          </Route>
          <Route path="" element={<WorkspaceListPage />} />
        </Route>
        {isAdmin && (
          <Route path="quotas" element={<Outlet />}>
            <Route path="" element={<QuotaListPage />} />
          </Route>
        )}
      </Routes>
    </TopbarLayout>
  );
};

export default AuthenticatedLayout;
