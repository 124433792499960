/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Space,
} from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  PageSection,
  messageError,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { QUOTAS_QUERY } from './queries';
import { extractGraphQLErrors } from '../utils/graphql';

import { ErrorList } from '../generics';
import {
  QuotaFilter,
  QuotaUpdateModal,
} from './components';

const QuotaListPage = () => {
  const { t } = useTranslation();

  const [searchObject, setSearchObject] = React.useState({
    search: '',
  });

  const [quotaUpdateId, setQuotaUpdateId] = React.useState(null);

  const {
    error,
    loading,
    data,
    fetchMore,
    refetch,
  } = useQuery(QUOTAS_QUERY, {
    variables: {
      pageSize: 50,
      filter: searchObject,
    },
  });

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { quotas: quotasConnection } = viewer;
      const { pageInfo, edges } = quotasConnection;
      return {
        quotas: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      quotas: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: queryResult.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.quotas;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.quotas;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              quotas: {
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  const handleSearch = React.useCallback((newSearchObject) => {
    setSearchObject((old) => ({
      ...old,
      ...newSearchObject,
    }));
  }, [setSearchObject]);

  const columns = React.useMemo(() => ([
    {
      title: t('quotas.list.table.user'),
      dataIndex: 'user',
    },
    {
      title: t('quotas.list.table.quota'),
      dataIndex: 'quota',
      render: (quota) => (quota / (8 * 60 * 60 * 24 * 30)).toFixed(2),
    },
    {
      title: t('quotas.list.table.unlimited'),
      dataIndex: 'quota',
      render: (quota) => (quota == null ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: t('common.actions'),
      dataIndex: 'id',
      render: (id) => {
        const result = (
          <Space size="small">
            <Tooltip title={t('common.udpate')}>
              <Button shape="circle" type="primary" onClick={() => setQuotaUpdateId(id)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
        return result;
      },
    },
  ]), [t]);

  const tableFooter = React.useMemo(() => {
    if (queryResult.pageInfo && queryResult.pageInfo.hasNextPage) {
      return () => (
        <Button type="primary" block onClick={handleLoadMore}>
          {t('common.loadMore')}
        </Button>
      );
    }
    return null;
  }, [queryResult, handleLoadMore]);

  return (
    <>
      <PageSection
        title={t('quotas.list.title')}
        subtitle={t('quotas.list.subtitle')}
      >
        <Row>
          <Col xs={24}>
            <QuotaFilter
              searchObject={searchObject}
              onSearch={handleSearch}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              size="small"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={queryResult.quotas}
              loading={loading}
              pagination={false}
              footer={tableFooter}
            />
          </Col>
        </Row>
      </PageSection>
      <QuotaUpdateModal
        id={quotaUpdateId}
        onClose={() => {
          setQuotaUpdateId(null);
          refetch({
            variables: searchObject,
          });
        }}
      />
    </>
  );
};

export default QuotaListPage;
