/* eslint-disable import/prefer-default-export */
export const pascalToDecibel = (pa, precision) => {
  if (pa == null) {
    return null;
  }
  const dB = 20 * Math.log10(pa / 0.00002);
  const p = 100 ** precision;
  return Math.round((dB + Number.EPSILON) * p) / p;
};

export const ms2ToDecibel = (ms2, precision) => {
  if (ms2 == null) {
    return null;
  }
  const dB = 20 * Math.log10(ms2 / (10 ** 6));
  const p = 100 * precision;
  return Math.round((dB + Number.EPSILON) * p) / p;
};

export const ms2ToCms2 = (ms2, precision) => {
  const cms2 = ms2 * 100;
  const p = 100 * precision;
  return Math.round((cms2 + Number.EPSILON) * p) / p;
};

export const ms2ToMms2 = (ms2, precision) => {
  const mms2 = ms2 * 1000;
  const p = 100 * precision;
  return Math.round((mms2 + Number.EPSILON) * p) / p;
};

export const ms2ToIns2 = (ms2, precision) => {
  const ins2 = ms2 * 39.370079;
  const p = 100 * precision;
  return Math.round((ins2 + Number.EPSILON) * p) / p;
};

export const ms2ToFts2 = (ms2, precision) => {
  const fts2 = ms2 * 3.28084;
  const p = 100 * precision;
  return Math.round((fts2 + Number.EPSILON) * p) / p;
};

export const ms2ToG = (ms2, precision) => {
  const g = ms2 / 9.80665;
  const p = 100 * precision;
  return Math.round((g + Number.EPSILON) * p) / p;
};
