import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ComponentLoader, messageError, PageSection } from '@deltaohm/ant-components';
import { useSoundSampleMeasureDetail } from './hooks';
import { extractGraphQLErrors } from '../utils/graphql';
import { ErrorList } from '../generics';
import { SoundMeasureChartContainer, SoundValueFilterModal } from './components/samples';
import { formatLocaleWithTimezone, getLocale } from '../utils/date';

const SoundSampleMeasureChartPage = (props) => {
  const {
    type,
  } = props;
  const { workspaceId, soundSessionId, soundMeasureId } = useParams();

  const { t, i18n } = useTranslation();

  const locale = getLocale(i18n.language);

  const {
    workspace,
    soundSession,
    soundMeasure,
    detailLoading,
    previewLoading,
    previewDecoding,
    error,
    preview,
    setFrom,
  } = useSoundSampleMeasureDetail(workspaceId, soundSessionId, soundMeasureId);

  const [requestSample, setRequestSample] = React.useState(null);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [autoFilter, setAutoFilter] = React.useState(null);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const breadcrumb = React.useMemo(() => {
    const result = [
      {
        path: '/workspaces',
        breadcrumbName: 'Workspaces',
      },
    ];
    if (workspace) {
      result.push({
        path: `/workspaces/${workspaceId}`,
        breadcrumbName: workspace.name,
      });
      if (soundSession) {
        result.push({
          path: `/workspaces/${workspaceId}/soundSessions/${soundSessionId}`,
          breadcrumbName: soundSession.name,
        });
        if (soundMeasure) {
          result.push({
            path: '',
            breadcrumbName: soundMeasure.name,
          });
        }
      }
    }
    return result;
  }, [workspace, soundSession, soundMeasure]);

  const availableFrom = React.useMemo(() => {
    if (soundMeasure) {
      return soundMeasure.availableWindow[0];
    }
    return null;
  }, [soundMeasure]);

  const availableTo = React.useMemo(() => {
    if (soundMeasure) {
      return soundMeasure.availableWindow[1];
    }
    return null;
  }, [soundMeasure]);

  React.useEffect(() => {
    if (!requestSample && availableFrom != null && availableTo != null) {
      setRequestSample({
        from: availableFrom,
        to: availableTo,
      });
    }
  }, [requestSample, setRequestSample, availableFrom, availableTo]);

  const handleFilter = (data) => {
    setRequestSample(data);
    setIsFilterOpen(false);
  };

  const handleFromChange = (data) => {
    setFrom(data);
  };

  const handleRequestFilter = (direction) => {
    if (direction === 'next') {
      setAutoFilter('next');
      setFrom(availableTo);
    }
    else if (direction === 'restart') {
      setAutoFilter('restart');
      setFrom(soundMeasure.minSample);
    }
    else {
      setAutoFilter(null);
      setIsFilterOpen(true);
    }
  };

  React.useEffect(() => {
    if (autoFilter) {
      setRequestSample({
        from: availableFrom,
        to: availableTo,
      });
    }
  }, [autoFilter, availableFrom, availableTo]);

  const subtitle = React.useMemo(() => {
    if (soundMeasure && soundSession) {
      const from = soundMeasure.minSample;
      const to = soundMeasure.maxSample;
      return t('soundSampleMeasures.chart.subtitle', { from, to, totalValues: soundMeasure.totalValues });
    }
    return '';
  }, [t, soundSession, soundMeasure]);

  const subsubtitle = React.useMemo(() => {
    if (soundMeasure && soundSession) {
      const date = formatLocaleWithTimezone(soundSession.recordDate, 'yyyy/MM/dd HH:mm:ss:SSS', locale, soundSession.timezone, false);
      return t('soundSampleMeasures.chart.subsubtitle', { date });
    }
    return '';
  }, [t, soundSession, soundMeasure]);

  return (
    <ComponentLoader
      message={t('common.loading')}
      loading={detailLoading || previewLoading || previewDecoding}
    >
      <PageSection
        title={soundMeasure ? soundMeasure.name : ''}
        breadcrumb={breadcrumb}
        subtitle={subtitle}
      >
        <Divider>
          { subsubtitle }
        </Divider>
        {!error && soundSession && !previewLoading && !previewDecoding && preview && (
          <SoundValueFilterModal
            timezone={soundSession.timezone}
            preview={preview}
            availableFrom={availableFrom}
            availableTo={availableTo}
            onFromChange={handleFromChange}
            onFormSubmit={handleFilter}
            isModalOpen={isFilterOpen}
            onClose={() => setIsFilterOpen(false)}
          />
        )}
        {soundMeasure && requestSample && (
          <SoundMeasureChartContainer
            from={requestSample.from}
            to={requestSample.to}
            workspaceId={workspaceId}
            soundSessionId={soundSessionId}
            soundMeasureId={soundMeasureId}
            onRequestFilter={handleRequestFilter}
            type={type}
          />
        )}
      </PageSection>
    </ComponentLoader>
  );
};

const propTypes = {
  type: PropTypes.oneOf(['single', 'octave', 'thirdOctave']).isRequired,
};

SoundSampleMeasureChartPage.propTypes = propTypes;

export default SoundSampleMeasureChartPage;
