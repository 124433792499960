import { gql } from '@apollo/client';

export const QUOTA_LIST_ITEM_FRAGMENT = gql`
fragment QuotaListItemFragment on Quota {
  id
  user
  quota
}
`;

export const QUOTA_DETAIL_FRAGMENT = gql`
fragment QuotaDetailFragment on Quota {
  id
  user
  quota
},
`;

export const QUOTAS_QUERY = gql`
query quotasQuery($afterCursor: String, $pageSize: Int!, $filter: QuotaFilter) {
  viewer {
    quotas(first: $pageSize, after: $afterCursor, filter: $filter) {
      edges {
        node {
          ...QuotaListItemFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
}
${QUOTA_LIST_ITEM_FRAGMENT}
`;

export const QUOTA_QUERY = gql`
query quotaQuery($id: ID!) {
  viewer {
    quota(id: $id) {
      ...QuotaDetailFragment
    }
  }
}
${QUOTA_DETAIL_FRAGMENT}
`;

export const QUOTA_UPDATE_MUTATION = gql`
  mutation QuotaUpdateMutation($input:QuotaUpdateInput!) {
    quotaUpdate(input: $input) {
      quota {
        ...QuotaDetailFragment
      }
    }
  }
  ${QUOTA_DETAIL_FRAGMENT}
`;
