/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Collapse,
  Table,
  Col,
  Row,
} from 'antd';

import {
  PageSection,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import ReactAudioPlayer from 'react-audio-player';
import { formatLocaleWithTimezone, getLocale } from '../../../utils/date';

const SerieAttachmentList = (props) => {
  const {
    timeSerie,
    timezone,
  } = props;

  const { t, i18n } = useTranslation('translations');
  const locale = getLocale(i18n.language);

  const attachments = React.useMemo(() => {
    const result = timeSerie.values.map((v) => {
      const augmented = v.attachments.map((a) => ({
        from: v.from,
        to: v.to,
        ...a,
      }));
      return augmented;
    }).flat();
    return result;
  }, [timeSerie]);

  const metadata = React.useMemo(() => {
    const result = attachments.map((attachment) => {
      const rows = Object.keys(attachment.metadata).map((key) => {
        let value = attachment.metadata[key];
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        return {
          parameter: key,
          value,
        };
      });
      rows.unshift(({
        parameter: 'to',
        value: formatLocaleWithTimezone(attachment.to, 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone),
      }));
      rows.unshift(({
        parameter: 'from',
        value: formatLocaleWithTimezone(attachment.from, 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone),
      }));
      return rows;
    });
    return result;
  }, [attachments]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('common.parameter'),
        dataIndex: 'parameter',
        width: '50%',
      },
      {
        title: t('common.value'),
        dataIndex: 'value',
      },
    ];
    return result;
  }, [t]);

  return (
    <PageSection
      title={t('soundMeasures.components.serieAttachmentList.title')}
    >
      <Row>
        <Col xs={24}>
          <Collapse>
            {attachments.map((attachment, index) => (
              <Collapse.Panel header={attachment.name} key={index.toString()}>
                <ReactAudioPlayer
                  style={{ width: '100%' }}
                  src={attachment.url}
                  controls
                />
                <Table
                  bordered
                  size="small"
                  scroll={{ x: true }}
                  columns={columns}
                  rowKey={(record) => record.parameter}
                  dataSource={metadata[index]}
                  pagination={false}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  timeSerie: PropTypes.any.isRequired,
  timezone: PropTypes.string.isRequired,
};

SerieAttachmentList.propTypes = propTypes;

export default SerieAttachmentList;
