import React from 'react';
import PropTypes from 'prop-types';
import { ComponentLoader, messageError } from '@deltaohm/ant-components';
import { t } from 'i18next';

import {
  SingleMeasureChart,
  OctaveMeasureChart,
} from '../../charts/samples';

import { useSoundSampleMeasureValues } from '../../hooks';
import { extractGraphQLErrors } from '../../../utils/graphql';
import { ErrorList } from '../../../generics';

const SoundMeasureChartContainer = (props) => {
  const {
    from,
    to,
    workspaceId,
    soundSessionId,
    soundMeasureId,
    onRequestFilter,
    type,
  } = props;

  const {
    loading,
    error,
    decoding,
    soundSession,
    soundMeasure,
    changeSamples,
  } = useSoundSampleMeasureValues(workspaceId, soundSessionId, soundMeasureId, from, to);

  React.useEffect(() => {
    changeSamples(from, to);
  }, [from, to, changeSamples]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  return (
    <ComponentLoader
      loading={loading || decoding}
      tip={loading ? t('common.loading') : t('common.decoding')}
    >
      {soundMeasure && type === 'single' && (
        <SingleMeasureChart
          workspaceId={workspaceId}
          soundSession={soundSession}
          measure={soundMeasure}
          onRequestFilter={onRequestFilter}
        />
      )}
      {soundMeasure && type === 'octave' && (
        <OctaveMeasureChart
          workspaceId={workspaceId}
          soundSession={soundSession}
          measure={soundMeasure}
          onRequestFilter={onRequestFilter}
        />
      )}
      {soundMeasure && type === 'thirdOctave' && (
        <OctaveMeasureChart
          workspaceId={workspaceId}
          soundSession={soundSession}
          measure={soundMeasure}
          onRequestFilter={onRequestFilter}
        />
      )}
    </ComponentLoader>
  );
};

const propTypes = {
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  workspaceId: PropTypes.string.isRequired,
  soundSessionId: PropTypes.string.isRequired,
  soundMeasureId: PropTypes.string.isRequired,
  onRequestFilter: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['single', 'octave', 'thirdOctave']).isRequired,
};

SoundMeasureChartContainer.propTypes = propTypes;

export default SoundMeasureChartContainer;
