import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
} from 'antd';

import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';

import {
  VIBRATION_SESSION_UPDATE_MUTATION,
} from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

import {
  VibrationSessionForm,
} from '../components';

const VibrationSessionPropertyTab = (props) => {
  const {
    workspaceId,
    vibrationSession,
  } = props;

  const { t } = useTranslation();

  const [
    vibrationSessionUpdateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(VIBRATION_SESSION_UPDATE_MUTATION);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const handleFormSubmit = async (data) => {
    try {
      await vibrationSessionUpdateMutationAction({
        variables: {
          input: {
            workspaceId,
            id: vibrationSession.id,
            ...data,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('vibrationSessions.propertyTab.title')}
    >
      <Row>
        <Col xs={24}>
          <ComponentLoader
            message={t('common.loading')}
            loading={loading}
          >
            <VibrationSessionForm
              vibrationSession={vibrationSession}
              onFormSubmit={handleFormSubmit}
            />
          </ComponentLoader>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  vibrationSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    reference: PropTypes.string,
    note: PropTypes.string,
    source: PropTypes.string.isRequired,
  }).isRequired,
};

VibrationSessionPropertyTab.propTypes = propTypes;

export default VibrationSessionPropertyTab;
