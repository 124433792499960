/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useQuery } from '@apollo/client';
import { decodeAsync } from '@msgpack/msgpack';

import {
  VIBRATION_SESSION_VIBRATION_TIME_MEASURES_PREVIEW_QUERY,
  WORKSPACE_VIBRATION_SESSION_QUERY,
} from './queries';

export const useVibrationSessionTimePreview = (workspaceId, vibrationSessionId) => {
  const [decoding, setDecoding] = React.useState(false);
  const [values, setValues] = React.useState(null);

  const {
    error,
    loading,
    data,
  } = useQuery(VIBRATION_SESSION_VIBRATION_TIME_MEASURES_PREVIEW_QUERY, {
    variables: {
      workspaceId,
      id: vibrationSessionId,
    },
  });

  const queryData = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace.vibrationSession.timeMeasurePreview;
    }
    return null;
  }, [loading, error, data]);

  React.useEffect(() => {
    if (queryData) {
      const decode = async () => {
        setDecoding(true);
        const base64Response = await fetch(`data:application/msgpack;base64,${queryData.values}`);
        const decoded = await decodeAsync((await base64Response.blob()).stream());
        setValues(decoded);
        setDecoding(false);
      };
      if (!decoding && !values) {
        decode();
      }
    }
  }, [queryData, error, data, decoding]);

  return {
    preview: queryData && values
      ? {
        ...queryData,
        values,
      } : null,
    loading,
    error,
  };
};

export const useVibrationSession = (workspaceId, vibrationSessionId) => {
  const {
    error,
    loading,
    data,
  } = useQuery(WORKSPACE_VIBRATION_SESSION_QUERY, {
    variables: {
      id: vibrationSessionId,
      workspaceId,
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [error, data, loading]);

  const vibrationSession = React.useMemo(() => {
    if (!loading && !error) {
      return workspace.vibrationSession;
    }
    return null;
  }, [workspace]);

  return {
    error,
    loading,
    workspace,
    vibrationSession,
  };
};
