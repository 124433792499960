/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  settingIcon,
  downloadImageIcon,
  dbIcon,
  paIcon,
  showGridIcon,
  hideGridIcon,
  showPointsIcon,
  hidePointsIcon,
  infoIcon,
  tableIcon,
  searchIcon,
  timeSerieIcon,
  excelIcon,
  nextIcon,
  restartIcon,
  exportExcel,

  DB_DECIMAL,
  PA_DECIMAL,
} from '../utils';

import SingleMeasureChartConfigModal from './SingleMeasureChartConfigModal';
import SerieAttachmentList from './SerieAttachmentList';
import { SingleMeasureTableModal } from '../../tables/times';
import { SoundMeasureConfigModal } from '../../components';

import { pascalToDecibel } from '../../../utils/converter';
import { formatLocaleWithTimezone, getLocale } from '../../../utils/date';

const defaultColors = [
  '#657b36',
  '#af5ac5',
  '#5cb650',
  '#cd4183',
  '#a6b345',
  '#6c71c7',
  '#cc9a45',
  '#60a6d8',
  '#be6634',
  '#4cb08d',
  '#cc5252',
  '#c475a3',
];

const MAX_TIMESERIES = 4;

const timeSerieColors = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FF00FF',
];

const SingleMeasureChart = (props) => {
  const {
    workspaceId,
    soundSession,
    measure,
    timeSeries,
    onRequestFilter,
  } = props;

  const { timezone } = soundSession;

  const { t, i18n } = useTranslation('translations');
  const locale = getLocale(i18n.language);

  const [currentZoomIndexes, setCurrentZoomIndexes] = React.useState([0, measure.values.length - 1]);
  const [selectedTimeSerie, setSelectedTimeSerie] = React.useState(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [isTableModalOpen, setIsTableModalOpen] = React.useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = React.useState(false);

  const chartRef = React.useRef(null);
  const [chartInstance, setChartInstance] = React.useState(null);

  const defaultMeasureColors = React.useMemo(() => {
    const measureIds = measure.config.map((c) => c.measureId);
    const result = measureIds.reduce((acc, item, index) => {
      acc[item] = defaultColors[index % defaultColors.length];
      return acc;
    }, {});
    return result;
  }, [measure]);

  const firstTimeSeriesNames = React.useMemo(() => {
    const result = [];
    for (let i = 0; i < MAX_TIMESERIES && i < timeSeries.length; i += 1) {
      result.push(timeSeries[i].name);
    }
    return result;
  }, [timeSeries]);

  const [currentConfig, setCurrentConfig] = React.useState({
    colors: defaultMeasureColors,
    showDB: true,
    showGrid: true,
    showPoints: false,
    timeSeriesNames: firstTimeSeriesNames,
  });

  const minMax = React.useMemo(() => {
    const { config, values } = measure;
    const measureIds = config.map((c) => c.measureId);
    let min;
    let max;
    values.forEach((value) => {
      measureIds.forEach((id) => {
        if (min == null || value.data[id] < min) {
          min = value.data[id];
        }
        if (max == null || value.data[id] > max) {
          max = value.data[id];
        }
      });
    });

    const dbMin = pascalToDecibel(min, DB_DECIMAL);
    const dbMax = pascalToDecibel(max, DB_DECIMAL);

    const result = {
      pascal: [min, max],
      db: [dbMin, dbMax],
    };

    return result;
  }, [measure]);

  const dataset = React.useMemo(() => {
    const { values } = measure;

    const dataSource = values.map((v) => ({
      timestamp: utcToZonedTime(v.timestamp, timezone).getTime(),
      ...v.data,
      timeSeries: null,
    }));

    const result = [
      {
        dimensions: ['timestamp', ...measure.config.map((c) => c.measureId), 'timeSeries'],
        source: dataSource,
      },
      {
        transform: {
          type: 'deltaohm:PAtoDBTransform',
          config: { measureConfig: measure.config },
        },
      },
    ];
    return result;
  }, [measure, timeSeries]);

  const singleTimestamp = React.useMemo(() => {
    if (dataset[0].source.length === 1) {
      return dataset[0].source;
    }
    return null;
  }, [dataset]);

  const handleConfigSubmit = (newConfig) => {
    setCurrentConfig((old) => ({ ...old, ...newConfig }));
  };

  const handleInfoModalToggle = (open) => {
    setIsInfoModalOpen(open);
  };

  const handleTableModalToggle = (open) => {
    setIsTableModalOpen(open);
  };

  const handleConfigModalToggle = (open) => {
    setIsConfigModalOpen(open);
  };

  const handleDownloadExcel = () => {
    const [from, to] = currentZoomIndexes;
    const { config, values } = measure;
    const currentValues = values.slice(from, to + 1);

    const header = ['timestamp', 'date', ...config.map((c) => c.measureId)];
    let data;
    if (currentConfig.showDB) {
      data = currentValues.map((cv) => {
        const result = {
          timestamp: cv.timestamp,
          date: formatLocaleWithTimezone(cv.timestamp, 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone),
          ...cv.data,
        };
        for (let i = 2; i < header.length; i += 1) {
          if (result[header[i]] != null) {
            result[header[i]] = pascalToDecibel(result[header[i]], DB_DECIMAL);
          }
        }
        return result;
      });
    }
    else {
      data = currentValues.map((cv) => ({ timestamp: cv.timestamp, ...cv.data }));
    }
    exportExcel(
      `${soundSession.name}_${soundSession.timezone}.xlsx`,
      'TimeHistory',
      data,
      header,
    );
  };

  const getLegendOption = () => ({
    id: 'legend',
    top: '7%',
    right: '5%',
    type: 'scroll',
    orient: 'horizontal',
    scrollable: 'scroll',
    data: measure.config.map((conf) => conf.label),
    selected: measure.config.reduce((acc, conf, index) => {
      acc[conf.label] = index < 4;
      return acc;
    }, {}),
  });

  const getToolboxOption = () => {
    let timeSerieColorIndex = 0;
    const result = [
      {
        id: 'right_toolbox',
        show: true,
        right: '5%',
        feature: {
          myRestartTool: {
            show: true,
            title: t('common.restart'),
            icon: restartIcon,
            onclick: () => {
              onRequestFilter('restart');
            },
          },
          myNextTool: {
            show: true,
            title: t('common.next'),
            icon: nextIcon,
            onclick: () => {
              onRequestFilter('next');
            },
          },
          mySearchTool: {
            show: true,
            title: t('common.filter'),
            icon: searchIcon,
            onclick: () => {
              onRequestFilter();
            },
          },
          saveAsImage: {
            excludeComponents: ['toolbox', 'dataZoom'],
            icon: downloadImageIcon,
            title: t('soundMeasures.components.charts.saveImage'),
          },
          myInfoTool: {
            show: true,
            title: t('common.info'),
            icon: infoIcon,
            onclick: () => {
              handleInfoModalToggle(true);
            },
          },
          myTableTool: {
            show: true,
            title: t('soundMeasures.components.charts.table'),
            icon: tableIcon,
            onclick: () => {
              handleTableModalToggle(true);
            },
          },
          myExcelTool: {
            show: true,
            title: t('soundMeasures.components.charts.excel'),
            icon: excelIcon,
            onclick: () => {
              handleDownloadExcel();
            },
          },
          mySettingTool: {
            show: true,
            title: t('soundMeasures.components.charts.settings'),
            icon: settingIcon,
            onclick: () => {
              handleConfigModalToggle(true);
            },
          },
          myPaDbTool: {
            show: true,
            title: currentConfig.showDB ? t('soundMeasures.components.charts.showPa') : t('soundMeasures.components.charts.showDB'),
            icon: currentConfig.showDB ? paIcon : dbIcon,
            onclick: () => {
              setCurrentConfig((old) => ({ ...old, showDB: !old.showDB }));
            },
          },
          myShowPointsTool: {
            show: true,
            title: currentConfig.showPoints ? t('soundMeasures.components.charts.hidePoints') : t('soundMeasures.components.charts.showPoints'),
            icon: currentConfig.showPoints ? hidePointsIcon : showPointsIcon,
            onclick: () => {
              setCurrentConfig((old) => ({ ...old, showPoints: !old.showPoints }));
            },
          },
          myShowGridTool: {
            show: true,
            title: currentConfig.showGrid ? t('soundMeasures.components.charts.hideGrid') : t('soundMeasures.components.charts.showGrid'),
            icon: currentConfig.showGrid ? hideGridIcon : showGridIcon,
            onclick: () => {
              setCurrentConfig((old) => ({ ...old, showGrid: !old.showGrid }));
            },
          },
        },
      },
      {
        id: 'bottom_toolbox',
        show: true,
        left: '10%',
        bottom: '7%',
        feature: timeSeries.reduce((acc, timeSerie) => {
          acc[`myTimeSerie${timeSerie.name}Tool`] = {
            show: true,
            icon: timeSerieIcon,
            title: timeSerie.name,
            iconStyle: {
              color: currentConfig.timeSeriesNames.includes(timeSerie.name) ? timeSerieColors[timeSerieColorIndex % MAX_TIMESERIES] : null,
              shadowBlur: timeSerie.values.some((d) => d.attachments.length) ? 5 : 0,
              shadowColor: currentConfig.timeSeriesNames.includes(timeSerie.name) ? timeSerieColors[timeSerieColorIndex % MAX_TIMESERIES] : null,
            },
            onclick: () => {
              setCurrentConfig((old) => {
                if (old.timeSeriesNames.includes(timeSerie.name)) {
                  const newNames = old.timeSeriesNames.filter((ts) => ts !== timeSerie.name);
                  return { ...old, timeSeriesNames: newNames };
                }
                if (old.timeSeriesNames.length >= MAX_TIMESERIES) {
                  const newNames = [...old.timeSeriesNames.slice(1)];
                  newNames.push(timeSerie.name);
                  return { ...old, timeSeriesNames: newNames };
                }
                const newNames = [...old.timeSeriesNames];
                newNames.push(timeSerie.name);
                return { ...old, timeSeriesNames: newNames };
              });
            },
          };
          if (currentConfig.timeSeriesNames.includes(timeSerie.name)) {
            timeSerieColorIndex += 1;
          }
          return acc;
        }, {}),
      },
    ];
    return result;
  };

  const getXAxisOption = () => ([
    {
      id: 'x_data',
      type: 'time',
      name: t('common.time'),
      axisLine: {
        show: true,
      },
      axisTick: {
        alignWithLabel: true,
        show: true,
      },
      splitLine: {
        alignWithLabel: true,
        show: currentConfig.showGrid,
      },
      gridIndex: 0,
    },
  ]);

  const getYAxisOption = () => ([
    {
      id: 'y_data',
      type: 'value',
      name: currentConfig.showDB ? t('common.db') : t('common.pa'),
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        show: currentConfig.showGrid,
      },
      min: () => {
        let diff;
        let min;
        if (currentConfig.showDB) {
          min = minMax.db[0];
          diff = minMax.db[1] - minMax.db[0];
        }
        else {
          min = minMax.pascal[0];
          diff = minMax.pascal[1] - minMax.pascal[0];
        }
        const air = diff * 0.05;
        return min - air;
      },
      max: () => {
        let diff;
        let max;
        if (currentConfig.showDB) {
          max = minMax.db[1];
          diff = minMax.db[1] - minMax.db[0];
        }
        else {
          max = minMax.pascal[1];
          diff = minMax.pascal[1] - minMax.pascal[0];
        }
        const air = diff * 0.05;
        return max + air;
      },
      axisLabel: {
        formatter: (value) => (currentConfig.showDB ? value.toFixed(DB_DECIMAL) : value.toFixed(PA_DECIMAL)),
      },
      gridIndex: 0,
    },
  ]);

  const getSeriesOption = () => {
    const timeSerieSizePercent = 3;
    const displayableTimeSeries = timeSeries.filter((ts) => currentConfig.timeSeriesNames.includes(ts.name));

    const markAreaData = displayableTimeSeries.reduce((acc, serie, index) => {
      const currentStart = index * timeSerieSizePercent;
      const currentStop = (currentStart + timeSerieSizePercent) - 1;
      const hasAttachment = serie.values.some((v) => v.attachments.length > 0);
      serie.values.forEach((val) => {
        acc.push([
          {
            name: serie.name,
            xAxis: utcToZonedTime(val.from, timezone).getTime(),
            y: `${currentStart + 55 + 17}%`,
            itemStyle: {
              color: timeSerieColors[index],
              shadowBlur: hasAttachment ? 10 : 0,
            },
          },
          {
            xAxis: utcToZonedTime(val.to, timezone).getTime(),
            y: `${currentStop + 55 + 17}%`,
          },
        ]);
      });
      return acc;
    }, []);

    const { config } = measure;
    const result = [];
    for (let i = 0; i < config.length; i += 1) {
      const conf = config[i];
      const name = conf.label;
      const thisSerie = {
        id: conf.measureId,
        step: 'start',
        name,
        type: 'line',
        sampling: 'lttb',
        symbolSize: 1,
        showSymbol: currentConfig.showPoints,
        lineStyle: {
          color: currentConfig.colors[conf.measureId],
          width: 1,
        },
        itemStyle: {
          color: currentConfig.colors[conf.measureId],
        },
        datasetIndex: currentConfig.showDB ? 1 : 0,
        yAxisIndex: 0,
        xAxisIndex: 0,
        encode: {
          x: 'timestamp',
          y: conf.measureId,
        },
      };
      result.push(thisSerie);
    }

    result.push({
      id: 'time_series',
      name: 'timeSeries',
      type: 'line',
      yAxisIndex: 0,
      xAxisIndex: 0,
      tooltip: {
        show: false,
      },
      encode: {
        x: 'timestamp',
        y: 'timeSeries',
      },
      markArea: {
        label: {
          show: false,
        },
        data: [
          ...markAreaData,
        ],
      },
    });

    if (selectedTimeSerie) {
      const filtered = timeSeries.find((s) => s.name === selectedTimeSerie);
      const selectedSerieMarkAreaData = [];
      const selectedSerieMarkLineData = [];
      filtered.values.forEach((v) => {
        selectedSerieMarkAreaData.push([
          {
            name: selectedTimeSerie,
            xAxis: utcToZonedTime(v.from, timezone).getTime(),
          },
          {
            xAxis: utcToZonedTime(v.to, timezone).getTime(),
          },
        ]);
        selectedSerieMarkLineData.push({ xAxis: utcToZonedTime(v.from, timezone).getTime() });
        selectedSerieMarkLineData.push({ xAxis: utcToZonedTime(v.to, timezone).getTime() });
      });

      result.push({
        id: 'selected_time_series',
        type: 'line',
        yAxisIndex: 0,
        xAxisIndex: 0,
        encode: {
          x: 'timestamp',
          y: 'timeSeries',
        },
        tooltip: {
          show: false,
        },
        markArea: {
          silent: true,
          data: selectedSerieMarkAreaData,
        },
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          label: { show: false },
          data: selectedSerieMarkLineData,
        },
      });
    }

    return result;
  };

  const getTooltipOption = () => {
    const result = {
      id: 'tooltip',
      trigger: 'axis',
      triggerOn: 'click',
      axisPointer: {
        type: 'line',
        snap: true,
      },
      formatter: (params) => {
        let timestamp;
        const series = [];
        for (let i = 0; i < params.length; i += 1) {
          const current = params[i];
          timestamp = current.value.timestamp;
          const value = current.value[current.seriesId];
          if (value != null) {
            const realValue = currentConfig.showDB ? value.toFixed(DB_DECIMAL) : value.toFixed(PA_DECIMAL);
            const unitOfMeasure = currentConfig.showDB ? t('common.db') : t('common.pa');
            series.push(`${current.marker} ${current.seriesName}: ${realValue} ${unitOfMeasure}`);
          }
        }
        const tooltip = `${format(timestamp, 'yyyy-MM-dd HH:mm:ss.SSS')}\n${series.join('\n')}`;
        return tooltip;
      },
      showContent: true,
      renderMode: 'richText',
    };

    return result;
  };

  const getInitialChartOption = () => {
    const result = {
      animation: false,
      title: {
        id: 'title',
        text: measure.name,
      },
      grid: {
        id: 'graph_grid',
        show: true,
        top: '17%',
        bottom: '35%',
        right: '12%',
      },
      tooltip: getTooltipOption(),
      dataZoom: [
        {
          id: 'x_zoom_slider',
          type: 'slider',
          show: true,
          xAxisIndex: [0],
          start: 0,
          end: 100,
          height: '3%',
          bottom: '3%',
          // non si può mettere insieme il filterMode a none ed il sampling
        },
        {
          id: 'y_zoom_slider',
          type: 'slider',
          show: true,
          yAxisIndex: [0],
          right: '3%',
          start: 0,
          end: 100,
          width: '3%',
          filterMode: 'none',
        },
        {
          id: 'x_zoom_inside',
          type: 'inside',
          xAxisIndex: [0],
          start: 0,
          end: 100,
          // non si può mettere insieme il filterMode a none ed il sampling
        },
      ],
      dataset,
      legend: getLegendOption(),
      toolbox: getToolboxOption(),
      xAxis: getXAxisOption(),
      yAxis: getYAxisOption(),
      series: getSeriesOption(),
    };

    return result;
  };

  React.useEffect(() => {
    if (chartInstance) {
      return () => {
        chartInstance.dispose();
      };
    }
    return () => { };
  }, [chartInstance]);

  React.useEffect(() => {
    if (chartRef && chartRef.current) {
      const instance = echarts.init(chartRef.current, null);
      instance.setOption(getInitialChartOption());
      instance.on('click', (event) => {
        if (event.componentType === 'markArea') {
          const serieName = event.name;
          setSelectedTimeSerie((old) => {
            if (old === serieName) {
              return null;
            }
            return serieName;
          });
        }
      });
      instance.on('datazoom', (e) => {
        if (e.batch || !e.dataZoomId.includes('y_')) {
          const option = instance.getOption();
          const start = Math.floor(measure.values.length * (option.dataZoom[0].start / 100));
          const end = Math.ceil(measure.values.length * (option.dataZoom[0].end / 100));
          setCurrentZoomIndexes([parseInt(start, 10), parseInt(end, 10)]);
        }
      });
      setChartInstance(instance);
    }
  }, [chartRef]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption({
        xAxis: getXAxisOption(),
        yAxis: getYAxisOption(),
        toolbox: getToolboxOption(),
      });
    }
  }, [chartInstance, currentConfig.showGrid]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption({
        xAxis: getXAxisOption(),
        yAxis: getYAxisOption(),
        series: getSeriesOption(),
        tooltip: getTooltipOption(),
      });
    }
  }, [chartInstance, currentConfig.showDB]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption({
        series: getSeriesOption(),
        toolbox: getToolboxOption(),
      });
    }
  }, [chartInstance, currentConfig.showPoints]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption({
        series: getSeriesOption(),
      }, { replaceMerge: ['series'] });
    }
  }, [chartInstance, selectedTimeSerie]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption({
        series: getSeriesOption(),
        toolbox: getToolboxOption(),
      }, { replaceMerge: ['series', 'toolbox'] });
    }
  }, [chartInstance, currentConfig.timeSeriesNames]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.setOption({
        series: getSeriesOption(),
      });
    }
  }, [chartInstance, currentConfig.colors]);

  React.useEffect(() => {
    if (chartInstance && singleTimestamp) {
      setCurrentConfig((old) => ({ ...old, showPoints: true }));
    }
  }, [chartInstance, singleTimestamp]);

  const timeSerieWithAttachments = React.useMemo(() => {
    const found = timeSeries.find((serie) => serie.name === selectedTimeSerie);
    if (found && found.values.some((v) => v.attachments.length > 0)) {
      return found;
    }
    return null;
  }, [selectedTimeSerie, timeSeries]);

  return (
    <>
      <SingleMeasureChartConfigModal
        currentConfig={currentConfig}
        onFormSubmit={handleConfigSubmit}
        isModalOpen={isConfigModalOpen}
        onClose={() => handleConfigModalToggle(false)}
      />
      <SingleMeasureTableModal
        soundSession={soundSession}
        measure={measure}
        isModalOpen={isTableModalOpen}
        currentConfig={currentConfig}
        currentIndexes={currentZoomIndexes}
        onClose={() => handleTableModalToggle(false)}
      />
      <SoundMeasureConfigModal
        workspaceId={workspaceId}
        soundSessionId={soundSession.id}
        soundMeasure={measure}
        isModalOpen={isInfoModalOpen}
        onClose={() => handleInfoModalToggle(false)}
      />
      <div style={{ minHeight: 600 }} ref={chartRef} />
      {timeSerieWithAttachments && (
        <SerieAttachmentList
          timeSerie={timeSerieWithAttachments}
          timezone={timezone}
        />
      )}
    </>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  soundSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  measure: PropTypes.shape({
    name: PropTypes.string.isRequired,
    config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    values: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  timeSeries: PropTypes.any.isRequired,
  onRequestFilter: PropTypes.func.isRequired,
};

SingleMeasureChart.propTypes = propTypes;

const avoidRerender = (prev, next) => {
  const result = prev.soundSession === next.soundSession
    && prev.measure === next.measure
    && prev.timeSeries === next.timeSeries;
  return result;
};

export default React.memo(SingleMeasureChart, avoidRerender);
