import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TimezoneInput } from '../../generics';

const WorkspaceFileForm = (props) => {
  const {
    onFormSubmit,
  } = props;

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const handleSubmit = React.useCallback((data) => {
    const fileList = data.files.map((f) => f.originFileObj);
    onFormSubmit({
      ...data,
      files: fileList,
    });
    form.resetFields();
  }, [form, onFormSubmit]);

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          initialValues={{
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }}
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <Form.Item
                label={t('workspaces.components.fileForm.namePrefix')}
                name="namePrefix"
                rules={[{ required: true, message: t('validations.required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                label={t('workspaces.components.fileForm.timezone')}
                name="timezone"
                rules={[{ required: true, message: t('validations.required') }]}
              >
                <TimezoneInput />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Item
                label={t('workspaces.components.fileForm.file')}
                name="files"
                rules={[{ required: true, message: t('validations.required') }]}
                valuePropName="fileList"
                getValueFromEvent={(data) => data.fileList}
              >
                <Upload
                  multiple
                  name="files"
                  maxCount={100}
                  onRemove={(removedFile) => {
                    const files = form.getFieldValue('files');
                    const result = files.filter((file) => file.uid !== removedFile.uid);
                    form.setFieldsValue({ files: result });
                    return false;
                  }}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    {t('common.selectFile')}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                label=" "
                name="files"
                valuePropName="fileList"
                getValueFromEvent={(data) => data.fileList}
              >
                <Upload
                  multiple
                  name="files"
                  maxCount={100}
                  directory
                  showUploadList={false}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    {t('common.selectDirectory')}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.upload')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

WorkspaceFileForm.propTypes = propTypes;

export default WorkspaceFileForm;
