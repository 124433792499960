import { gql } from '@apollo/client';

export const VIBRATION_SESSION_LIST_ITEM_FRAGMENT = gql`
  fragment VibrationSessionListItemFragment on VibrationSession {
    id
    name
    weight
    createdAt
    reference
    source
    minTimestamp
    maxTimestamp
    timezone
    weight
    recordDate
    timeMeasurePreview {
      config
    }
  }
`;

export const VIBRATION_SESSION_DETAIL_FRAGMENT = gql`
  fragment VibrationSessionDetailFragment on VibrationSession {
    id
    config
    note
    timeSingleMeasures {
      id
      name
      kind
      weight
    }
    timeOctaveMeasures {
      id
      name
      kind
      weight
    }
    timeThirdOctaveMeasures {
      id
      name
      kind
      weight
    }
    timeStatisticsMeasures {
      id
      name
      kind
      weight
    }
    ...VibrationSessionListItemFragment
  }
  ${VIBRATION_SESSION_LIST_ITEM_FRAGMENT}
`;

export const WORKSPACE_VIBRATION_SESSIONS_QUERY = gql`
query workspaceVibrationSessionsQuery($afterCursor: String, $pageSize: Int!, $workspaceId: ID!, $filter: VibrationSessionFilter) {
  viewer {
    workspace(id: $workspaceId) {
      vibrationSessions(first: $pageSize, after: $afterCursor, filter: $filter) {
        edges {
          node {
            ...VibrationSessionListItemFragment
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
      }
    }
  }
}
${VIBRATION_SESSION_LIST_ITEM_FRAGMENT}
`;

export const WORKSPACE_VIBRATION_SESSION_QUERY = gql`
  query workspaceVibrationSessionQuery($workspaceId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        id
        name
        vibrationSession(id: $id) {
          ...VibrationSessionDetailFragment
        }
      }
    }
  }
  ${VIBRATION_SESSION_DETAIL_FRAGMENT}
`;

export const VIBRATION_SESSION_INFO_QUERY = gql`
  query VibrationSessionInfoQuery($workspaceId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        vibrationSession(id: $id) {
          id
          timeSingleMeasures {
            id
            period
            config
          }
          timeOctaveMeasures {
            id
            period
            config
          }
          timeThirdOctaveMeasures {
            id
            period
            config
          }
          timeStatisticsMeasures {
            id
            period
            config
          }
        }
      } 
    }
  }
`;

export const VIBRATION_SESSION_VIBRATION_TIME_MEASURES_PREVIEW_QUERY = gql`
  query VibrationSessionVibrationTimeMeasuresPreviewQuery($workspaceId: ID!, $id: ID!) {
    viewer {
      workspace(id: $workspaceId) {
        vibrationSession(id: $id) {
          id
          timeMeasurePreview {
            config
            values
          }
        }
      }
    }
  }
`;

export const VIBRATION_SESSION_UPDATE_MUTATION = gql`
  mutation VibrationSessionUpdateMutation($input: VibrationSessionUpdateInput!) {
    vibrationSessionUpdate(input: $input) {
      vibrationSession {
        ...VibrationSessionListItemFragment
      }
    }
  }
  ${VIBRATION_SESSION_LIST_ITEM_FRAGMENT}
`;

export const VIBRATION_SESSION_DELETE_MUTATION = gql`
  mutation VibrationSessionDeleteMutation($input: VibrationSessionDeleteInput!) {
    vibrationSessionDelete(input: $input) {
      success
    }
  }
`;

export const VIBRATION_SESSION_MULTI_DELETE_MUTATION = gql`
  mutation VibrationSessionMultiDeleteMutation($input: VibrationSessionMultiDeleteInput!) {
    vibrationSessionMultiDelete(input: $input) {
      success
    }
  }
`;
