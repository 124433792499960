/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import WORKSPACE_ROLES from '../../enums/workspaceRoles';

const WorkspaceChangeUserPermissionsForm = (props) => {
  const {
    workspace,
    canEdit,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  const workspaceRoleOptions = React.useMemo(() => {
    const result = Object.keys(WORKSPACE_ROLES)
      .map((role) => ({
        label: t(`enums.workspaceRoles.${role}`),
        value: role,
      }));
    return result;
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={{ userPermissions: workspace.userPermissions }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.List
            name="userPermissions"
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    key={field.key}
                  >
                    <Row align="middle" justify="space-between">
                      <Col xs={12}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[{ required: true, message: t('validations.required') }]}
                          noStyle
                        >
                          <Input disabled={!canEdit} style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                      <Col xs={8}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'roles']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[{ required: true, message: t('validations.required') }]}
                          noStyle
                        >
                          <Select
                            multiple
                            disabled={!canEdit}
                            optionLabelProp="label"
                            options={workspaceRoleOptions}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('common.add')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {canEdit && (
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {t('common.save')}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  canEdit: PropTypes.bool.isRequired,
  workspace: PropTypes.shape({
    id: PropTypes.string,
    userPermissions: PropTypes.arrayOf(PropTypes.shape({
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    })),
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

WorkspaceChangeUserPermissionsForm.propTypes = propTypes;

export default WorkspaceChangeUserPermissionsForm;
