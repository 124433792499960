import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import {
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import WorkspaceForm from './WorkspaceForm';
import { WORKSPACE_CREATE_MUTATION, WORKSPACES_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const WorkspaceCreateModal = (props) => {
  const {
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const [
    workspaceCreateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(WORKSPACE_CREATE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACES_QUERY,
          variables: {
            pageSize: 50,
            filter: { },
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const handleFormSubmit = async (data) => {
    try {
      await workspaceCreateMutationAction({
        variables: {
          input: {
            ...data,
          },
        },
      });
      onClose();
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <Modal
      title={t('workspaces.components.createModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
    >
      <ComponentLoader
        message={t('common.loading')}
        loading={loading}
      >
        <WorkspaceForm
          onFormSubmit={handleFormSubmit}
        />
      </ComponentLoader>
    </Modal>
  );
};

const propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

WorkspaceCreateModal.propTypes = propTypes;

export default WorkspaceCreateModal;
