/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Table,
  Tooltip,
  Button,
  Popconfirm,
  Space,
} from 'antd';
import {
  PageSection,
  messageError,
  messageSuccess,
  ComponentLoader,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import {
  formatLocale,
  getLocale,
} from '../../utils/date';
import {
  WORKSPACE_QUERY,
  WORKSPACE_RETRY_CONVERT_MUTATION,
  WORKSPACE_DELETE_CONVERT_MUTATION,
  WORKSPACE_SOUND_SESSIONS_JOBS_QUERY,
  WORKSPACE_UPLOAD_FILE_MUTATION,
} from '../queries';
import WorkspaceFileForm from '../components/WorkspaceFileForm';

const WorkspaceFileTab = (props) => {
  const {
    workspace,
  } = props;

  const { t, i18n } = useTranslation();

  const locale = getLocale(i18n.language);

  const {
    data: jobData,
    error: jobError,
    loading: jobLoading,
  } = useQuery(WORKSPACE_SOUND_SESSIONS_JOBS_QUERY, {
    variables: {
      workspaceId: workspace.id,
    },
    pollInterval: 20000,
    fetchPolicy: 'network-only',
  });

  const [
    workspaceUploadFileMutationAction,
    {
      loading: uploadLoading,
      error: uploadError,
    },
  ] = useMutation(WORKSPACE_UPLOAD_FILE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACE_QUERY,
          variables: {
            id: workspace.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    workspaceRetryConvertJobAction,
    {
      error: retryJobError,
    },
  ] = useMutation(WORKSPACE_RETRY_CONVERT_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACE_SOUND_SESSIONS_JOBS_QUERY,
          variables: {
            workspaceId: workspace.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    workspaceDeleteConvertJobAction,
    {
      error: deleteJobError,
    },
  ] = useMutation(WORKSPACE_DELETE_CONVERT_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACE_SOUND_SESSIONS_JOBS_QUERY,
          variables: {
            workspaceId: workspace.id,
          },
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const jobQueryResult = React.useMemo(() => {
    if (!jobLoading && !jobError) {
      const { viewer } = jobData;
      const { workspace: workspaceQuery } = viewer;
      const { soundSessionsConvertJobs } = workspaceQuery;
      return {
        soundSessionsConvertJobs,
      };
    }
    return {
      soundSessionsConvertJobs: [],
    };
  }, [jobData, jobError, jobLoading]);

  const handleFormSubmit = React.useCallback(async (data) => {
    try {
      await workspaceUploadFileMutationAction({
        variables: {
          input: {
            id: workspace.id,
            ...data,
          },
        },
      });
      messageSuccess(t('workspaces.fileTab.uploadSuccess'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  }, [workspaceUploadFileMutationAction]);

  React.useEffect(() => {
    if (jobError || uploadError || retryJobError || deleteJobError) {
      const errors = extractGraphQLErrors(
        jobError
        || uploadError
        || retryJobError
        || deleteJobError,
        t,
      );
      messageError({
        content: <ErrorList errors={errors || uploadError} />,
      });
    }
  }, [jobError, uploadError, retryJobError, deleteJobError, t]);

  const jobColumns = React.useMemo(() => ([
    {
      title: t('workspaces.fileTab.table.date'),
      dataIndex: 'date',
      render: (date) => (date ? formatLocale(new Date(date), 'PPpp', locale) : ''),
    },
    {
      title: t('workspaces.fileTab.table.filename'),
      dataIndex: 'originalFilename',
    },
    {
      title: t('workspaces.fileTab.table.jobStatus'),
      dataIndex: 'kind',
      render: (kind, record) => {
        if (record.message) {
          return (
            <Tooltip title={record.message}>
              {kind}
            </Tooltip>
          );
        }
        return kind;
      },
    },
    {
      title: t('common.actions'),
      dataIndex: 'id',
      fixed: 'right',
      render: (id, record) => {
        const result = [];
        if (record.kind === 'FAILED') {
          result.push((
            <Tooltip
              key={`${id}-retry`}
              title={t('common.retry')}
            >
              <Button
                shape="circle"
                size="small"
                onClick={() => workspaceRetryConvertJobAction({
                  variables: {
                    input: {
                      workspaceId: workspace.id,
                      id,
                    },
                  },
                })}
              >
                <RedoOutlined />
              </Button>
            </Tooltip>
          ));
        }
        if (record.kind === 'FAILED' || record.kind === 'QUEUED') {
          result.push((
            <Popconfirm
              key={`${id}-delete`}
              title={t('common.sureToDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => workspaceDeleteConvertJobAction({
                variables: {
                  input: {
                    workspaceId: workspace.id,
                    id,
                  },
                },
              })}
            >
              <Tooltip title={t('common.delete')}>
                <Button shape="circle" size="small" type="ghost">
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          ));
        }

        if (result.length) {
          return (
            <Space size="small">
              {result}
            </Space>
          );
        }

        return null;
      },
    },
  ]), [workspace, t]);

  return (
    <>
      <PageSection
        title={t('workspaces.fileTab.formTitle')}
      >
        <Row>
          <Col xs={24}>
            <ComponentLoader
              loading={uploadLoading}
            >
              <WorkspaceFileForm
                onFormSubmit={handleFormSubmit}
              />
            </ComponentLoader>
          </Col>
        </Row>
      </PageSection>
      <PageSection
        title={t('workspaces.fileTab.jobTitle')}
        subtitle={t('workspaces.fileTab.jobSubtitle')}
      >
        <Row>
          <Col xs={24}>
            <Table
              bordered
              size="small"
              columns={jobColumns}
              rowKey={(record) => record.id}
              dataSource={jobQueryResult.soundSessionsConvertJobs}
              loading={jobLoading}
              pagination={false}
            />
          </Col>
        </Row>
      </PageSection>
    </>
  );
};

const propTypes = {
  workspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

WorkspaceFileTab.propTypes = propTypes;

export default WorkspaceFileTab;
