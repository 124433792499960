import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const WorkspaceForm = (props) => {
  const {
    workspace,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={workspace}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={t('workspaces.components.form.name')}
            name="name"
            rules={[{ required: true, message: t('validations.required') }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.save')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  workspace: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onFormSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  workspace: null,
};

WorkspaceForm.propTypes = propTypes;
WorkspaceForm.defaultProps = defaultProps;

export default WorkspaceForm;
