import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Button,
  InputNumber,
  Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';

const QuotaForm = (props) => {
  const {
    quota,
    onFormSubmit,
  } = props;

  const { t } = useTranslation();

  const initialValues = React.useMemo(() => {
    const result = {
      quota: quota.quota,
      unlimited: quota.quota == null,
    };
    if (result.unlimited) {
      result.quota = null;
    }
    if (result.quota != null) {
      result.quota /= (8 * 60 * 60 * 24 * 30); // terzi d'ottava a 125ms
    }
    return result;
  }, [quota]);

  const handleSubmit = (data) => {
    if (data.unlimited) {
      onFormSubmit({
        id: quota.id,
        quota: null,
      });
    }
    else {
      onFormSubmit({
        id: quota.id,
        quota: data.quota * (8 * 60 * 60 * 24 * 30), // terzi d'ottava a 125ms
      });
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          initialValues={initialValues}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            noStyle
            dependencies={['unlimited']}
          >
            { (a) => (
              <Form.Item
                label={t('quotas.components.form.quota')}
                name="quota"
                extra={t('quotas.components.form.quotaInfo')}
                rules={[{ required: !a.getFieldValue('unlimited'), message: t('validations.required') }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  disabled={a.getFieldValue('unlimited')}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            label={t('quotas.components.form.unlimited')}
            name={['unlimited']}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('common.save')}
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const propTypes = {
  quota: PropTypes.shape({
    id: PropTypes.string,
    quota: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

QuotaForm.propTypes = propTypes;

export default QuotaForm;
