import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

import config from './config';

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem('token');
  const authHeaders = {
    authorization: token ? `Bearer ${token}` : '',
  };
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    window.localStorage.removeItem('token');
    window.location.replace('/');
  }
  else if (graphQLErrors) {
    // eslint-disable-next-line no-console
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
  }
});

const uploadLink = createUploadLink({ uri: config.graphQLUri });

const httpCompositeLink = ApolloLink.from([
  errorLink,
  authLink,
  uploadLink,
]);

const apolloClient = new ApolloClient({
  link: httpCompositeLink,
  cache: new InMemoryCache(),
});

export default apolloClient;
