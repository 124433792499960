/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Popconfirm,
  Space,
} from 'antd';
import { DeleteOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import {
  PageSection,
  messageError,
} from '@deltaohm/ant-components';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';

import useLocalStorage from 'use-local-storage';
import { WORKSPACES_QUERY, WORKSPACE_CREATE_MUTATION, WORKSPACE_DELETE_MUTATION } from './queries';
import { extractGraphQLErrors } from '../utils/graphql';

import { ErrorList } from '../generics';
import {
  WorkspaceFilter,
  WorkspaceCreateModal,
} from './components';
import { AuthenticationContext } from '../authentications';

const WorkspaceListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { quota } = React.useContext(AuthenticationContext);

  const availableQuota = React.useMemo(() => {
    const {
      workspaceAvailableQuota,
    } = quota;
    return workspaceAvailableQuota;
  }, [quota]);

  const [searchObject, setSearchObject] = useLocalStorage('searchObject', {
    search: '',
  });

  const [isWorkspaceCreateModalOpen, setIsWorkspaceCreateModalOpen] = React.useState(false);

  const {
    error,
    loading,
    data,
    fetchMore,
  } = useQuery(WORKSPACES_QUERY, {
    variables: {
      pageSize: 50,
      filter: searchObject,
    },
    nextFetchPolicy: 'network-only',
  });

  const [
    workspaceCreateMutationAction,
    {
      loading: loadingCreate,
      error: errorCreate,
    },
  ] = useMutation(WORKSPACE_CREATE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACES_QUERY,
          variables: {
            pageSize: 50,
            filter: searchObject,
          },
          nextFetchPolicy: 'network-only',
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  const [
    workspaceDeleteMutationAction,
    {
      loading: loadingDelete,
      error: errorDelete,
    },
  ] = useMutation(WORKSPACE_DELETE_MUTATION, {
    refetchQueries: () => {
      const result = [
        {
          query: WORKSPACES_QUERY,
          variables: {
            pageSize: 50,
            filter: searchObject,
          },
          nextFetchPolicy: 'network-only',
        },
      ];
      return result;
    },
    awaitRefetchQueries: true,
  });

  React.useEffect(() => {
    if (error || errorCreate || errorDelete) {
      const errors = extractGraphQLErrors(error || errorCreate || errorDelete, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, errorCreate, errorDelete, t]);

  const queryResult = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const { workspaces: workspacesConnection } = viewer;
      const { pageInfo, edges } = workspacesConnection;
      return {
        workspaces: edges.map((e) => e.node),
        pageInfo,
      };
    }
    return {
      workspaces: [],
      pageInfo: null,
    };
  }, [data, error, loading]);

  React.useEffect(() => {
    const createDefaultWorkspace = async () => {
      if (
        (
          searchObject.search === ''
            || searchObject.search === null
        )
            && !loading
            && !loadingCreate
            && queryResult.workspaces.length === 0
      ) {
        try {
          await workspaceCreateMutationAction({
            variables: {
              input: {
                name: 'Default',
              },
            },
          });
        }
        catch (e) { } // eslint-disable-line
      }
    };

    createDefaultWorkspace();
  }, [workspaceCreateMutationAction, searchObject, loading, searchObject, t]);

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        afterCursor: queryResult.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: oldEdges } = previousResult.viewer.workspaces;
        const { pageInfo: newPageInfo, edges: newEdges } = fetchMoreResult.viewer.workspaces;
        return newEdges.length
          ? {
            ...previousResult,
            viewer: {
              ...previousResult.viewer,
              workspaces: {
                edges: [...oldEdges, ...newEdges],
                pageInfo: newPageInfo,
              },
            },
          }
          : previousResult;
      },
    });
  };

  const handleSearch = React.useCallback((newSearchObject) => {
    setSearchObject((old) => ({
      ...old,
      ...newSearchObject,
    }));
  }, [setSearchObject]);

  const columns = React.useMemo(() => ([
    {
      title: t('workspaces.list.table.name'),
      dataIndex: 'name',
    },
    {
      title: t('workspaces.list.table.owner'),
      dataIndex: 'owner',
    },
    {
      title: t('common.actions'),
      dataIndex: 'id',
      render: (id) => {
        const result = (
          <Space size="small">
            <Tooltip title={t('common.detail')}>
              <Button shape="circle" type="primary" onClick={() => navigate(`/workspaces/${id}`)}>
                <RightOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title={t('common.sureToDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => workspaceDeleteMutationAction({
                variables: {
                  input: {
                    id,
                  },
                },
              })}
            >
              <Tooltip title={t('common.delete')}>
                <Button shape="circle" type="primary">
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </Space>
        );
        return result;
      },
    },
  ]), [t]);

  const tableFooter = React.useMemo(() => {
    if (queryResult.pageInfo && queryResult.pageInfo.hasNextPage) {
      return () => (
        <Button type="primary" block onClick={handleLoadMore}>
          {t('common.loadMore')}
        </Button>
      );
    }
    return null;
  }, [queryResult, handleLoadMore]);

  return (
    <>
      <PageSection
        title={t('workspaces.list.title')}
        subtitle={t('workspaces.list.subtitle')}
        headerExtras={[
          <Tooltip key="add-extra" title={t('common.create')}>
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() => setIsWorkspaceCreateModalOpen(true)}
            />
          </Tooltip>,
        ]}
      >
        <Row>
          <Col xs={24}>
            <WorkspaceFilter
              searchObject={searchObject}
              onSearch={handleSearch}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              bordered
              size="small"
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={queryResult.workspaces}
              loading={loading || loadingCreate || loadingDelete}
              pagination={false}
              footer={tableFooter}
            />
          </Col>
        </Row>
      </PageSection>
      <WorkspaceCreateModal
        isModalOpen={isWorkspaceCreateModalOpen}
        onClose={() => setIsWorkspaceCreateModalOpen(false)}
      />
    </>
  );
};

export default WorkspaceListPage;
