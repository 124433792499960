/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useQuery } from '@apollo/client';
import { WORKSPACE_QUERY } from './queries';

export const useWorkspace = (workspaceId) => {
  const {
    error,
    loading,
    data,
  } = useQuery(WORKSPACE_QUERY, {
    variables: {
      id: workspaceId,
    },
  });

  const workspace = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.workspace;
    }
    return null;
  }, [error, data, loading]);

  return {
    error,
    loading,
    workspace,
  };
};
