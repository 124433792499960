/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  settingIcon,
  downloadImageIcon,
  dbIcon,
  paIcon,
  showGridIcon,
  hideGridIcon,
  showPointsIcon,
  hidePointsIcon,
  infoIcon,
  searchIcon,
  excelIcon,
  tableIcon,
  nextIcon,
  restartIcon,
  exportExcel,

  DB_DECIMAL,
  PA_DECIMAL,
} from '../utils';

import {
  formatLocale,
  formatLocaleWithTimezone,
  getLocale,
} from '../../../utils/date';

import { pascalToDecibel } from '../../../utils/converter';

import OctaveMeasureChartConfigModal from './OctaveMeasureChartConfigModal';
import { OctaveMeasureTableModal } from '../../tables/times';
import { SoundMeasureConfigModal } from '../../components';

const calculateColors = (colorStep) => {
  const result = [];
  const byteStep = parseInt(255 / colorStep, 10);
  let currentRed = 0;
  let currentGreen = 0;
  let currentBlue = 255;
  for (let i = 0; i < colorStep; i += 1) {
    currentRed = byteStep * i;
    currentGreen = parseInt(255 - (255 * (Math.abs((byteStep * i) - 127) / 128)), 10);
    currentBlue = 255 - byteStep * i;
    result.push([
      currentRed,
      currentGreen,
      currentBlue,
    ]);
  }
  return result.map((r) => {
    const red = r[0].toString(16).padStart(2, '0');
    const green = r[1].toString(16).padStart(2, '0');
    const blue = r[2].toString(16).padStart(2, '0');
    return `#${red}${green}${blue}`;
  });
};

const getColorMinMax = (measure, currentMeasureId, showDB) => {
  const { values } = measure;
  let min = null;
  let max = null;

  for (let i = 0; i < values.length; i += 1) {
    const value = values[i];
    const currentSpectre = value.data[currentMeasureId];
    for (let k = 0; currentSpectre != null && k < currentSpectre.length; k += 1) {
      const current = showDB ? pascalToDecibel(currentSpectre[k], 2) : currentSpectre[k];
      if (max == null || (current != null && current > max)) {
        max = current;
      }
      if (min == null || (current != null && current < min)) {
        min = current;
      }
    }
  }

  return [min, max];
};

const OctaveMeasureChart = (props) => {
  const {
    workspaceId,
    soundSession,
    measure,
    onRequestFilter,
  } = props;

  const { timezone } = soundSession;

  const { t, i18n } = useTranslation('translations');
  const locale = getLocale(i18n.language);

  const [currentTimeZoomIndexes, setCurrentTimeZoomIndexes] = React.useState([0, measure.values.length - 1]);
  const [currentBandZoomIndexes, setCurrentBandZoomIndexes] = React.useState([0, measure.config[0].bands.length - 1]);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);
  const [isTableModalOpen, setIsTableModalOpen] = React.useState(false);
  const [isOctaveConfigModalOpen, setIsOctaveConfigModalOpen] = React.useState(false);

  const spectreChartRef = React.useRef(null);
  const [spectreChartInstance, setSpectreChartInstance] = React.useState(null);
  const detailChartRef = React.useRef(null);
  const [detailChartInstance, setDetailChartInstance] = React.useState(null);

  const firstColorMinMax = React.useMemo(() => getColorMinMax(measure, measure.config[0].measureId, true), [measure]);

  const [currentOctaveConfig, setCurrentOctaveConfig] = React.useState({
    measureId: measure.config[0].measureId,
    showDB: true,
    showGrid: true,
    showPoints: false,
    minColorRange: firstColorMinMax[0],
    maxColorRange: firstColorMinMax[1],
    colorSteps: 10,
    selectedPoint: null,
  });

  const currentConfig = React.useMemo(() => {
    const { config } = measure;
    return config[0];
  }, [measure]);

  const bands = React.useMemo(() => currentConfig.bands, [currentConfig]);

  const handleResetColors = React.useCallback(() => {
    setCurrentOctaveConfig((old) => {
      const range = getColorMinMax(measure, old.measureId, old.showDB);
      return {
        ...old,
        minColorRange: range[0],
        maxColorRange: range[1],
        colorSteps: 10,
      };
    });
  }, [measure, setCurrentOctaveConfig]);

  const handleDownloadExcel = () => {
    const [from, to] = currentTimeZoomIndexes;
    const { values } = measure;
    const { measureId } = currentConfig;
    const filteredValues = values.filter((v) => v.data[measureId] != null);
    const currentValues = filteredValues.slice(from, to + 1);

    const header = ['timestamp', 'date', ...bands];
    let data;
    if (currentOctaveConfig.showDB) {
      data = currentValues.map((cv) => ([cv.timestamp, formatLocaleWithTimezone(cv.timestamp, 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone), ...cv.data[measureId].map((d) => pascalToDecibel(d, DB_DECIMAL))]));
    }
    else {
      data = currentValues.map((cv) => ([cv.timestamp, formatLocaleWithTimezone(cv.timestamp, 'yyyy-MM-dd HH:mm:ss.SSS', locale, timezone), ...cv.data[measureId]]));
    }
    exportExcel(
      `${soundSession.name}_${measureId}_${soundSession.timezone}.xlsx`,
      `Time Octave ${measure.config[0].bands.length}`,
      [
        header,
        ...data,
      ],
    );
  };

  const dataSource = React.useMemo(() => {
    const { values } = measure;
    const { measureId } = currentConfig;
    const filteredValues = values.filter((v) => v.data[measureId] != null);

    const ds = filteredValues.map((v) => ([
      v.timestamp,
      ...v.data[measureId],
    ]));

    return ds;
  }, [measure, currentConfig]);

  const spectreDataset = React.useMemo(() => {
    const datasets = [];
    datasets.push({
      id: 'ds',
      dimensions: ['timestamp', ...bands],
      source: dataSource,
    });
    datasets.push({
      id: 'heatmap_pa_ds',
      fromDatasetIndex: 0,
      transform: {
        type: 'deltaohm:SpectreToHeatmapTransform',
        config: { measureConfig: currentConfig },
      },
    });
    datasets.push({
      id: 'heatmap_db_ds',
      fromDatasetIndex: 1,
      transform: {
        type: 'deltaohm:HeatmapPAtoDBTransform',
      },
    });
    return datasets;
  }, [dataSource, currentConfig, bands]);

  const detailDataset = React.useMemo(() => {
    if (currentOctaveConfig.selectedPoint) {
      const bandIndex = bands.indexOf(currentOctaveConfig.selectedPoint[1]);
      const timestamp = currentOctaveConfig.selectedPoint[0];

      const lineDataSource = dataSource.map((ds) => {
        const result = [utcToZonedTime(ds[0], timezone).getTime(), ds[bandIndex + 1]];
        return result;
      });

      const currentTimestampBand = dataSource
        .find((ds) => ds[0] === timestamp);

      const barDataSource = [];
      for (let i = 0; i < bands.length; i += 1) {
        barDataSource.push([bands[i], currentTimestampBand[i + 1]]);
      }

      const datasets = [];
      datasets.push({
        id: 'line_pa',
        dimensions: ['timestamp', 'value'],
        source: lineDataSource,
      });
      datasets.push({
        id: 'line_db',
        fromDatasetIndex: 0,
        transform: {
          type: 'deltaohm:PAtoDBTransform',
          config: { indexes: [1, 1] },
        },
      });
      datasets.push({
        id: 'bar_pa',
        dimensions: ['band', 'value'],
        source: barDataSource,
      });
      datasets.push({
        id: 'bar_db',
        fromDatasetIndex: 2,
        transform: {
          type: 'deltaohm:PAtoDBTransform',
          config: { indexes: [1, 1] },
        },
      });
      return datasets;
    }
    return null;
  }, [dataSource, bands, currentOctaveConfig.selectedPoint]);

  const handleOctaveConfigSubmit = (d) => {
    setCurrentOctaveConfig((old) => ({
      ...old,
      ...d,
    }));
  };

  const handleInfoModalToggle = (open) => {
    setIsInfoModalOpen(open);
  };

  const handleTableModalToggle = (open) => {
    setIsTableModalOpen(open);
  };

  const handleOctaveConfigModalToggle = (open) => {
    setIsOctaveConfigModalOpen(open);
  };

  const getVisualMap = (show) => {
    const result = {
      show,
      type: 'piecewise',
      splitNumber: currentOctaveConfig.colorSteps - 2,
      inRange: {
        color: calculateColors(currentOctaveConfig.colorSteps),
      },
      min: currentOctaveConfig.minColorRange,
      max: currentOctaveConfig.maxColorRange,
      top: '12.5%',
      right: '5%',
      minOpen: true,
      maxOpen: true,
      formatter: (value1, value2) => {
        const precision = currentOctaveConfig.showDB ? DB_DECIMAL : PA_DECIMAL;
        const unit = currentOctaveConfig.showDB ? t('common.db') : t('common.pa');
        if (value1 === -Infinity) {
          if (value2 != null) {
            return `< ${value2.toFixed(precision)} ${unit}`;
          }
          return 'N/A';
        }
        if (value2 === Infinity) {
          if (value1 != null) {
            return `> ${value1.toFixed(precision)} ${unit}`;
          }
          return 'N/A';
        }
        if (value1 != null && value2 != null) {
          return `[${value1.toFixed(precision)} - ${value2.toFixed(precision)}] ${unit}`;
        }
        return 'N/A';
      },
    };
    return result;
  };

  const getToolboxOptionHeatmap = () => {
    const result = [
      {
        id: 'right_toolbox',
        show: true,
        right: '5%',
        feature: {
          myRestartTool: {
            show: true,
            title: t('common.restart'),
            icon: restartIcon,
            onclick: () => {
              onRequestFilter('restart');
            },
          },
          myNextTool: {
            show: true,
            title: t('common.next'),
            icon: nextIcon,
            onclick: () => {
              onRequestFilter('next');
            },
          },
          mySearchTool: {
            show: true,
            title: t('common.filter'),
            icon: searchIcon,
            onclick: () => {
              onRequestFilter();
            },
          },
          saveAsImage: {
            excludeComponents: ['toolbox', 'dataZoom'],
            icon: downloadImageIcon,
            title: t('soundMeasures.components.charts.saveImage'),
          },
          myInfoTool: {
            show: true,
            title: t('common.info'),
            icon: infoIcon,
            onclick: () => {
              handleInfoModalToggle(true);
            },
          },
          myTableTool: {
            show: true,
            title: t('soundMeasures.components.charts.table'),
            icon: tableIcon,
            onclick: () => {
              handleTableModalToggle(true);
            },
          },
          myExcelTool: {
            show: true,
            title: t('soundMeasures.components.charts.excel'),
            icon: excelIcon,
            onclick: () => {
              handleDownloadExcel();
            },
          },
          mySettingTool: {
            show: true,
            title: t('soundMeasures.components.charts.settings'),
            icon: settingIcon,
            onclick: () => {
              handleOctaveConfigModalToggle(true);
            },
          },
          myPaDbTool: {
            show: true,
            title: currentOctaveConfig.showDB ? t('soundMeasures.components.charts.showPa') : t('soundMeasures.components.charts.showDB'),
            icon: currentOctaveConfig.showDB ? paIcon : dbIcon,
            onclick: () => {
              setCurrentOctaveConfig((old) => ({ ...old, showDB: !old.showDB }));
            },
          },
          myShowPointsTool: {
            show: true,
            title: currentOctaveConfig.showPoints ? t('soundMeasures.components.charts.hidePoints') : t('soundMeasures.components.charts.showPoints'),
            icon: currentOctaveConfig.showPoints ? hidePointsIcon : showPointsIcon,
            onclick: () => {
              setCurrentOctaveConfig((old) => ({ ...old, showPoints: !old.showPoints }));
            },
          },
          myShowGridTool: {
            show: true,
            title: currentOctaveConfig.showGrid ? t('soundMeasures.components.charts.hideGrid') : t('soundMeasures.components.charts.showGrid'),
            icon: currentOctaveConfig.showGrid ? hideGridIcon : showGridIcon,
            onclick: () => {
              setCurrentOctaveConfig((old) => ({ ...old, showGrid: !old.showGrid }));
            },
          },
        },
      },
    ];

    return result;
  };

  const getXAxisOptionHeatmap = () => {
    const result = {
      id: 'x_heatmap_data',
      type: 'category',
      axisLabel: {
        formatter: (data) => {
          const date = formatLocaleWithTimezone(parseInt(data, 10), 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone);
          return date;
        },
      },
    };

    return result;
  };

  const getYAxisOptionHeatmap = () => {
    const result = {
      id: 'y_heatmap_data',
      type: 'category',
      name: t('common.hz'),
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        show: currentOctaveConfig.showGrid,
      },
    };

    return result;
  };

  const getSeriesOptionHeatmap = () => {
    const result = {
      id: 'heatmap_serie',
      type: 'heatmap',
      datasetIndex: currentOctaveConfig.showDB ? 2 : 1,
      encode: {
        x: 'timestamp',
        y: 'band',
      },
      progressive: 100000,
      animation: false,
    };

    return result;
  };

  const getTitlesHeatmap = () => {
    const title = `${measure.name} ${currentConfig.label}`;
    const result = {
      id: 'heatmap_title',
      text: title,
    };
    return result;
  };

  const getTooltipOptionHeatmap = () => {
    const result = {
      trigger: 'item',
      formatter: (data) => {
        const [timestamp, band, value] = data.value;
        const date = formatLocaleWithTimezone(timestamp, 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone);
        const bandStr = `${band} ${t('common.hz')}`;
        const realValue = currentOctaveConfig.showDB ? value.toFixed(DB_DECIMAL) : value.toFixed(PA_DECIMAL);
        const unitOfMeasure = currentOctaveConfig.showDB ? t('common.db') : t('common.pa');
        const tooltip = `${date}\n${bandStr}: ${realValue} ${unitOfMeasure}`;
        return tooltip;
      },
      showContent: true,
      renderMode: 'richText',
    };
    return result;
  };

  const getInitialChartOptionHeatmap = () => {
    const result = {
      title: getTitlesHeatmap(),
      tooltip: getTooltipOptionHeatmap(),
      grid: {
        right: '18%',
      },
      dataZoom: [
        {
          id: 'x_zoom_slider',
          type: 'slider',
          start: 0,
          end: 100,
          height: '3%',
          bottom: '3%',
          realtime: false,
          labelFormatter: (index, valueStr) => {
            const date = formatLocaleWithTimezone(parseInt(valueStr, 10), 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone);
            return date;
          },
        },
        {
          id: 'x_zoom_inside',
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          id: 'y_zoom_slider',
          type: 'slider',
          orient: 'vertical',
          show: true,
          start: 0,
          end: 100,
          left: '3%',
          width: '1%',
          yAxisIndex: [0],
          realtime: false,
        },
      ],
      dataset: spectreDataset,
      visualMap: getVisualMap(true),
      toolbox: getToolboxOptionHeatmap(),
      xAxis: getXAxisOptionHeatmap(),
      yAxis: getYAxisOptionHeatmap(),
      series: getSeriesOptionHeatmap(),
    };

    return result;
  };

  const getTitlesDetail = () => {
    const result = [{
      left: 'center',
      text: currentOctaveConfig.selectedPoint ? `${currentOctaveConfig.selectedPoint[1]} ${t('common.hz')}` : '',
    },
    {
      top: '50%',
      left: 'center',
      text: currentOctaveConfig.selectedPoint ? formatLocale(currentOctaveConfig.selectedPoint[0], 'yyyy-MM-dd HH:mm:ss:SSS') : '',
    }];

    return result;
  };

  const getXAxisOptionDetail = () => {
    const result = [
      {
        id: 'x_line',
        type: 'time',
        name: t('common.time'),
        axisLine: {
          show: true,
        },
        axisTick: {
          alignWithLabel: true,
          show: true,
        },
        splitLine: {
          alignWithLabel: true,
          show: currentOctaveConfig.showGrid,
        },
        axisPointer: {
          snap: true,
          label: {
            formatter: (params) => formatLocaleWithTimezone(params.value, 'yyyy-MM-dd HH:mm:ss:SSS', locale, timezone),
          },
        },
        gridIndex: 0,
      },
      {
        id: 'x_bar',
        type: 'category',
        name: t('common.hz'),
        axisLabel: {
          interval: 0,
          rotate: 90,
        },
        axisLine: {
          show: true,
        },
        axisTick: {
          alignWithLabel: true,
          show: true,
          interval: 0,
        },
        splitLine: {
          alignWithLabel: true,
          show: currentOctaveConfig.showGrid,
        },
        gridIndex: 1,
      },
    ];

    return result;
  };

  const getMinMaxDetail = () => {
    const bandIndex = bands.indexOf(currentOctaveConfig.selectedPoint[1]);
    let min;
    let max;

    dataSource.forEach((ds) => {
      const value = ds[bandIndex + 1];
      if (min == null || value < min) {
        min = value;
      }
      if (max == null || value > max) {
        max = value;
      }
    });

    const dbMin = pascalToDecibel(min, DB_DECIMAL);
    const dbMax = pascalToDecibel(max, DB_DECIMAL);

    const result = {
      pascal: [min, max],
      db: [dbMin, dbMax],
    };
    return result;
  };

  const getYAxisOptionDetail = () => {
    const minMax = getMinMaxDetail();
    const result = [
      {
        id: 'y_line',
        type: 'value',
        name: currentOctaveConfig.showDB ? t('common.db') : t('common.pa'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: currentOctaveConfig.showGrid,
        },
        min: () => {
          let diff;
          let min;
          if (currentOctaveConfig.showDB) {
            min = minMax.db[0];
            diff = minMax.db[1] - minMax.db[0];
          }
          else {
            min = minMax.pascal[0];
            diff = minMax.pascal[1] - minMax.pascal[0];
          }
          const air = diff * 0.05;
          return min - air;
        },
        max: () => {
          let diff;
          let max;
          if (currentOctaveConfig.showDB) {
            max = minMax.db[1];
            diff = minMax.db[1] - minMax.db[0];
          }
          else {
            max = minMax.pascal[1];
            diff = minMax.pascal[1] - minMax.pascal[0];
          }
          const air = diff * 0.05;
          return max + air;
        },
        axisLabel: {
          formatter: (value) => (currentOctaveConfig.showDB ? value.toFixed(DB_DECIMAL) : value.toFixed(PA_DECIMAL)),
        },
        gridIndex: 0,
      },
      {
        id: 'y_bar',
        type: 'value',
        name: currentOctaveConfig.showDB ? t('common.db') : t('common.pa'),
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: currentOctaveConfig.showGrid,
        },
        min: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.min - air;
        },
        max: (value) => {
          const diff = value.max - value.min;
          const air = diff * 0.05;
          return value.max + air;
        },
        axisLabel: {
          formatter: (value) => (currentOctaveConfig.showDB ? value.toFixed(DB_DECIMAL) : value.toFixed(PA_DECIMAL)),
        },
        gridIndex: 1,
      },
    ];
    return result;
  };

  const getSeriesOptionDetail = () => {
    const result = [
      {
        id: 'line_serie',
        step: 'start',
        type: 'line',
        sampling: 'lttb',
        symbolSize: 1,
        showSymbol: currentOctaveConfig.showPoints,
        lineStyle: {
          width: 1,
        },
        datasetIndex: currentOctaveConfig.showDB ? 1 : 0,
        encode: {
          x: 'timestamp',
          y: 'value',
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
      },
      {
        id: 'bar_serie',
        type: 'bar',
        datasetIndex: currentOctaveConfig.showDB ? 3 : 2,
        encode: {
          x: 'band',
          y: 'value',
        },
        xAxisIndex: 1,
        yAxisIndex: 1,
      },
    ];

    return result;
  };

  const getTooltipOptionDetail = () => {
    const result = {
      trigger: 'axis',
      triggerOn: 'click',
      axisPointer: {
        type: 'line',
        snap: true,
      },
      formatter: (params) => {
        const [param] = params;
        let tooltip;
        if (param.seriesId === 'line_serie') {
          const [timestamp, value] = param.value;
          if (value != null) {
            const realValue = currentOctaveConfig.showDB ? value.toFixed(DB_DECIMAL) : value.toFixed(PA_DECIMAL);
            const unitOfMeasure = currentOctaveConfig.showDB ? t('common.db') : t('common.pa');
            tooltip = `${format(timestamp, 'yyyy-MM-dd HH:mm:ss.SSS')}\n${realValue} ${unitOfMeasure}`;
          }
          else {
            tooltip = null;
          }
        }
        else if (param.seriesId === 'bar_serie') {
          const [band, value] = param.value;
          const realValue = currentOctaveConfig.showDB ? value.toFixed(DB_DECIMAL) : value.toFixed(PA_DECIMAL);
          const unitOfMeasure = currentOctaveConfig.showDB ? t('common.db') : t('common.pa');
          tooltip = `${band} ${t('common.hz')}\n${realValue} ${unitOfMeasure}`;
        }
        return tooltip;
      },
      showContent: true,
      renderMode: 'richText',
    };

    return result;
  };

  const getInitialChartOptionDetail = () => {
    const timeDataZoom = spectreChartInstance.getOption().dataZoom[0];
    const bandDataZoom = spectreChartInstance.getOption().dataZoom[2];
    const result = {
      title: getTitlesDetail(),
      grid: [
        {
          id: 'line_grid',
          height: '20%',
          right: '18%',
        },
        {
          id: 'bar_grid',
          top: '58%',
          right: '18%',
          height: '20%',
        },
      ],
      tooltip: getTooltipOptionDetail(),
      toolbox: {
        id: 'right_toolbox',
        show: true,
        right: '5%',
        feature: {
          saveAsImage: {
            excludeComponents: ['toolbox', 'dataZoom'],
            icon: downloadImageIcon,
            title: t('soundMeasures.components.charts.saveImage'),
          },
        },
      },
      dataZoom: [
        {
          id: 'x_time_zoom_inside',
          type: 'inside',
          start: timeDataZoom.start,
          end: timeDataZoom.end,
          xAxisIndex: [0],
        },
        {
          id: 'x_band_zoom_slider',
          type: 'slider',
          show: true,
          start: bandDataZoom.start,
          end: bandDataZoom.end,
          xAxisIndex: [1],
          height: '3%',
          realtime: false,
        },
      ],
      dataset: detailDataset,
      visualMap: getVisualMap(false),
      xAxis: getXAxisOptionDetail(),
      yAxis: getYAxisOptionDetail(),
      series: getSeriesOptionDetail(),
    };

    return result;
  };

  // Dispose charts on change page

  React.useEffect(() => {
    if (spectreChartInstance) {
      return () => {
        spectreChartInstance.dispose();
      };
    }
    return () => { };
  }, [spectreChartInstance]);

  React.useEffect(() => {
    if (detailChartInstance) {
      return () => {
        detailChartInstance.dispose();
      };
    }
    return () => { };
  }, [detailChartInstance]);

  React.useEffect(() => {
    if (detailChartInstance && currentOctaveConfig.selectedPoint) {
      detailChartInstance.setOption(getInitialChartOptionDetail());
    }
  }, [detailChartInstance, currentOctaveConfig.selectedPoint, currentOctaveConfig.measureId]);

  // dispose detail chart on unselect point on heatmap
  React.useEffect(() => {
    if (detailChartInstance && !currentOctaveConfig.selectedPoint) {
      detailChartInstance.dispose();
      setDetailChartInstance(null);
    }
  }, [detailChartInstance, currentOctaveConfig.selectedPoint]);

  React.useEffect(() => {
    if (spectreChartRef && spectreChartRef.current) {
      const instance = echarts.init(spectreChartRef.current);
      instance.on('click', (event) => {
        setCurrentOctaveConfig((old) => ({ ...old, selectedPoint: [event.value[0], event.value[1]] }));
      });
      instance.on('datazoom', (e) => {
        if (e.batch || e.dataZoomId === 'x_zoom_slider') {
          const option = instance.getOption();
          setCurrentTimeZoomIndexes([option.dataZoom[0].startValue, option.dataZoom[0].endValue]);
        }
        if (e.dataZoomId === 'y_zoom_slider') {
          const option = instance.getOption();
          setCurrentBandZoomIndexes([option.dataZoom[2].start, option.dataZoom[2].end]);
        }
      });
      setSpectreChartInstance(instance);
    }
  }, [spectreChartRef, detailChartInstance]);

  // change spectre chart on measureId change
  React.useEffect(() => {
    if (spectreChartInstance) {
      spectreChartInstance.setOption(getInitialChartOptionHeatmap());
      setCurrentOctaveConfig((old) => ({ ...old, selectedPoint: null }));
    }
  }, [spectreChartInstance, currentOctaveConfig.measureId, setCurrentOctaveConfig]);

  // Datazoom on detail chart
  React.useEffect(() => {
    if (spectreChartInstance && detailChartInstance) {
      const option = spectreChartInstance.getOption();
      detailChartInstance.dispatchAction({
        type: 'dataZoom',
        dataZoomIndex: 0,
        start: option.dataZoom[0].start,
        end: option.dataZoom[0].end,
      });
    }
  }, [spectreChartInstance, detailChartInstance, currentTimeZoomIndexes]);

  React.useEffect(() => {
    if (spectreChartInstance && detailChartInstance) {
      const option = spectreChartInstance.getOption();
      detailChartInstance.dispatchAction({
        type: 'dataZoom',
        dataZoomIndex: 1,
        start: option.dataZoom[2].start,
        end: option.dataZoom[2].end,
      });
    }
  }, [spectreChartInstance, detailChartInstance, currentBandZoomIndexes]);

  React.useEffect(() => {
    if (detailChartRef && detailChartRef.current && currentOctaveConfig.selectedPoint) {
      const instance = echarts.init(detailChartRef.current, null);
      instance.on('datazoom', (e) => {
        if (e.batch && e.batch[0].dataZoomId === 'x_time_zoom_inside') {
          const option = instance.getOption();
          if (spectreChartInstance) {
            spectreChartInstance.dispatchAction({
              type: 'dataZoom',
              dataZoomIndex: 0,
              start: option.dataZoom[0].start,
              end: option.dataZoom[0].end,
            });
          }
        }
        if (e.dataZoomId === 'x_band_zoom_slider') {
          const option = instance.getOption();
          if (spectreChartInstance) {
            spectreChartInstance.dispatchAction({
              type: 'dataZoom',
              dataZoomIndex: 2,
              start: option.dataZoom[1].start,
              end: option.dataZoom[1].end,
            });
          }
        }
      });
      setDetailChartInstance(instance);
    }
  }, [detailChartRef, spectreChartInstance, currentOctaveConfig.selectedPoint]);

  // change chart value axis name
  React.useEffect(() => {
    if (spectreChartInstance) {
      spectreChartInstance.setOption({
        xAxis: getXAxisOptionHeatmap(),
        yAxis: getYAxisOptionHeatmap(),
        series: getSeriesOptionHeatmap(),
        visualMap: getVisualMap(true),
        tooltip: getTooltipOptionHeatmap(),
      });
    }
  }, [spectreChartInstance, currentOctaveConfig.showDB]);

  React.useEffect(() => {
    if (detailChartInstance) {
      detailChartInstance.setOption({
        xAxis: getXAxisOptionDetail(),
        yAxis: getYAxisOptionDetail(),
        series: getSeriesOptionDetail(),
        visualMap: getVisualMap(false),
        tooltip: getTooltipOptionDetail(),
      });
    }
  }, [detailChartInstance, currentOctaveConfig.showDB]);

  React.useEffect(() => {
    if (spectreChartInstance) {
      spectreChartInstance.setOption({
        xAxis: getXAxisOptionHeatmap(),
        yAxis: getYAxisOptionHeatmap(),
        toolbox: getToolboxOptionHeatmap(),
      });
    }
    if (detailChartInstance && currentOctaveConfig.selectedPoint) {
      detailChartInstance.setOption({
        xAxis: getXAxisOptionDetail(),
        yAxis: getYAxisOptionDetail(),
      });
    }
  }, [spectreChartInstance, detailChartInstance, currentOctaveConfig.showGrid, currentOctaveConfig.selectedPoint]);

  React.useEffect(() => {
    if (spectreChartInstance) {
      spectreChartInstance.setOption({
        toolbox: getToolboxOptionHeatmap(),
      });
    }
    if (detailChartInstance && currentOctaveConfig.selectedPoint) {
      detailChartInstance.setOption({
        series: getSeriesOptionDetail(),
      });
    }
  }, [spectreChartInstance, detailChartInstance, currentOctaveConfig.showPoints]);

  React.useEffect(() => {
    if (spectreChartInstance) {
      spectreChartInstance.setOption({
        visualMap: getVisualMap(true),
      });
    }
  }, [spectreChartInstance, currentOctaveConfig.minColorRange, currentOctaveConfig.maxColorRange, currentOctaveConfig.colorSteps]);

  React.useEffect(() => {
    if (detailChartInstance && currentOctaveConfig.selectedPoint) {
      detailChartInstance.setOption({
        visualMap: getVisualMap(false),
      });
    }
  }, [detailChartInstance, currentOctaveConfig.minColorRange, currentOctaveConfig.maxColorRange, currentOctaveConfig.colorSteps, currentOctaveConfig.selectedPoint]);

  return (
    <>
      <OctaveMeasureChartConfigModal
        currentConfig={currentOctaveConfig}
        onFormSubmit={handleOctaveConfigSubmit}
        isModalOpen={isOctaveConfigModalOpen}
        onClose={() => handleOctaveConfigModalToggle(false)}
        onReset={handleResetColors}
      />
      <OctaveMeasureTableModal
        soundSession={soundSession}
        measure={measure}
        isModalOpen={isTableModalOpen}
        currentConfig={currentOctaveConfig}
        currentIndexes={currentTimeZoomIndexes}
        onClose={() => handleTableModalToggle(false)}
      />
      <SoundMeasureConfigModal
        workspaceId={workspaceId}
        soundSessionId={soundSession.id}
        soundMeasure={measure}
        isModalOpen={isInfoModalOpen}
        onClose={() => handleInfoModalToggle(false)}
      />
      <div style={{ height: 400 }} ref={spectreChartRef} />
      <div style={{ height: 400 }} ref={detailChartRef} />
    </>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  soundSession: PropTypes.shape({
    id: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  measure: PropTypes.shape({
    kind: PropTypes.string,
    name: PropTypes.string.isRequired,
    config: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    values: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onRequestFilter: PropTypes.func.isRequired,
};

OctaveMeasureChart.propTypes = propTypes;

export default OctaveMeasureChart;
