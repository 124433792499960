import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  Row,
  Col,
  Collapse,
} from 'antd';

import {
  PageSection,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';

const VibrationSessionSetupTab = (props) => {
  const {
    vibrationSession,
  } = props;

  const { t } = useTranslation();

  const instrumentColumns = React.useMemo(() => {
    if (vibrationSession.config.instrument) {
      const result = Object.keys(vibrationSession.config.instrument).map((key) => {
        let value = vibrationSession.config.instrument[key];
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        const row = {
          parameter: t(`vibrationSessions.setup.instrument.${key}`),
          value,
        };
        return row;
      });
      return result;
    }
    return null;
  }, [vibrationSession, t]);

  const rightSensorColumns = React.useMemo(() => {
    if (vibrationSession.config.rightSensor) {
      const result = Object.keys(vibrationSession.config.rightSensor).map((key) => {
        let value = vibrationSession.config.rightSensor[key];
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        const row = {
          parameter: t(`vibrationSessions.setup.sensor.${key}`),
          value,
        };
        return row;
      });
      return result;
    }
    return null;
  }, [vibrationSession, t]);

  const leftSensorColumns = React.useMemo(() => {
    if (vibrationSession.config.leftSensor) {
      const result = Object.keys(vibrationSession.config.leftSensor).map((key) => {
        let value = vibrationSession.config.leftSensor[key];
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        const row = {
          parameter: t(`vibrationSessions.setup.sensor.${key}`),
          value,
        };
        return row;
      });
      return result;
    }
    return null;
  }, [vibrationSession, t]);

  const measureColumns = React.useMemo(() => {
    if (vibrationSession.config.measure) {
      const result = Object.keys(vibrationSession.config.measure).map((key) => {
        let value = vibrationSession.config.measure[key];
        if (Array.isArray(value)) {
          value = value.join(',');
        }
        const row = {
          parameter: t(`vibrationSessions.setup.measure.${key}`),
          value,
        };
        return row;
      });
      return result;
    }
    return null;
  }, [vibrationSession, t]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('common.parameter'),
        dataIndex: 'parameter',
      },
      {
        title: t('common.value'),
        dataIndex: 'value',
      },
    ];
    return result;
  }, [t]);

  const defaultActiveKey = React.useMemo(() => {
    const result = [];
    if (instrumentColumns) {
      result.push('instrument');
    }
    if (rightSensorColumns) {
      result.push('rightSensor');
    }
    if (leftSensorColumns) {
      result.push('leftSensor');
    }
    if (measureColumns) {
      result.push('measure');
    }
    return result;
  }, [instrumentColumns, rightSensorColumns, leftSensorColumns, measureColumns]);

  return (
    <PageSection
      title={t('vibrationSessions.setupTab.title')}
    >
      <Row>
        <Col xs={24}>
          <Collapse
            defaultActiveKey={defaultActiveKey}
          >
            {instrumentColumns && (
              <CollapsePanel
                key="instrument"
                header={t('vibrationSessions.setupTab.instrument')}
              >
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  rowKey={(record) => record.parameter}
                  dataSource={instrumentColumns}
                  pagination={false}
                />
              </CollapsePanel>
            )}
            {rightSensorColumns && (
              <CollapsePanel
                key="rightSensor"
                header={t('vibrationSessions.setupTab.rightSensor')}
              >
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  rowKey={(record) => record.parameter}
                  dataSource={rightSensorColumns}
                  pagination={false}
                />
              </CollapsePanel>
            )}
            {leftSensorColumns && (
              <CollapsePanel
                key="leftSensor"
                header={t('vibrationSessions.setupTab.leftSensor')}
              >
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  rowKey={(record) => record.parameter}
                  dataSource={leftSensorColumns}
                  pagination={false}
                />
              </CollapsePanel>
            )}
            {measureColumns && (
              <CollapsePanel
                key="measure"
                header={t('vibrationSessions.setupTab.instrument')}
              >
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  rowKey={(record) => record.parameter}
                  dataSource={measureColumns}
                  pagination={false}
                />
              </CollapsePanel>
            )}
          </Collapse>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  vibrationSession: PropTypes.shape({
    id: PropTypes.string,
    config: PropTypes.objectOf(PropTypes.any),
  }).isRequired,
};

VibrationSessionSetupTab.propTypes = propTypes;

export default VibrationSessionSetupTab;
