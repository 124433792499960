/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import {
  Row,
  Col,
  Form,
  Modal,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';

const SingleMeasureChartConfigModal = (props) => {
  const {
    currentConfig,
    onFormSubmit,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = React.useCallback((data) => {
    onFormSubmit(data);
    onClose();
  }, [onFormSubmit]);

  return (
    <Modal
      title={t('soundMeasures.components.charts.configModal.title')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      <Form
        initialValues={currentConfig}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Row gutter={24}>
          {Object.keys(currentConfig.colors).map((key) => (
            <Col xs={12} md={4} key={key}>
              <Form.Item
                label={key}
                name={['colors', key]}
                rules={[{ required: true, message: t('validations.required') }]}
                valuePropName="color"
                getValueFromEvent={(e) => e.hex}
              >
                <SketchPicker
                  width="100%"
                  disableAlpha
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {t('common.save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const propTypes = {
  currentConfig: PropTypes.any.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SingleMeasureChartConfigModal.propTypes = propTypes;

export default SingleMeasureChartConfigModal;
