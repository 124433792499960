/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { WORKSPACE_UPDATE_MUTATION } from '../queries';

import { WorkspaceForm } from '../components';

const WorkspaceSoundSessionsTab = (props) => {
  const {
    workspace,
  } = props;

  const { t } = useTranslation();

  const [
    workspaceUpdateMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(WORKSPACE_UPDATE_MUTATION);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  const handleFormSubmit = async (data) => {
    try {
      await workspaceUpdateMutationAction({
        variables: {
          input: {
            id: workspace.id,
            ...data,
          },
        },
      });
      messageSuccess(t('common.successMessage'));
    }
    // eslint-disable-next-line no-empty
    catch (e) { }
  };

  return (
    <PageSection
      title={t('workspaces.propertyTab.title')}
    >
      <Row>
        <Col xs={24}>
          <ComponentLoader
            message={t('common.loading')}
            loading={loading}
          >
            <WorkspaceForm
              workspace={workspace}
              onFormSubmit={handleFormSubmit}
            />
          </ComponentLoader>
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  workspace: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

WorkspaceSoundSessionsTab.propTypes = propTypes;

export default WorkspaceSoundSessionsTab;
