/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
  Row,
  Col,
  Table,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  messageError,
} from '@deltaohm/ant-components';

import { VIBRATION_MEASURE_CONFIG_QUERY } from '../queries';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';

const VibrationMeasureConfigModal = (props) => {
  const {
    workspaceId,
    vibrationSessionId,
    vibrationMeasure,
    isModalOpen,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    error,
    loading,
    data,
  } = useQuery(VIBRATION_MEASURE_CONFIG_QUERY, {
    variables: {
      workspaceId,
      vibrationSessionId,
      id: vibrationMeasure.id,
    },
    skip: vibrationMeasure.config != null,
  });

  const config = React.useMemo(() => {
    if (vibrationMeasure.config) {
      return vibrationMeasure.config;
    }
    if (!loading && !error) {
      return data.viewer.workspace.vibrationSession.measure.config;
    }
    return null;
  }, [loading, error, data, vibrationMeasure]);

  const dataSources = React.useMemo(() => {
    if (config) {
      const result = config.map((conf) => ({
        measureId: conf.measureId,
        data: Object.keys(conf).map((key) => {
          let value = conf[key];
          if (Array.isArray(value)) {
            value = value.join(',');
          }
          return {
            parameter: t(`vibrationMeasures.config.${key}`),
            value,
          };
        }),
      }));
      return result;
    }
    return [];
  }, [config, t]);

  const columns = React.useMemo(() => {
    const result = [
      {
        title: t('common.parameter'),
        dataIndex: 'parameter',
        width: '50%',
      },
      {
        title: t('common.value'),
        dataIndex: 'value',
      },
    ];
    return result;
  }, [t]);

  React.useEffect(() => {
    if (error) {
      const errors = extractGraphQLErrors(error, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [error, t]);

  return (
    <Modal
      title={t('common.info')}
      visible={isModalOpen}
      footer={null}
      onCancel={onClose}
      width="80%"
    >
      {dataSources.map((dataSource) => (
        <Row key={dataSource.measureId}>
          <Col span={24}>
            <Table
              bordered
              size="small"
              scroll={{ x: true }}
              columns={columns}
              rowKey={(record) => record.parameter}
              dataSource={dataSource.data}
              pagination={false}
            />
          </Col>
        </Row>
      ))}
    </Modal>
  );
};

const propTypes = {
  workspaceId: PropTypes.string.isRequired,
  vibrationSessionId: PropTypes.string.isRequired,
  vibrationMeasure: PropTypes.shape({
    id: PropTypes.string.isRequired,
    config: PropTypes.any,
  }).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

VibrationMeasureConfigModal.propTypes = propTypes;

export default VibrationMeasureConfigModal;
